import React, { useEffect, useState, useRef } from "react";
import MainSidebar from "./MainSidebar";
import SideUl from "./SideUl";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import FreeTrail from "./FreeTrail";
import { GetMessagesApi } from "../Utility/Api";
import BannerMessage from "./BannerMessage";

const Reports = () => {
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [activeState, setActiveState] = useState(0);
  const [messageData, setMessageData] = useState();

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  const navbarStyle = {
    // top: isFreeTrialVisible ? "-4px" : "-50px",
  };
  const mainContentStyle = {
    // marginTop: isFreeTrialVisible ? "132px" : "92px",
  };
  const mainwrapper = {
    // marginTop: isSidebarVisible ? "0" : "0",
  };
  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     const sidebarElement = document.querySelector(".main-sidebar");
  //     const toggleButtonElement = document.querySelector(".sidebar-arrow a");

  //     if (
  //       sidebarElement &&
  //       !sidebarElement.contains(event.target) &&
  //       toggleButtonElement &&
  //       !toggleButtonElement.contains(event.target)
  //     ) {
  //       setSidebarVisible(false);
  //     }
  //   };

  //   document.addEventListener("click", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);
  useEffect(() => {
    GetMessages();
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const GetMessages = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(GetMessagesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result?.status === true) {
          const bannerData = result?.data?.bannerText;
          setMessageData(bannerData);
        } else {
          // Swal.fire(
          //   "To ensure you see the most recent changes, please clear your browser's cache"
          // );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial || messageData
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {" "}
          {isFreeTrial ? (
            <div className="app-container-trial">
              <FreeTrail />
            </div>
          ) : messageData ? (
            <div className="app-container-trial bannercssmsg">
              <BannerMessage />
            </div>
          ) : null}
          <div
            // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
            //   !isFreeTrialVisible ? "no-free-trial" : ""
            // }`}
            style={sidebarStyleone}
          >
            <nav
              className="navbar navbar-expand-lg main-navbar sticky"
              style={navbarStyle}
            >
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();

                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto">
                <h4>
                  Reports{" "}
                  <a
                    className="notes_icon"
                    href="#"
                    data-toggle="modal"
                    data-target="#noteinfoModal"
                  >
                    <img src="assets/img/notes-icon.svg" alt="" />
                  </a>
                </h4>
              </div>
              <SideUl />
            </nav>
            <MainSidebar />
            {/* Main Content */}
            <div className="main-content" style={mainContentStyle}>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="report_tabs">
                    <ul class="cus_navtabs">
                      <li
                        onClick={() => setActiveState(0)}
                        className={`${activeState === 0 && "active"}`}
                      >
                        <a href="#">Reports</a>
                      </li>
                      <li
                        onClick={() => setActiveState(1)}
                        className={`${activeState === 1 && "active"}`}
                      >
                        <Link to="/storesalesreport">
                          Multi Location Reports
                        </Link>
                      </li>
                    </ul>
                    {/* <ul className="cus_navtabs">
                      <li>
                        <a href="#">Reports</a>
                      </li>
                    
                    </ul> */}
                    <div className="reports_content">
                      <div className="reports_column">
                        <h4>Sales Reports</h4>
                        <div className="report_form_field">
                          <div className="form_column">
                            <div className="form_field">
                              <input
                                type="text"
                                className="form-control ticketinput"
                                placeholder="Sales By date"
                                readOnly
                              />
                              <Link to="/saledate">
                                <input
                                  type="button"
                                  className="generate_btn"
                                  defaultValue="Generate"
                                />
                              </Link>
                            </div>
                          </div>
                          <div className="form_column">
                            <div className="form_field">
                              <input
                                type="text"
                                className="form-control ticketinput"
                                placeholder="Sales By Sales Person"
                                readOnly
                              />
                              <Link to="/saleperson">
                                <input
                                  type="button"
                                  className="generate_btn"
                                  defaultValue="Generate"
                                />
                              </Link>
                            </div>
                          </div>
                          {/* <div className="form_column">
                              <div className="form_field">
                                <input
                                  type="text"
                                  className="form-control ticketinput"
                                  placeholder="Sales By Customer"
                                  readOnly
                                />
                                <input
                                  type="button"
                                  className="generate_btn"
                                  defaultValue="Generate"
                                />
                              </div>
                            </div>
                            <div className="form_column">
                              <div className="form_field">
                                <input
                                  type="text"
                                  className="form-control ticketinput"
                                  placeholder="Sales By Payment Type"
                                  readOnly
                                />
                                <input
                                  type="button"
                                  className="generate_btn"
                                  defaultValue="Generate"
                                />
                              </div>
                            </div> */}
                        </div>
                      </div>
                      {/* <div className="reports_column">
                          <h4>Inventory Reports</h4>
                          <div className="report_form_field">
                            <div className="form_column">
                              <div className="form_field">
                                <input
                                  type="text"
                                  className="form-control ticketinput"
                                  placeholder="Sales By date"
                                  readOnly
                                />
                                <input
                                  type="button"
                                  className="generate_btn"
                                  defaultValue="Generate"
                                />
                              </div>
                            </div>
                            <div className="form_column">
                              <div className="form_field">
                                <input
                                  type="text"
                                  className="form-control ticketinput"
                                  placeholder="Sales By Sales Person"
                                  readOnly
                                />
                                <input
                                  type="button"
                                  className="generate_btn"
                                  defaultValue="Generate"
                                />
                              </div>
                            </div>
                            <div className="form_column">
                              <div className="form_field">
                                <input
                                  type="text"
                                  className="form-control ticketinput"
                                  placeholder="Sales By Customer"
                                  readOnly
                                />
                                <input
                                  type="button"
                                  className="generate_btn"
                                  defaultValue="Generate"
                                />
                              </div>
                            </div>
                            <div className="form_column">
                              <div className="form_field">
                                <input
                                  type="text"
                                  className="form-control ticketinput"
                                  placeholder="Sales By Payment Type"
                                  readOnly
                                />
                                <input
                                  type="button"
                                  className="generate_btn"
                                  defaultValue="Generate"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="reports_column">
                          <h4>Service Reports</h4>
                          <div className="report_form_field">
                            <div className="form_column">
                              <div className="form_field">
                                <input
                                  type="text"
                                  className="form-control ticketinput"
                                  placeholder="Services Created "
                                  readOnly
                                />
                                <input
                                  type="button"
                                  className="generate_btn"
                                  defaultValue="Generate"
                                />
                              </div>
                            </div>
                          </div>
                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <div
        className="modal common_modal noteinfo_modal"
        id="noteinfoModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="noteinfoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="noteclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="assets/img/notes-close-icon.svg" alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className="notes_tabs">
                <ul
                  className="nav nav-pills notetabs"
                  id="notetabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="video_demo-tab"
                      data-toggle="tab"
                      href="#video_demo"
                      role="tab"
                      aria-controls="video_demo"
                      aria-selected="true"
                    >
                      Video Demo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="page_info-tab"
                      data-toggle="tab"
                      href="#page_info"
                      role="tab"
                      aria-controls="page_info"
                      aria-selected="false"
                    >
                      Page Information
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="settingContent">
                  <div
                    className="tab-pane fade active show"
                    id="video_demo"
                    role="tabpanel"
                    aria-labelledby="video_demo-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Video Demo</h4>
                      <p>
                        In this short tutorial, we’ll walk you through the key
                        features and functions of the Dashboard page.
                      </p>
                      <div className="notes_videos">
                        <img
                          src="assets/img/notes-demo-videos-img.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="page_info"
                    role="tabpanel"
                    aria-labelledby="page_info-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Page Information</h4>
                      <p>
                        This page provides a comprehensive view of all the tools
                        and features available to you. Each section is designed
                        to help you manage your business more efficiently,
                        whether you’re tracking sales, monitoring inventory, or
                        analyzing reports.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Reports;
