import React, { useEffect, useState } from "react";
import $ from "jquery";
import SideUl from "./SideUl";
import MainSidebar from "./MainSidebar";
import {
  baseurl,
  GetAllColorApi,
  GetAllConditionApi,
  GetAllPurchaseOrderApi,
  GetAllStorageApi,
  GetMessagesApi,
} from "../Utility/Api";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
import DeviceModalList from "./DeviceModalList";
import { Link } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import FreeTrail from "./FreeTrail";
import BannerMessage from "./BannerMessage";

const PurchaseOrderList = () => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [sortState, setSortState] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [AllPurchase, setAllPurchase] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("Filter Table");
  const [loading, setLoading] = useState(false);
  const [isSortAsc, setIsSortAsc] = useState(false);
  const [adminState, setAdminState] = useState("");
  const [permission, setPermission] = useState([]);
  const [messageData, setMessageData] = useState();

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    // Toggle selectAll state
    const newSelectedItems = selectAll
      ? []
      : currentData.map((item) => item.id);
    setSelectedItems(newSelectedItems);
  };

  const handleSelectRow = (id) => {
    // Toggle selected state for a specific item
    const newSelectedItems = selectedItems.includes(id)
      ? selectedItems.filter((itemId) => itemId !== id)
      : [...selectedItems, id];

    setSelectedItems(newSelectedItems);
  };

  const handlePageClick = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange1 = (event) => {
    const selectedValue = event.currentTarget.textContent.trim();

    if (selectedValue === "Filter By ID") {
      const sortedData = [...AllPurchase].sort((a, b) => {
        const nameA = `${a.id}`.toLowerCase();
        const nameB = `${b.id}`.toLowerCase();

        if (isSortAsc) {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });

      setAllPurchase(sortedData);
      setIsSortAsc(!isSortAsc);
      setSelectedFilter("Filter By ID");
    } else if (selectedValue === "Filter By Date") {
      const sortedData = [...AllPurchase].sort((a, b) => {
        const nameA = `${a.deliveryDate}`.toLowerCase();
        const nameB = `${b.deliveryDate}`.toLowerCase();

        if (isSortAsc) {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });

      setAllPurchase(sortedData);
      setIsSortAsc(!isSortAsc);
      setSelectedFilter("Filter By Date");
    } else {
      setSelectedFilter("Filter Table");
    }
  };

  const filteredData = AllPurchase?.filter((item) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return (
      item?.supplier?.name &&
      item?.supplier?.name.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  const GetAllPurchaseOrder = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllPurchaseOrderApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("all purchase order", result?.data);
        setAllPurchase(result?.data.reverse());
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const sortId = (a) => {
    let sorted = AllPurchase.sort((a, b) => (a.id > b.id ? 1 : -1));

    setAllPurchase([...sorted]);
  };
  const sortId1 = (a) => {
    let sorted = AllPurchase.sort((a, b) => (a.id < b.id ? 1 : -1));

    setAllPurchase([...sorted]);
  };

  const sortCreated = (a) => {
    let sorted = AllPurchase.sort((a, b) =>
      a.user.userName > b.user.userName ? 1 : -1
    );

    setAllPurchase([...sorted]);
  };
  const sortCreated1 = (a) => {
    let sorted = AllPurchase.sort((a, b) =>
      a.user.userName < b.user.userName ? 1 : -1
    );

    setAllPurchase([...sorted]);
  };

  const sortTracking = (a) => {
    let sorted = AllPurchase.sort((a, b) =>
      a.trackingNumber > b.trackingNumber ? 1 : -1
    );

    setAllPurchase([...sorted]);
  };
  const sortTracking1 = (a) => {
    let sorted = AllPurchase.sort((a, b) =>
      a.trackingNumber < b.trackingNumber ? 1 : -1
    );

    setAllPurchase([...sorted]);
  };

  const sortTotal = (a) => {
    let sorted = AllPurchase.sort((a, b) => (a.id > b.id ? 1 : -1));

    setAllPurchase([...sorted]);
  };
  const sortTotal1 = (a) => {
    let sorted = AllPurchase.sort((a, b) => (a.id < b.id ? 1 : -1));

    setAllPurchase([...sorted]);
  };

  const sortStatus = (a) => {
    let sorted = AllPurchase.sort((a, b) =>
      a.activeInactive > b.activeInactive ? 1 : -1
    );

    setAllPurchase([...sorted]);
  };
  const sortStatus1 = (a) => {
    let sorted = AllPurchase.sort((a, b) =>
      a.activeInactive < b.activeInactive ? 1 : -1
    );

    setAllPurchase([...sorted]);
  };

  useEffect(() => {
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));

    setAdminState(localAdmin);
    setPermission(localPerminssion);
    GetAllPurchaseOrder();
  }, []);

  const HandleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete data",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          DeletePurchaseOrder(id);
        });
      }
    });
  };

  const headers = [
    { label: "ID", key: "id" },
    { label: "Date", key: "deliveryDate" },
    { label: "Supplier", key: "supplier.name" },
    { label: "Created By", key: "user.userName" },
    { label: "Tracking", key: "trackingNumber" },
    { label: "Total", key: "" },
    { label: "Status", key: "activeInactive" },
  ];

  const DeletePurchaseOrder = (id) => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      isDeleted: 1,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updatePurchaseOrder/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        GetAllPurchaseOrder();
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  const navbarStyle = {
    // top: isFreeTrialVisible ? "-4px" : "-50px",
  };
  const mainContentStyle = {
    // marginTop: isFreeTrialVisible ? "132px" : "92px",
  };
  const mainwrapper = {
    // marginTop: isSidebarVisible ? "0" : "0",
  };
  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);
  useEffect(() => {
    GetMessages();
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const startPage = Math.max(currentPage - 2, 1);
  const endPage = Math.min(startPage + 3, totalPages);
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  const GetMessages = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(GetMessagesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result?.status === true) {
          const bannerData = result?.data?.bannerText;
          setMessageData(bannerData);
        } else {
          // Swal.fire(
          //   "To ensure you see the most recent changes, please clear your browser's cache"
          // );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial || messageData
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {/* <Helmet>
          <title>Track Devices Seamlessly | Toolbox Device List</title>
          <meta
            ID="description"
            content="Keep track of your devices effortlessly with the Device List tool. Ensure smooth operations and optimal performance across your network."
          />
        </Helmet> */}
          {loading ? (
            <div className="landing_loader">
              <div className="innerloader">
                <img src="img/spin-1s-156px.gif" alt="" />
              </div>
            </div>
          ) : null}
          {isFreeTrial ? (
            <div className="app-container-trial">
              <FreeTrail />
            </div>
          ) : messageData ? (
            <div className="app-container-trial bannercssmsg">
              <BannerMessage />
            </div>
          ) : null}

          <div
            // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
            //   !isFreeTrialVisible ? "no-free-trial" : ""
            // }`}
            style={sidebarStyleone}
          >
            <nav
              className="navbar navbar-expand-lg main-navbar sticky"
              style={navbarStyle}
            >
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();

                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto">
                <h4>
                  Purchase Order{" "}
                  <span className="count">{AllPurchase.length} Devices</span>{" "}
                  <a
                    className="notes_icon"
                    href="#"
                    data-toggle="modal"
                    data-target="#noteinfoModal"
                  >
                    <img src="assets/img/notes-icon.svg" alt="" />
                  </a>
                </h4>
              </div>
              <SideUl />
            </nav>
            <MainSidebar />
            <div className="main-content tableback" style={mainContentStyle}>
              <div className="common_filter_sec">
                <div className="filter_col">
                  <div className="filter_tble_btn">
                    <div className="dropdown custmfilterpurchaseorderlistres">
                      <button
                        type="button"
                        className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        //onClick={handleFilterChange1}
                      >
                        {selectedFilter === "default"
                          ? "Filter Table"
                          : selectedFilter}
                      </button>
                      <img
                        src="assets/img/filter-icon.webp"
                        alt=""
                        className="filtericonimgboxsetting"
                      />
                      <ul
                        className="dropdown-menu customdropdownulboxsetting"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={handleFilterChange1}
                          >
                            Filter Table
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={handleFilterChange1}
                          >
                            Filter By ID
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={handleFilterChange1}
                          >
                            Filter By Date
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="manage_btn">
                    <a href="#">Manage</a>
                  </div>
                  <div className="search_field">
                    <div className="search_icon">
                      <img src="assets/img/search-icon.webp" alt="" />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search PO#/Supplier"
                      value={searchTerm}
                      onChange={handleSearchTermChange}
                    />
                  </div>
                  <div className="imp_exp_btn export_btn">
                    <CSVLink
                      data={AllPurchase}
                      headers={headers}
                      filename={"table-data.csv"}
                    >
                      <button type="button">
                        <img src="assets/img/export-icon.webp" alt="" /> Export
                      </button>
                    </CSVLink>
                  </div>
                  <div className="imp_exp_btn import_btn">
                    <button type="button">
                      <img src="assets/img/import-icon.webp" alt="" /> Import
                    </button>
                  </div>

                  <div className="add_customer_btn addcustmoerbtnsettingresposive">
                    <Link to="/purchaseorder">
                      New Purchase Order <i className="fa fa-plus"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="customertablemain">
                    <table className="table table-striped sales_report_datatable common_datatable customdatatable">
                      <thead className="customtheadpadding">
                        <tr style={{ cursor: "pointer" }}>
                          <th scope="col" className="checkbtnthsetting">
                            <input
                              className="form-check-input customcheckboxbtnsetting"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onChange={handleSelectAll}
                              checked={selectAll}
                            />
                          </th>
                          <th scope="col" className="customnameth">
                            ID
                            {sortState === 0 ? (
                              <span
                                className="upperarrow"
                                onClick={() => {
                                  sortId();
                                  setSortState(0);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-up active"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              <span
                                className="upperarrow"
                                onClick={() => {
                                  sortId();
                                  setSortState(0);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                            {sortState === 1 ? (
                              <span
                                className="lowerarrow"
                                onClick={() => {
                                  sortId1();
                                  setSortState(1);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-down active "
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              <span
                                className="lowerarrow"
                                onClick={() => {
                                  sortId1();
                                  setSortState(1);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-down "
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                          </th>
                          <th scope="col">Date</th>
                          <th scope="col">Supplier</th>
                          <th scope="col" className="customnameth">
                            Created By
                            {sortState === 2 ? (
                              <span
                                className="upperarrow"
                                onClick={() => {
                                  sortCreated();
                                  setSortState(2);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-up active"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              <span
                                className="upperarrow"
                                onClick={() => {
                                  sortCreated();
                                  setSortState(2);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                            {sortState === 3 ? (
                              <span
                                className="lowerarrow"
                                onClick={() => {
                                  sortCreated1();
                                  setSortState(3);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-down active"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              <span
                                className="lowerarrow"
                                onClick={() => {
                                  sortCreated1();
                                  setSortState(3);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-down "
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                          </th>
                          <th scope="col" className="customnameth">
                            Tracking
                            {sortState === 4 ? (
                              <span
                                className="upperarrow"
                                onClick={() => {
                                  sortTracking();
                                  setSortState(4);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-up active"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              <span
                                className="upperarrow"
                                onClick={() => {
                                  sortTracking();
                                  setSortState(4);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                            {sortState === 5 ? (
                              <span
                                className="lowerarrow"
                                onClick={() => {
                                  sortTracking1();
                                  setSortState(5);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-down active"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              <span
                                className="lowerarrow"
                                onClick={() => {
                                  sortTracking1();
                                  setSortState(5);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                          </th>
                          <th scope="col" className="customnameth">
                            Total
                            {sortState === 6 ? (
                              <span
                                className="upperarrow"
                                onClick={() => {
                                  sortTotal();
                                  setSortState(6);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-up active"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              <span
                                className="upperarrow"
                                onClick={() => {
                                  sortTotal();
                                  setSortState(6);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                            {sortState === 7 ? (
                              <span
                                className="lowerarrow"
                                onClick={() => {
                                  sortTotal1();
                                  setSortState(7);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-down active"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              <span
                                className="lowerarrow"
                                onClick={() => {
                                  setSortState(7);
                                  sortTotal1();
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-down "
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                          </th>
                          <th scope="col" className="customnameth">
                            Status
                            {sortState === 8 ? (
                              <span
                                className="upperarrow storearrcust"
                                onClick={() => {
                                  sortStatus();
                                  setSortState(8);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-up active"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              <span
                                className="upperarrow storearrcust"
                                onClick={() => {
                                  sortStatus();
                                  setSortState(8);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-up "
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                            {sortState === 9 ? (
                              <span
                                className="lowerarrow responsstore"
                                onClick={() => {
                                  sortStatus1();
                                  setSortState(9);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-down active"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              <span
                                className="lowerarrow responsstore"
                                onClick={() => {
                                  sortStatus1();
                                  setSortState(9);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                          </th>

                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody className="customtheadpadding">
                        {currentData.length ? (
                          <>
                            {currentData?.map((item, index) => (
                              <tr
                                key={index.id}
                                className={index % 2 === 0 ? "" : "customeven"}
                              >
                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  <input
                                    key={index.id}
                                    className="form-check-input customcheckboxbtnsetting"
                                    type="checkbox"
                                    value=""
                                    id={`flexCheckDefault${1}`}
                                    onChange={() => handleSelectRow(item.id)}
                                    checked={selectedItems.includes(item.id)}
                                  />
                                </td>
                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  #{item?.id}
                                </td>
                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  {moment(
                                    item?.deliveryDate,
                                    "YYYY/MM/DD"
                                  ).format("MM-DD-YYYY")}
                                </td>
                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  {item?.supplier?.name}
                                </td>
                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  {item?.user?.userName}
                                </td>
                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  {item?.trackingNumber}
                                </td>
                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  $ {item?.totalCost ? item?.totalCost : 0}
                                </td>
                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  {item.activeInactive === 1 ? (
                                    <span className="spanactiveinactivecstm1">
                                      Open
                                    </span>
                                  ) : (
                                    <span className="spanactiveinactivecstm">
                                      Close
                                    </span>
                                  )}
                                </td>

                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  <Link
                                    to="/purchaseorderedit"
                                    state={{ data: item }}
                                    className="editbtn"
                                  >
                                    <img src="assets/img/editicon.webp" />
                                  </Link>
                                  <i
                                    className="fa-solid fa-trash deletecustmtb"
                                    onClick={() => HandleDelete(item.id)}
                                  ></i>
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <>
                            {" "}
                            <tr id="noDataMessage">
                              <td colSpan="10" className="nodataav">
                                No data available
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                    {AllPurchase.length > 0 ? (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="custombottompaginationbox">
                            <nav aria-label="Page navigation example">
                              <ul className="pagination">
                                <li
                                  className="page-item custompaginationli  paginationleftbtnbox"
                                  style={{ cursor: "pointer" }}
                                >
                                  <button
                                    type="button"
                                    className="page-link"
                                    onClick={() =>
                                      handlePageClick(currentPage - 1)
                                    }
                                    disabled={currentPage === 1}
                                  >
                                    <img src="assets/img/previous.webp" />
                                  </button>
                                </li>

                                {pages.map((page, key) => {
                                  if (
                                    page === 1 ||
                                    page === totalPages ||
                                    (page >= startPage && page <= endPage)
                                  ) {
                                    return (
                                      <li
                                        className="page-item custompaginationli"
                                        key={key}
                                      >
                                        <a
                                          className={
                                            currentPage === page
                                              ? "page-link active customcountpagination"
                                              : "page-link customcountpagination"
                                          }
                                          onClick={() => handlePageClick(page)}
                                          disabled={currentPage === page}
                                          style={{ cursor: "pointer" }}
                                        >
                                          {page}
                                        </a>
                                      </li>
                                    );
                                  } else if (
                                    page === startPage - 1 ||
                                    page === endPage + 1
                                  ) {
                                    return (
                                      <li
                                        className="page-item custompaginationli"
                                        key={key}
                                      >
                                        <a
                                          className="page-link customcountpagination"
                                          style={{ cursor: "pointer" }}
                                        >
                                          ...
                                        </a>
                                      </li>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}

                                {/* {Array.from(
                                    { length: totalPages },
                                    (_, index) => index + 1
                                  ).map((page, key) => (
                                    <li
                                      className="page-item custompaginationli"
                                      key={key}
                                    >
                                      <a
                                        key={key}
                                        className={
                                          currentPage === page
                                            ? "page-link active customcountpagination"
                                            : "page-link customcountpagination"
                                        }
                                        onClick={() => handlePageClick(page)}
                                        disabled={currentPage === page}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {page}
                                      </a>
                                    </li>
                                  ))} */}

                                <li
                                  className="page-item custompaginationli paginationrightbtnbox"
                                  style={{ cursor: "pointer" }}
                                >
                                  <button
                                    type="button"
                                    className="page-link"
                                    href="#"
                                    onClick={() =>
                                      handlePageClick(currentPage + 1)
                                    }
                                    disabled={currentPage === totalPages}
                                  >
                                    <img src="assets/img/next.webp" />
                                  </button>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <div
        className="modal common_modal noteinfo_modal"
        id="noteinfoModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="noteinfoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="noteclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="assets/img/notes-close-icon.svg" alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className="notes_tabs">
                <ul
                  className="nav nav-pills notetabs"
                  id="notetabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="video_demo-tab"
                      data-toggle="tab"
                      href="#video_demo"
                      role="tab"
                      aria-controls="video_demo"
                      aria-selected="true"
                    >
                      Video Demo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="page_info-tab"
                      data-toggle="tab"
                      href="#page_info"
                      role="tab"
                      aria-controls="page_info"
                      aria-selected="false"
                    >
                      Page Information
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="settingContent">
                  <div
                    className="tab-pane fade active show"
                    id="video_demo"
                    role="tabpanel"
                    aria-labelledby="video_demo-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Video Demo</h4>
                      <p>
                        In this short tutorial, we’ll walk you through the key
                        features and functions of the Dashboard page.
                      </p>
                      <div className="notes_videos">
                        <img
                          src="assets/img/notes-demo-videos-img.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="page_info"
                    role="tabpanel"
                    aria-labelledby="page_info-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Page Information</h4>
                      <p>
                        This page provides a comprehensive view of all the tools
                        and features available to you. Each section is designed
                        to help you manage your business more efficiently,
                        whether you’re tracking sales, monitoring inventory, or
                        analyzing reports.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PurchaseOrderList;
