import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetMessagesApi } from "../Utility/Api";

const BannerMessage = () => {
  const [days, setDays] = useState("");
  const [messages, setMessages] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    GetMessages();
    let plan = localStorage.getItem("planDate");

    if (plan === null || new Date(plan) > new Date()) {
      const date1 = new Date(plan);
      const date2 = new Date();

      const timeDifference = date1.getTime() - date2.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      setDays(daysDifference);
    } else {
      setDays(0);
    }
  }, []);

  const GetMessages = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(GetMessagesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result?.status === true && result?.data?.bannerText?.type === 1) {
          setMessages(result?.data?.bannerText?.message);
          console.log("get messages banner", result?.data?.bannerText?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <div className="marquee">
        <span>{messages}</span>
      </div>
    </>
  );
};
export default BannerMessage;
