import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "../Footer";
import SideUl from "../MainDashboard/SideUl";
import MainSidebar from "../MainDashboard/MainSidebar";
import { baseurl, customerEditApi, uploadImageApi } from "../Utility/Api";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Swal from "sweetalert2";
const TicketCustomerDetail = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [images, setImages] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, SetCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");
  const [error, setError] = useState({});
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [validstate, setValidState] = useState(false);
  const [imageMsg, setImageMsg] = useState("");
  let location = useLocation();
  const [countrycode, setCountryCode] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    let detail = location?.state?.data;

    setFirstName(detail?.firstName);
    setLastName(detail?.lastName);
    setPhoneNo(detail?.phoneNumber);
    setEmail(detail?.email);
    setAddress(detail?.streetAddress);
    setCountry(detail?.country);
    SetCity(detail?.city);
    setZipcode(detail?.zipCode);
    setState(detail?.state);
    setImages(detail?.profileImage);
    setId(detail?.id);
    GetCustomerId(detail?.id);
    // fetch(
    //   "https://ip-api.io/api/json?api_key=fabc32c1-943d-43c7-8a13-ea82cd6ac052"
    // )
    // fetch("http://ip-api.com/json")
    //   .then((response) => response.json())
    //   .then((json) => {
    //     console.log("countryyy", json);
    //     setCountryCode(json);
    //   });
  }, []);

  const isDisposableEmail = (email) => {
    const domain = email.split("@")[1];
    return disposableEmailDomains.includes(domain);
  };

  const disposableEmailDomains = [
    "mailinator.com",
    "guerrillamail.com",
    "10minutemail.com",
    "temp-mail.org",
    "yopmail.com",
    "getnada.com",
    "trashmail.com",
    "fakeinbox.com",
    "throwawaymail.com",
    "maildrop.cc",
    "mytemp.email",
    "tempmailo.com",
    "dispostable.com",
    "mintemail.com",
    "airmail.com",
  ];

  const Validation = () => {
    let error = {};
    let reg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;
    // const isValid = /^[0-9]+$/.test(value);
    if (email && !reg.test(email)) {
      error.email = "Invalid email.";
    } else if (email && isDisposableEmail(email)) {
      error.email =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }
    if (!firstName) {
      error.firstName = "First Name Is Required";
    }

    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      EditCustomer();
    }
    return error;
  };
  const SubmitCus = () => {
    setError(Validation());
  };

  const handleImages = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 5000; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (file.size < minSizeInBytes) {
        setImageMsg("Image size is below the minimum allowed size (5 KB)");
        e.target.value = null;
        return;
      }

      let size = file.type.replace("/", ".");
      var blob = file.slice(0, file.size, file.type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: file.type });

      UploadImage(newFile);
    }
  };

  const UploadImage = async (newFile) => {
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "customers-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setImages(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const EditCustomer = () => {
    let local = localStorage.getItem("token");
    let userid = localStorage.getItem("userId");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNo, // phoneNo ? phoneNo.replace(/^\+/, "") : "",
      profileImage: images,
      country: country,
      streetAddress: address,
      state: state,
      zipCode: zipcode,
      city: city,
      countryCode: +91, //String(country.country_code),
      storeCredit: 32.23,
      account: 10.23,
      mainUserId: userid,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updateCustomerProfile/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetCustomerId(id);

          navigate("/ticketdetail");
        } else {
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetCustomerId = (id) => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getCustomerProfile/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setFirstName(result?.data?.firstName);
          setLastName(result?.data?.lastName);
          setPhoneNo(result?.data?.phoneNumber);
          setEmail(result?.data?.email);
          setAddress(result?.data?.streetAddress);
          setCountry(result?.data?.country);
          SetCity(result?.data?.city);
          setZipcode(result?.data?.zipCode);
          setState(result?.data?.state);
          setImages(result?.data?.profileImage);
          setId(result?.data?.id);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const UpdateCustomer = () => {
    let local = localStorage.getItem("token");
    let userid = localStorage.getItem("userId");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNo,
      profileImage: "",
      country: country,
      streetAddress: address,
      state: state,
      zipCode: zipcode,
      city: city,
      storeCredit: 32.23,
      account: 10.23,
      mainUserId: userid,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updateCustomerProfile/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          GetCustomerId(id);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const HandleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setImages("");
        });
      }
    });
  };

  return (
    <>
      {loading ? (
        <div className="landing_loader">
          <div className="innerloader">
            <img src="img/spin-1s-156px.gif" alt="" />
          </div>
        </div>
      ) : null}
      <div className="main-wrapper">
        <nav className="navbar navbar-expand-lg main-navbar sticky">
          <div className="sidebar-arrow">
            <a href="" data-toggle="sidebar">
              <img src="assets/img/sidebar-arrow.webp" alt="" />
            </a>
          </div>
          <div className="header-title mr-auto">
            <h4>Customer Details</h4>
          </div>
          <SideUl />
        </nav>
        <MainSidebar />
        {/* <!-- Main Content --> */}
        <div className="main-content">
          <div className="customer_details">
            <div className="cust_detail_left">
              <div className="custom_form customer_form">
                <form className="cus_form">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="upload_files">
                          {images ? (
                            <img className="uploaded_img" src={images} alt="" />
                          ) : (
                            <img
                              className="uploaded_img"
                              src="assets/img/customer-banner.webp"
                              alt=""
                            />
                          )}
                          <div className="file_upload">
                            <input
                              type="file"
                              name="files"
                              accept="image/jpeg, image/jpg, image/png"
                              onChange={handleImages}
                            />
                            <div className="fileedit_icon">
                              <img
                                src="assets/img/file-edit-icon.webp"
                                alt=""
                              />
                            </div>
                          </div>
                          <div
                            className="filetrash_icon"
                            onClick={() => {
                              if (images) {
                                HandleDelete();
                              }
                            }}
                          >
                            <a href="#">
                              <img
                                src="assets/img/file-trash-icon.webp"
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                        {imageMsg && (
                          <span className="customvalidationdesignlogin">
                            {imageMsg}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 responscustm">
                      <div className="form_column col_half">
                        <div className="form-group">
                          <label>First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="first_name"
                            placeholder="First Name"
                            value={firstName}
                            onChange={(e) => {
                              setFirstName(e.target.value.trim());
                              if (error.firstName) {
                                setError({ ...error, firstName: "" });
                              }
                            }}
                          />
                          {error.firstName && (
                            <span className="customvalidationdesignlogin">
                              {error.firstName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 responscustm">
                      <div className="form_column col_half">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="last_name"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(e) => {
                              setLastName(e.target.value.trim());
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 responscustm">
                      <div className="form_column col_half">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Email Address"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value.trim());
                              if (error.email) {
                                setError({ ...error, email: "" });
                              }
                            }}
                          />
                          {error.email && (
                            <span className="customvalidationdesignlogin">
                              {error.email}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 responscustm">
                      <div className="form_column col_half">
                        <div className="form-group">
                          <label>Phone No.</label>
                          <PhoneInput
                            className="form-control"
                            placeholder="Enter phone number"
                            international
                            countryCallingCodeEditable={false}
                            //limitMaxLength={10}
                            value={phoneNo}
                            onChange={(val) => {
                              setPhoneNo(val);
                              // if (error.phoneNo) {
                              //   setError({ ...error, phoneNo: "" });
                              // }
                              setValidState(true);
                            }}
                            //   onKeyup={setValidState(true)}
                            defaultCountry={countrycode.country_code}
                          />

                          {validstate === true ? (
                            <span className="customvalidationdesignlogin">
                              {phoneNo && isValidPhoneNumber(phoneNo)
                                ? ""
                                : "Invalid phone number"}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="clearfix"></div>
                    <div className="col-md-12">
                      <h5>Address</h5>
                    </div>
                    <div className="col-md-12">
                      <div className="form_column col_full">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            name="address"
                            placeholder="Street Address"
                            value={address}
                            onChange={(e) => {
                              setAddress(e.target.value);
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 responscustm">
                      <div className="form_column state_col">
                        <div className="form-group">
                          <label>Country</label>
                          <input
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="Country"
                            value={country}
                            onChange={(e) => {
                              setCountry(e.target.value.trim());
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 responscustm">
                      <div className="form_column city_col">
                        <div className="form-group">
                          <label>City</label>
                          <input
                            type="text"
                            className="form-control"
                            name="city"
                            placeholder="City"
                            value={city}
                            onChange={(e) => {
                              SetCity(e.target.value.trim());
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 responscustm">
                      <div className="form_column state_col">
                        <div className="form-group">
                          <label>State</label>
                          <input
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="State / Province"
                            value={state}
                            onChange={(e) => {
                              setState(e.target.value.trim());
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 responscustm">
                      <div className="form_column zip_col">
                        <div className="form-group">
                          <label>ZipCode</label>
                          <input
                            type="text"
                            className="form-control"
                            name="zipcode"
                            placeholder="Zip Code"
                            value={zipcode}
                            onChange={(e) => {
                              setZipcode(e.target.value.trim());
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form_column col_full">
                      <div className="form-btn">
                        <button
                          type="button"
                          className="btn btn-primary theme_btn"
                          onClick={() => SubmitCus()}
                        >
                          Submit
                        </button>
                      </div>
                    </div>

                    <div className="clearfix"></div>
                  </div>
                </form>
              </div>
            </div>
            <div className="cust_detail_right">
              <div className="recent_opiton">
                <div className="title">
                  <h5>Recent Transactions</h5>
                  <a href="#">+</a>
                </div>
                <div className="recent_content">
                  <ul>
                    <li>
                      Invoice n57834
                      <span className="recent_span">
                        <span>Nov 14, 09:00</span>
                        <span>$ 6000</span>
                      </span>
                    </li>
                    <li>
                      Invoice n534
                      <span className="recent_span">
                        <span>Nov 14, 2023 09:00</span>
                        <span>$ 6000</span>
                      </span>
                    </li>
                    <li>
                      416 Woodland Terra, New York, NY
                      <span className="recent_span">
                        <span>Nov 14, 09:00</span>
                        <span>$ 6000</span>
                      </span>
                    </li>
                  </ul>
                  <a className="loadmore" href="#">
                    Load More
                  </a>
                </div>
              </div>
              <div className="recent_opiton">
                <div className="title">
                  <h5>Recent Services</h5>
                  <a href="#">+</a>
                </div>
                <div className="recent_content">
                  <ul>
                    <li>
                      Ticket t57894, Service issue
                      <span className="recent_span">
                        <span>Nov 14, 09:00</span>
                        <span>$ 6000</span>
                      </span>
                    </li>
                    <li>
                      Ticket t343, Screen Replacement
                      <span className="recent_span">
                        <span>Nov 14, 09:00</span>
                        <span>$ 6000</span>
                      </span>
                    </li>
                    <li>
                      Ticket t4783, Charging port
                      <span className="recent_span">
                        <span>Nov 14, 09:00</span>
                        <span>$ 6000</span>
                      </span>
                    </li>
                  </ul>
                  <a className="loadmore" href="#">
                    Load More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default TicketCustomerDetail;
