import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import { Select2 } from "select2";
import Footer from "../Footer";
import NavBar from "../MainDashboard/NavBar";
import SideUl from "../MainDashboard/SideUl";
import MainSidebar from "../MainDashboard/MainSidebar";
import {
  AddInvoiceApi,
  GetAllDiscountApi,
  GetAllTaxApi,
  GetInvoices,
  GetMessagesApi,
  GetProductDeviceApi,
  GettAllPaymentApi,
  baseurl,
  customerAddApi,
  customerGetApi,
  uploadImageApi,
} from "../Utility/Api";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Swal from "sweetalert2";
import ProductModal from "../MainDashboard/ProductModal";
import DeviceModal from "../MainDashboard/DeviceModal";
import moment from "moment";
import Ticket from "../MainDashboard/TicketModal";
import { flatMap } from "lodash";
import FreeTrail from "./FreeTrail";
import BannerMessage from "./BannerMessage";

const VoidSale = () => {
  const [loading, setLoading] = useState(false);
  const [voideNote, setVoidNote] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [id, setId] = useState("");
  const [messageData, setMessageData] = useState();

  const maxDescriptionLength = 300;

  let location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setId(location?.state?.data);
    // console.log("void sale", location?.state?.detail);
  }, []);

  const VoidSale = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    let imeiArrays =
      location?.state?.detail?.items.map((item) => item?.imeiNumberArr) || [];
    let voidImeiArr = imeiArrays.flat(); // Flatten the array
    // console.log("Arr", imeiArrays);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      voidNote: voideNote,
      voidImeiArr: voidImeiArr,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("void raw", raw);
    setLoading(false);
    fetch(`${baseurl}voidSales/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          Swal.fire(result?.message);
          navigate("/invoicelist");
          setLoading(false);
        } else {
          setLoading(false);
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const handleDescriptionChangeNote = (e) => {
    const newDescription = e.target.value;

    if (newDescription.length <= maxDescriptionLength) {
      setVoidNote(newDescription);
      setErrormsg("");
    } else if (newDescription.length > maxDescriptionLength) {
      setTimeout(() => {
        setErrormsg("Allow Maximum 300 Characters");
      }, 2000);
    }
  };
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  const navbarStyle = {
    // top: isFreeTrialVisible ? "-4px" : "-50px",
  };
  const mainContentStyle = {
    // marginTop: isFreeTrialVisible ? "132px" : "92px",
  };

  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);
  useEffect(() => {
    GetMessages();
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const GetMessages = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(GetMessagesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result?.status === true) {
          const bannerData = result?.data?.bannerText;
          setMessageData(bannerData);
        } else {
          // Swal.fire(
          //   "To ensure you see the most recent changes, please clear your browser's cache"
          // );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial || messageData
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {loading ? (
            <div className="landing_loader">
              <div className="innerloader">
                <img src="img/spin-1s-156px.gif" alt="" />
              </div>
            </div>
          ) : null}
          {isFreeTrial ? (
            <div className="app-container-trial">
              <FreeTrail />
            </div>
          ) : messageData ? (
            <div className="app-container-trial bannercssmsg">
              <BannerMessage />
            </div>
          ) : null}
          <div style={sidebarStyleone}>
            <nav
              className="navbar navbar-expand-lg main-navbar sticky"
              style={navbarStyle}
            >
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();

                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto">
                <h4>
                  Invoices{" "}
                  <a
                    className="notes_icon"
                    href="#"
                    data-toggle="modal"
                    data-target="#noteinfoModal"
                  >
                    <img src="assets/img/notes-icon.svg" alt="" />
                  </a>
                </h4>
              </div>
              <SideUl />
            </nav>
            <MainSidebar />{" "}
            <div className="main-content" style={mainContentStyle}>
              <div className="row">
                <div className="col-lg-5 col-md-12"></div>
                <div className="col-lg-7 col-md-12">
                  <div className="cus_table cash_register_table">
                    <div className="void_sale_sec">
                      <div className="void_title">
                        <h4>Void Sale</h4>
                      </div>
                      <div className="void_content">
                        <p>
                          <span>WARNING:</span> Voiding a sale&nbsp;returns the
                          item to inventory, removes the payment from Toolbox
                          and removes the sale from your sales history. Voided
                          sales will not appear in any sales reports or
                          customers records, but will still be present in the
                          system in the Voids Report. Voids are not reversible.
                        </p>
                        <p>This action is permanent and cannot be undone.</p>
                        <ul>
                          <li>Removes all records of transactions</li>
                          <li>Restores all relevant inventory levels</li>
                          <li>
                            Refunds all payments associated with transactions
                          </li>
                        </ul>
                        <div className="reason_textarea">
                          <textarea
                            placeholder="Reasons for voiding sale"
                            className="form-control"
                            value={voideNote}
                            onChange={handleDescriptionChangeNote}
                            // defaultValue={""}
                          />
                          <span>Max Characters 300</span>
                          {errormsg && (
                            <span
                              style={{
                                fontSize: "12px",
                                color: "red",
                              }}
                            >
                              {errormsg}
                            </span>
                          )}
                        </div>
                        <div className="void_submit_btns">
                          <button
                            type="button"
                            className="cus_btn cancel_btn"
                            onClick={() => {
                              setVoidNote("");
                              navigate("/invoicelist");
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="cus_btn void_btn"
                            onClick={VoidSale}
                          >
                            Void
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
        <div className="sales_void_popup">
          <div className="inner_content">
            <h4>Sale has been Voided.</h4>
            <button type="button" className="popup_btn">
              Done
            </button>
          </div>
        </div>
      </div>
      <div
        className="modal common_modal noteinfo_modal"
        id="noteinfoModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="noteinfoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="noteclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="assets/img/notes-close-icon.svg" alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className="notes_tabs">
                <ul
                  className="nav nav-pills notetabs"
                  id="notetabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="video_demo-tab"
                      data-toggle="tab"
                      href="#video_demo"
                      role="tab"
                      aria-controls="video_demo"
                      aria-selected="true"
                    >
                      Video Demo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="page_info-tab"
                      data-toggle="tab"
                      href="#page_info"
                      role="tab"
                      aria-controls="page_info"
                      aria-selected="false"
                    >
                      Page Information
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="settingContent">
                  <div
                    className="tab-pane fade active show"
                    id="video_demo"
                    role="tabpanel"
                    aria-labelledby="video_demo-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Video Demo</h4>
                      <p>
                        In this short tutorial, we’ll walk you through the key
                        features and functions of the Dashboard page.
                      </p>
                      <div className="notes_videos">
                        <img
                          src="assets/img/notes-demo-videos-img.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="page_info"
                    role="tabpanel"
                    aria-labelledby="page_info-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Page Information</h4>
                      <p>
                        This page provides a comprehensive view of all the tools
                        and features available to you. Each section is designed
                        to help you manage your business more efficiently,
                        whether you’re tracking sales, monitoring inventory, or
                        analyzing reports.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VoidSale;
