import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";

const UserBlank = () => {
  return (
    <>
      <header className="header" style={{ backgroundColor: "#84E9FF" }}>
        <div className="container">
          <div className="headerstrip">
            <div className="headerlogo">
              <a aria-current="page" className="active" href="/succeslink">
                <img alt="Toolbox Logo" src="img/toolbox-logo-txt.webp" />
              </a>
            </div>
            <div className="back_btn">
              <Link
                className="backlink"
                to="/"
                // href="/succeslink"
                style={{ backgroundColor: "#000" }}
              >
                Back
              </Link>
            </div>
          </div>
        </div>
      </header>

      <div className="main-wrapper custom-mainwrapper">
        <div className="register_page">
          <div className="regi_step_form">
            <form action="" className="stepform">
              <div className="cus_step" id="step3">
                <div className="container">
                  <div className="cus_blank_msg">
                    <h3>Please Contact your Admin.</h3>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserBlank;
