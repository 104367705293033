import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import MainSidebar from "./MainSidebar";
import SideUl from "./SideUl";
import FreeTrail from "./FreeTrail";
import {
  baseurl,
  CashDrawerDetailsGetApi,
  GetMessagesApi,
  OpenCloseAddApi,
  OpenCloseGetApi,
} from "../Utility/Api";
import Swal from "sweetalert2";
import BannerMessage from "./BannerMessage";
const OpenCloseRegister = () => {
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [error, setError] = useState("");
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);
  const [cashDrawerId, setCashDrawerId] = useState("");
  const [messageData, setMessageData] = useState();

  const [cashDrawer, setCashDrawer] = useState([
    { Denomination: "$100", value: 100, Quantity: 0, Total: 0 },
    { Denomination: "$50", value: 50, Quantity: 0, Total: 0 },
    { Denomination: "$20", value: 20, Quantity: 0, Total: 0 },
    { Denomination: "$10", value: 10, Quantity: 0, Total: 0 },
    { Denomination: "$5", value: 5, Quantity: 0, Total: 0 },
    { Denomination: "$2", value: 2, Quantity: 0, Total: 0 },
    { Denomination: "$1", value: 1, Quantity: 0, Total: 0 },
    { Denomination: "Quarters (0.25)", value: 0.25, Quantity: 0, Total: 0 },
    { Denomination: "Dimes (0.10)", value: 0.1, Quantity: 0, Total: 0 },
    { Denomination: "Nickels (0.05)", value: 0.05, Quantity: 0, Total: 0 },
    { Denomination: "Pennies (0.01)", value: 0.01, Quantity: 0, Total: 0 },
  ]);

  const handleChange = (index, field, value) => {
    const updatedCashDrawer = cashDrawer.map((item, i) => {
      if (i === index) {
        const updatedQuantity = parseFloat(value) || 0;
        const updatedTotal = updatedQuantity * item.value;
        return { ...item, [field]: updatedQuantity, Total: updatedTotal };
      }
      return item;
    });
    setCashDrawer(updatedCashDrawer);
  };

  // Calculate the overall total amount
  const totalAmount = cashDrawer.reduce((sum, item) => sum + item.Total, 0);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  };

  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    GetCashDrawerCounter();
    GetCashDetails();
    GetMessages();
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
      setError("");
    } else {
      setError("Please enter a valid amount.");
    }
  };

  // Function to handle the Open Register button click
  const handleOpenRegister = () => {
    if (amount === "") {
      setError("Amount is required");
      return;
    }

    // Call API if validation passes
    AddOpenClose();
  };

  const AddOpenClose = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const formatDate = (date) => {
      const dateOptions = {
        month: "short",
        day: "numeric",
        year: "numeric",
      };

      const timeOptions = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };

      // Get the date and time separately
      const formattedDate = date.toLocaleDateString("en-US", dateOptions);
      const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

      // Combine the date and time with the " @ " separator
      return `${formattedDate} @ ${formattedTime}`;
    };

    // Example usage:
    const currentDateFormatted = formatDate(new Date());
    console.log(currentDateFormatted);

    const raw = JSON.stringify({
      name: "gagandeep",
      email: "gagandeepsmartitventures@gmail.com",
      type: "Opening Float",
      reason: "",
      amount: amount,
      note: note,
      date: currentDateFormatted,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("openclose", raw);
    fetch(OpenCloseAddApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("add openclose", result);
        if (result?.status === true) {
          setAmount("");
          setNote("");
          Swal.fire(result?.message);
        } else {
          setAmount("");
          setNote("");
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetCashDetails = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(OpenCloseGetApi, requestOptions)
      .then((response) => response.json())
      .then((result) => console.log("OpenClose Register details", result))
      .catch((error) => console.error(error));
  };

  const UpdateCashDrawer = () => {
    if (parseFloat(amount) !== totalAmount) {
      Swal.fire({
        icon: "error", // Show an error icon
        title:
          "The total denomination amount does not match the entered amount. Please double-check and try again.",
        customClass: {
          container: "custom-swal-container",
          confirmButton: "custom-swal-confirm-button",
        },
        // confirmButtonText: "Fix it", // Customized button text
        // backdrop: `rgba(255,0,0,0.4)`, // Adds a red overlay in the background
        // timer: 5000, // Automatically close after 5 seconds
        showCloseButton: true, // Add a close (X) button
      });

      // Swal.fire(
      //   "Error",
      //   "Total denomination amount does not match the entered amount!",
      //   "error"
      // );
    } else {
      let local = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${local}`);
      const filteredCashDrawer = cashDrawer.filter((item) => item.Quantity > 0);
      const dataToPost = filteredCashDrawer.map(({ value, ...rest }) => rest);

      const raw = JSON.stringify({
        cashDrawer: dataToPost,
        totalAmount: totalAmount,
      });

      console.log("cashhdrawer", raw);

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      console.log("raaa", raw);
      fetch(`${baseurl}updateCashDrawerCounter/${cashDrawerId}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);

          if (result?.status === true) {
            //  setCashDrawer([]);
            GetCashDrawerCounter();

            setShowModal(false);
            document
              .querySelectorAll(".modal-backdrop")
              .forEach((el) => el.classList.remove("modal-backdrop"));
            printTable();
          } else {
            //setCashDrawer([]);
            //  GetCashDrawerCounter();
            //  props?.onLoad();
            setShowModal(false);
            document
              .querySelectorAll(".modal-backdrop")
              .forEach((el) => el.classList.remove("modal-backdrop"));
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const GetCashDrawerCounter = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(CashDrawerDetailsGetApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("cash drawer count", result);
        if (result?.status === true) {
          setCashDrawerId(result?.data?.id);
        } else {
          console.log(result);
        }
      })
      .catch((error) => console.error(error));
  };

  const resetValues = () => {
    const resetCashDrawer = cashDrawer.map((item) => ({
      ...item,
      Quantity: 0,
      Total: 0,
    }));
    setCashDrawer(resetCashDrawer);
    //setTotalAmount(0);
  };
  const buttonClass = amount
    ? "cus_reg_btn open_reg_btn success_open_reg"
    : "cus_reg_btn open_reg_btn default_open_reg";

  const printTable = () => {
    const printContents = document.querySelector(".reciepttable").innerHTML;
    const originalContents = document.body.innerHTML;
    // Calculate the position to center the new window
    const width = 800;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    // Open a new window with centered position
    const printWindow = window.open(
      "",
      "_blank",
      `width=${width},height=${height},top=${top},left=${left}`
    );

    if (!printWindow) {
      Swal.fire(
        "Unable to open print window. Please check your pop-up settings."
      );
      return;
    }

    // const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
    <html>
      <head>
     <style>
     @media print {
  @page {
    margin:auto; /* Remove default margins to ensure there's no space at the top and bottom */
  }
  body {
 /* Remove margins on body as well */
     margin-left: 0px;
    margin-right: 0px;
     height:auto
  }
  /* Hide browser print headers and footers */
  header,
  footer {
    display: none;
  }
}
     </style>

     </head>
      <body>${printContents}</body>
    </html>
  `);
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 200);
  };

  const GetMessages = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(GetMessagesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result?.status === true) {
          const bannerData = result?.data?.bannerText;
          setMessageData(bannerData);
        } else {
          // Swal.fire(
          //   "To ensure you see the most recent changes, please clear your browser's cache"
          // );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial || messageData
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {/* <Helmet>
              <title>
                Centralized Customer Database | Toolbox Customer List
              </title>
              <meta
                name="description"
                content="Access a centralized customer list for personalized interactions. Strengthen relationships and improve customer satisfaction."
              />
            </Helmet> */}
          {loading ? (
            <div className="landing_loader">
              <div className="innerloader">
                <img src="img/spin-1s-156px.gif" alt="" />
              </div>
            </div>
          ) : null}
          {isFreeTrial ? (
            <div className="app-container-trial">
              <FreeTrail />
            </div>
          ) : messageData ? (
            <div className="app-container-trial bannercssmsg">
              <BannerMessage />
            </div>
          ) : null}
          <div style={sidebarStyleone}>
            <nav className="navbar navbar-expand-lg main-navbar sticky">
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();
                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto">
                <h4>
                  Cash Register{" "}
                  <a
                    className="notes_icon"
                    href="#"
                    data-toggle="modal"
                    data-target="#noteinfoModal"
                  >
                    <img src="assets/img/notes-icon.svg" alt="" />
                  </a>
                </h4>
              </div>
              <SideUl />
            </nav>
            <MainSidebar />

            <div className="main-content">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="cash_mange_record">
                    <div className="cash_manag_left">
                      <p>
                        Record all your cash movements for the day. If you need
                        help,
                      </p>
                      <p>
                        Petty cash is&nbsp;a small amount of money kept on hand
                        for small purchases or expenses.
                      </p>
                    </div>
                    <div className="cash_manag_right">
                      <div className="cash_manage_btn">
                        <button type="button" className="cus_btn default_btn">
                          Remove Cash
                        </button>
                        <button type="button" className="cus_btn default_btn">
                          Add Cash
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
              <div className="row">
                <div className="offset-lg-2 col-lg-8 col-md-12">
                  <div className="cus_regis_close">
                    <div className="cash_regis_field">
                      <div className="cash_reg_icon">
                        <img src="assets/img/cash-register-close.svg" alt="" />
                      </div>
                      <div className="cash_reg_content">
                        <h3>Register is closed</h3>
                        <p>
                          Keep Track of your cash. <br />
                          Set an opening float amount to open register, and
                          begin making sales.
                        </p>
                      </div>
                      <div className="inner_reg_field">
                        <div className="form-group">
                          <label>Opening Float</label>
                          <div className="cus_input_group">
                            <div className="input_feild">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={0.0}
                                value={amount}
                                onChange={handleAmountChange}
                              />
                              <span>$</span>
                            </div>

                            <div className="input_btn">
                              <button
                                type="button"
                                onClick={() => setShowModal(true)}
                              >
                                <img
                                  src="assets/img/cash-counter-icon.svg"
                                  alt=""
                                />{" "}
                                Till Counter
                              </button>
                            </div>
                          </div>
                          {error && <p style={{ color: "red" }}>{error}</p>}
                        </div>
                        <div className="form-group">
                          <label>
                            Notes <span>Optional</span>
                          </label>
                          <div className="input_feild">
                            <textarea
                              className="form-control"
                              placeholder="Enter a note"
                              value={note}
                              onChange={(e) => setNote(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cash_regis_btns">
                      <button
                        className={buttonClass}
                        onClick={handleOpenRegister}
                      >
                        Open Register
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom_btns">
                <div className="row">
                  <div className="col-md-12">
                    <div className="bottom_links">
                      <ul>
                        <li>
                          <a href="#">--</a>
                        </li>
                        <li>
                          <a href="#">--</a>
                        </li>
                        <li>
                          <a href="#">Note</a>
                        </li>
                        <li>
                          <a href="#">Signature</a>
                        </li>
                        <li>
                          <a href="#">Start Over</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
      {showModal && (
        <>
          <div
            className="modal common_modal cashdrawcounter_modal fade show"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="cashdrawcounterModalLabel"
            style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            aria-modal="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="cashdrawcounterModalLabel">
                    Cash Drawer Counter:
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      resetValues();
                      setShowModal(false);
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    <span className="close_icon" />
                  </button>
                  <p>
                    Enter each denomination of currency for manageable counting.
                    <br />
                    This is not necessary for opening float. You can enter a
                    whole number.
                  </p>
                </div>
                <div className="modal-body">
                  <div className="cash_draw_table">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Denomination</th>
                            <th>Quantity</th>
                            <th>Total:</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cashDrawer?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <b>{item.Denomination}</b>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="cash_qty_field no-arrow-input"
                                  value={item.Quantity}
                                  onChange={(e) =>
                                    handleChange(
                                      index,
                                      "Quantity",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter Quantity"
                                  onWheel={(e) => e.target.blur()}
                                />
                              </td>
                              <td>{item.Total.toFixed(2)}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={2}>
                              <b>Total:</b>
                            </td>
                            <td>${totalAmount.toFixed(2)}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    data-dismiss="modal"
                    onClick={() => {
                      resetValues();
                      setShowModal(false);
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={() => {
                      UpdateCashDrawer();
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showModal && (
        <div
          className="modal-backdrop fade show"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        ></div>
      )}
      <div style={{ display: "none" }}>
        <div className="reciepttable">
          <div style={{ fontFamily: '"Inter", sans-serif' }}>
            <div
              style={{
                margin: "0 auto",
                padding: "25px 25px 15px",
                width: "400px",
              }}
            >
              <table style={{ width: "100%", textAlign: "center" }}>
                <tbody>
                  <tr>
                    <td style={{ padding: "5px 0" }}>
                      <img src="assets/img/receipt-logo.svg" alt="" />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px 0",
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "20px",
                        color: "#000",
                      }}
                    >
                      STORE NAME
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "10px 0 0",
                        fontSize: "13px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        color: "#000",
                      }}
                    >
                      Opening Float
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px 0",
                        fontSize: "24px",
                        lineHeight: "32px",
                        color: "#000",
                      }}
                    >
                      <b>$ 150.00</b>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div style={{ padding: "8px 0" }}></div>

              <table className="tabledata" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ padding: "8px 6px" }}></th>
                    <th style={{ padding: "8px 6px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        borderTop: "2px dashed #000",
                        padding: "8px 6px",
                      }}
                    ></td>
                    <td
                      style={{
                        borderTop: "2px dashed #000",
                        padding: "8px 6px",
                      }}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px 0",
                        fontSize: "13px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        color: "#000",
                        textAlign: "center",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "5px 0",
                        fontSize: "13px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        color: "#000",
                        textAlign: "left",
                      }}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px 0",
                        fontSize: "13px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      <b>Date:</b>
                    </td>
                    <td
                      style={{
                        padding: "5px 0",
                        fontSize: "13px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      Sep, 5, 2024 @ 1:00 pm
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px 0",
                        fontSize: "13px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      <b>Cashier:</b>
                    </td>
                    <td
                      style={{
                        padding: "5px 0",
                        fontSize: "13px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      First Name Last Name
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px 0",
                        fontSize: "13px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      <b>Register:</b>
                    </td>
                    <td
                      style={{
                        padding: "5px 0",
                        fontSize: "13px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      Register Name
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px 0",
                        fontSize: "13px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      <b>Note:</b>
                    </td>
                    <td
                      style={{
                        padding: "5px 0",
                        fontSize: "13px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      -
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "5px 0",
                        fontSize: "13px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        color: "#000",
                        textAlign: "center",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "5px 0",
                        fontSize: "13px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        color: "#000",
                        textAlign: "left",
                      }}
                    ></td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      style={{
                        borderTop: "2px dashed #000",
                        padding: "8px 6px",
                      }}
                    ></td>
                    <td
                      style={{
                        borderTop: "2px dashed #000",
                        padding: "8px 6px",
                      }}
                    ></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal common_modal noteinfo_modal"
        id="noteinfoModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="noteinfoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="noteclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="assets/img/notes-close-icon.svg" alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className="notes_tabs">
                <ul
                  className="nav nav-pills notetabs"
                  id="notetabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="video_demo-tab"
                      data-toggle="tab"
                      href="#video_demo"
                      role="tab"
                      aria-controls="video_demo"
                      aria-selected="true"
                    >
                      Video Demo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="page_info-tab"
                      data-toggle="tab"
                      href="#page_info"
                      role="tab"
                      aria-controls="page_info"
                      aria-selected="false"
                    >
                      Page Information
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="settingContent">
                  <div
                    className="tab-pane fade active show"
                    id="video_demo"
                    role="tabpanel"
                    aria-labelledby="video_demo-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Video Demo</h4>
                      <p>
                        In this short tutorial, we’ll walk you through the key
                        features and functions of the Dashboard page.
                      </p>
                      <div className="notes_videos">
                        <img
                          src="assets/img/notes-demo-videos-img.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="page_info"
                    role="tabpanel"
                    aria-labelledby="page_info-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Page Information</h4>
                      <p>
                        This page provides a comprehensive view of all the tools
                        and features available to you. Each section is designed
                        to help you manage your business more efficiently,
                        whether you’re tracking sales, monitoring inventory, or
                        analyzing reports.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OpenCloseRegister;
