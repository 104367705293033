import React, { useEffect, useState, useRef } from "react";
import MainSidebar from "./MainSidebar";
import SideUl from "./SideUl";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import FreeTrail from "./FreeTrail";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {
  GetAllMultiStoreReportApi,
  GetAllSubdomainApi,
  GetMessagesApi,
} from "../Utility/Api";
import BannerMessage from "./BannerMessage";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";

const MultiStorereport = () => {
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortState, setSortState] = useState(null);
  const [activeState, setActiveState] = useState(0);
  const [messageData, setMessageData] = useState();
  const [data, setData] = useState([]);
  const [subdomains, setSubdomains] = useState([]);
  const [subdomain, setSubdomain] = useState("");
  const [overTotal, setOverTotal] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [selectedStore, setSelectedStore] = useState("");

  const generatePDF = () => {
    const doc = new jsPDF();

    // Add optional title
    doc.text("Sales Report", 14, 10);

    // Extract table by class name and create the PDF
    doc.autoTable({
      html: ".table-class", // Replace with the class name of your table
      startY: 20, // Space between the title and the table
    });

    // Save the PDF
    doc.save("table-report.pdf");
  };

  const headers = [
    { label: "Store Name (Subdomain)", key: "StoreName" },
    { label: "No. of Services", key: "ServiceCount" },
    { label: "Services", key: "ServicePrice" },
    { label: "New Customers", key: "NewCustomers" },
    { label: "No. of Products Sold", key: "ProductsSold" },
    { label: "No. of Devices Sold", key: "DevicesSold" },
    { label: "No. of Transactions", key: "Transactions" },
    { label: "Total Sales", key: "TotalSales" },
    { label: "Avg Sales", key: "AvgSales" },
    { label: "Discounts", key: "Discounts" },
    { label: "Refunds", key: "Refunds" },
    { label: "COGS", key: "COGS" },
    { label: "Taxes", key: "Taxes" },
    { label: "Net Profit Margin", key: "NetProfitMargin" },
    { label: "Net Profit", key: "NetProfit" },
  ];
  const preprocessData = (data) => {
    return data.map((store) => ({
      StoreName: store?.subDomain || "--",
      ServiceCount: store?.serviceCount || 0,
      ServicePrice: store?.serviceCost
        ? `$${store.serviceCost.toFixed(2)}`
        : "$0.00",
      NewCustomers: store?.customerCount || 0,
      ProductsSold: store?.productCount || 0,
      DevicesSold: store?.deviceCount || 0,
      Transactions: store?.transactions || 0,
      TotalSales: store?.sales ? `$${store.sales.toFixed(2)}` : "$0.00",
      AvgSales: store?.avgSales ? `$${store.avgSales.toFixed(2)}` : "$0.00",
      Discounts: store?.discount
        ? `($${store.discount.toFixed(2)})`
        : "($0.00)",
      Refunds: store?.refund ? `($${store.refund.toFixed(2)})` : "($0.00)",
      COGS: store?.cogs ? `$${store.cogs.toFixed(2)}` : "$0.00",
      Taxes: store?.tax ? `$${store.tax.toFixed(2)}` : "$0.00",
      NetProfitMargin: store?.margin ? `${store.margin}%` : "0%",
      NetProfit: store?.profit ? `$${store.profit.toFixed(2)}` : "$0.00",
    }));
  };

  const data1 = preprocessData(data);

  const handleStoreChange = (store) => {
    setSelectedStore(store);
    const filteredData = data.filter(
      (storeData) => storeData.subDomain === store
    );
    setData(filteredData);
  };

  const handleResetFilter = () => {
    setSelectedStore("");
    GellAllMultiStoreReportByDate(subdomain, activeState);
  };

  const sortServices = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.serviceCount > b?.store?.serviceCount ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortServices1 = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.serviceCount < b?.store?.serviceCount ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortProduct = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.productCount > b?.store?.productCount ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortProduct1 = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.productCount < b?.store?.productCount ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortDevice = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.deviceCount > b?.store?.deviceCount ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortDevice1 = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.deviceCount < b?.store?.deviceCount ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortTransactions = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.transactions > b?.store?.transactions ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortTransactions1 = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.transactions < b?.store?.transactions ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortTotalSales = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.sales > b?.store?.sales ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortTotalSales1 = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.sales < b?.store?.sales ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortAvgSales = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.avgSales > b?.store?.avgSales ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortAvgSales1 = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.avgSales < b?.store?.avgSales ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortDiscounts = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.discount > b?.store?.discount ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortDiscounts1 = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.discount < b?.store?.discount ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortRefunds = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.refund > b?.store?.refund ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortRefunds1 = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.refund < b?.store?.refund ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortCOGS = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.cogs > b?.store?.cogs ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortCOGS1 = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.cogs < b?.store?.cogs ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortTaxes = (a) => {
    let sorted = data.sort((a, b) => (a?.store?.tax > b?.store?.tax ? 1 : -1));

    setData([...sorted]);
  };
  const sortTaxes1 = (a) => {
    let sorted = data.sort((a, b) => (a?.store?.tax < b?.store?.tax ? 1 : -1));

    setData([...sorted]);
  };
  const sortNetProfitMargin = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.margin > b?.store?.margin ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortNetProfitMargin1 = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.margin < b?.store?.margin ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortNetProfit = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.profit > b?.store?.profit ? 1 : -1
    );

    setData([...sorted]);
  };
  const sortNetProfit1 = (a) => {
    let sorted = data.sort((a, b) =>
      a?.store?.profit < b?.store?.profit ? 1 : -1
    );

    setData([...sorted]);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
    // Trigger the API call whenever the start date changes
    // GellAllMultiStoreReportByDate("your-subdomain", startDate, date); // Pass start and end dates
  };

  const handleEndDate = (date) => {
    setEndDate(date);
    // Trigger the API call whenever the end date changes
    GellAllMultiStoreReportByDates(subdomain, startDate, date); // Pass start and end dates
  };

  useEffect(() => {
    GetMessages();
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const subdomain = host.split(".")[0];
    console.log("subdomain,,,,,", subdomain);
    setSubdomain(subdomain);
    GetAllSubdomain(subdomain);
    GellAllMultiStoreReportByDate(subdomain);
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  const navbarStyle = {
    // top: isFreeTrialVisible ? "-4px" : "-50px",
  };

  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const totalPages = Math.ceil(data?.length / itemsPerPage);
  const startPage = Math.max(currentPage - 2, 1);
  const endPage = Math.min(startPage + 3, totalPages);

  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);
  const handlePageClick = (newPage) => {
    setCurrentPage(newPage);
  };

  const GetAllSubdomain = (sub) => {
    let local = localStorage.getItem("token");
    let mail = localStorage.getItem("email");

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      subDomain: sub,
      email: mail,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(GetAllSubdomainApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("subdomain list,,,", result);
        setSubdomains(result.data);
      })
      .catch((error) => console.error(error));
  };

  const GetMessages = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(GetMessagesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result?.status === true) {
          const bannerData = result?.data?.bannerText;
          setMessageData(bannerData);
        } else {
          // Swal.fire(
          //   "To ensure you see the most recent changes, please clear your browser's cache"
          // );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GellAllMultiStoreReportByDates = (sub, start, end) => {
    setLoading(true);
    const formattedStartDate = start.toISOString().split("T")[0];
    const formattedEndDate = end.toISOString().split("T")[0];
    let local = localStorage.getItem("token");
    let mail = localStorage.getItem("email");

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      subDomain: sub,
      email: mail,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("gdsjgd datess", raw);
    fetch(GetAllMultiStoreReportApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("reposttstst", result);

        if (result.status) {
          setLoading(false);
          setData(result.data);
          setOverTotal(result.overallTotal);
        } else {
          setLoading(false);
          console.error("Error fetching data:", result);
        }
      })
      .catch((error) => {
        console.error(error);

        setLoading(false);
      });
  };

  const GellAllMultiStoreReportByDate = (sub, state) => {
    setLoading(true);
    const { startDate, endDate } = getStartAndEndDate(state);

    let local = localStorage.getItem("token");
    let mail = localStorage.getItem("email");

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      subDomain: sub,
      email: mail,
      startDate: startDate,
      endDate: endDate,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("gdsjgd", raw);
    fetch(GetAllMultiStoreReportApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("reposttstst", result);

        if (result.status) {
          setLoading(false);
          setData(result.data);
          setOverTotal(result.overallTotal);
        } else {
          setLoading(false);

          console.error("Error fetching data:", result);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const getStartAndEndDate = (state) => {
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();

    switch (state) {
      case 1: // Today
        startDate = endDate = today;
        break;
      case 2: // Yesterday
        startDate.setDate(today.getDate() - 1);
        endDate = startDate;
        break;
      case 3: // This Week
        const firstDayOfWeek = today.getDate() - today.getDay(); // Get Sunday
        startDate.setDate(firstDayOfWeek);
        endDate = today;
        break;
      case 4: // This Month
        startDate.setDate(1); // Set to first day of the current month
        endDate = today;
        break;
      case 5: // Last Month
        startDate.setMonth(today.getMonth() - 1);
        startDate.setDate(1);
        endDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + 1,
          0
        ); // Last day of last month
        break;
      case 6: // This Year
        startDate.setMonth(0, 1); // Set to first day of the year
        endDate = today;
        break;
      default:
        break;
    }

    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];

    return { startDate: formattedStartDate, endDate: formattedEndDate };
  };

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const calculateTotals = (data) => {
    return data.reduce(
      (acc, item) => {
        acc.serviceCount += item.serviceCount || 0;
        acc.serviceCost += item.serviceCost || 0;
        acc.customerCount += item.customerCount || 0;
        acc.productCount += item.productCount || 0;
        acc.deviceCount += item.deviceCount || 0;
        acc.transactions += item.transactions || 0;
        acc.sales += item.sales || 0;
        acc.avgSales += item.avgSales || 0;
        acc.discount += item.discount || 0;
        acc.refund += item.refund || 0;
        acc.cogs += item.cogs || 0;
        acc.tax += item.tax || 0;
        acc.margin += item.margin || 0; // Assuming margin is an aggregate
        acc.profit += item.profit || 0;
        return acc;
      },
      {
        serviceCount: 0,
        serviceCost: 0,
        customerCount: 0,
        productCount: 0,
        deviceCount: 0,
        transactions: 0,
        sales: 0,
        avgSales: 0,
        discount: 0,
        refund: 0,
        cogs: 0,
        tax: 0,
        margin: 0,
        profit: 0,
      }
    );
  };

  const filteredData = selectedStore
    ? data.filter((storeData) => storeData.subDomain === selectedStore)
    : data;

  const totals = calculateTotals(filteredData);

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        {loading ? (
          <div className="landing_loader">
            <div className="innerloader">
              <img src="img/spin-1s-156px.gif" alt="" />
            </div>
          </div>
        ) : null}
        <div
          className={`${
            isFreeTrial || messageData
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {" "}
          {isFreeTrial ? (
            <div className="app-container-trial">
              <FreeTrail />
            </div>
          ) : messageData ? (
            <div className="app-container-trial bannercssmsg">
              <BannerMessage />
            </div>
          ) : null}
          <div style={sidebarStyleone}>
            <nav
              className="navbar navbar-expand-lg main-navbar sticky"
              style={navbarStyle}
            >
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();

                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto">
                <h4>
                  Reports / Multi-Store Reports
                  <a
                    className="notes_icon"
                    href="#"
                    data-toggle="modal"
                    data-target="#noteinfoModal"
                  >
                    <img src="assets/img/notes-icon.svg" alt="" />
                  </a>
                </h4>
              </div>
              <SideUl />
            </nav>
            <MainSidebar />
            {/* Main Content */}
            <div className="main-content">
              <div className="salesreport_action common_filter_sec">
                <div className="salesreport_left">
                  <div className="salesreport_location">
                    <label> Select Store</label>
                    <div className="ft_select_option brand_option option_15 customdropdownulboxcate">
                      <div className="dropdown customtabledropdownbox1 customtabledropdownbox2">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedStore ? selectedStore : "Select SubDomain"}
                        </button>
                        <ul
                          className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                          aria-labelledby="dropdownMenuLink"
                        >
                          {subdomains.map((subdomain, index) => (
                            <>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={handleResetFilter}
                                >
                                  Select SubDomain
                                </a>
                              </li>
                              <li key={index}>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() =>
                                    handleStoreChange(
                                      subdomain.usersAccountDetail.subDomain
                                    )
                                  }
                                >
                                  {subdomain.usersAccountDetail.subDomain}
                                </a>
                              </li>
                            </>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <img src="assets/img/select-arrow.webp" alt="" />
                  </div>

                  <div className="sales_report_date">
                    <label>Date</label>
                    <div className="sales_date">
                      <div className="date_icon">
                        <img src="assets/img/sales-date-icon.svg" alt="" />
                      </div>
                      <div className="date_rangepicker">
                        <DatePicker
                          id="startDate"
                          className="saledashstartdatepicker"
                          dateFormat="MM/dd/yyyy"
                          selected={startDate}
                          onChange={handleStartDate}
                        />
                        <span>-</span>
                        <DatePicker
                          id="endDate"
                          className="saledashenddatepicker"
                          dateFormat="MM/dd/yyyy"
                          minDate={startDate} // Ensure the end date is not before the start date
                          selected={endDate}
                          onChange={handleEndDate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="salesreport_right">
                  <div className="sales_action_btns">
                    <button
                      type="button"
                      className="cus_btn pdf_btn"
                      onClick={() => generatePDF()}
                    >
                      <img src="assets/img/sales-report-pdf-icon.svg" alt="" />{" "}
                      PDF
                    </button>
                    <CSVLink
                      data={data1}
                      headers={headers}
                      filename={"table-data.csv"}
                    >
                      <button type="button" className="cus_btn excel_btn">
                        <img
                          src="assets/img/sales-report-excel-icon.svg"
                          alt=""
                        />{" "}
                        Export
                      </button>
                    </CSVLink>
                    <button type="button" className="cus_btn print_btn">
                      <img
                        src="assets/img/sales-report-print-icon.svg"
                        alt=""
                      />{" "}
                      Print
                    </button>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
              <div className="salesreport_statics">
                <div className="salesreport_statics_row">
                  <div className="report_statics_left">
                    <ul>
                      {[
                        "All",
                        "Today",
                        "Yesterday",
                        "This Week",
                        "This Month",
                        "Last Month",
                        "This Year",
                      ].map((label, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            setActiveState(index);
                            GellAllMultiStoreReportByDate(subdomain, index); // Replace 'your-subdomain' with actual subdomain
                          }}
                          className={`${activeState === index && "active"}`}
                        >
                          <a href="#">{label}</a>
                        </li>
                      ))}
                    </ul>
                    {/* <ul>
                      <li
                        onClick={() => setActiveState(0)}
                        className={`${activeState === 0 && "active"}`}
                      >
                        <a href="#">All</a>
                      </li>
                      <li
                        onClick={() => setActiveState(1)}
                        className={`${activeState === 1 && "active"}`}
                      >
                        <a href="#">Today</a>
                      </li>
                      <li
                        onClick={() => setActiveState(2)}
                        className={`${activeState === 2 && "active"}`}
                      >
                        <a href="#">Yesterday</a>
                      </li>
                      <li
                        onClick={() => setActiveState(3)}
                        className={`${activeState === 3 && "active"}`}
                      >
                        <a href="#">This Week</a>
                      </li>
                      <li
                        onClick={() => setActiveState(4)}
                        className={`${activeState === 4 && "active"}`}
                      >
                        <a href="#">This Month</a>
                      </li>
                      <li
                        onClick={() => setActiveState(5)}
                        className={`${activeState === 5 && "active"}`}
                      >
                        <a href="#">Last Month</a>
                      </li>
                      <li
                        onClick={() => setActiveState(6)}
                        className={`${activeState === 6 && "active"}`}
                      >
                        <a href="#">This Year</a>
                      </li>
                    </ul> */}
                  </div>
                  <div className="report_statics_right">
                    <span>{`${formatDate(startDate)} to ${formatDate(
                      endDate
                    )}`}</span>
                  </div>
                </div>
                <div className="salesreport_statics_list">
                  <div className="report_statics_column_left report_statics_column_custom">
                    <div className="report_statics_column">
                      <div className="statics_label statics_green">
                        <span>
                          Total Sales{" "}
                          <img
                            src="assets/img/sales-statics-info-icon.svg"
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="statics_value">
                        <span>{`$${totals?.sales.toFixed(2) ?? 0}`}</span>
                      </div>
                    </div>
                    <div className="report_statics_column">
                      <div className="statics_label statics_darkred">
                        <span>
                          Tax{" "}
                          <img
                            src="assets/img/sales-statics-info-icon.svg"
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="statics_value">
                        <span>{`$${totals?.tax.toFixed(2) ?? 0}`}</span>
                      </div>
                    </div>
                    <div className="report_statics_column">
                      <div className="statics_label statics_darkred">
                        <span>
                          Discounts{" "}
                          <img
                            src="assets/img/sales-statics-info-icon.svg"
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="statics_value">
                        <span>{`$${totals?.discount.toFixed(2) ?? 0}`}</span>
                      </div>
                    </div>
                    <div className="report_statics_column">
                      <div className="statics_label statics_darkred">
                        <span>
                          COGS{" "}
                          <img
                            src="assets/img/sales-statics-info-icon.svg"
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="statics_value">
                        <span>{`$${totals?.cogs.toFixed(2) ?? 0}`}</span>
                      </div>
                    </div>
                    <div className="report_statics_column">
                      <div className="statics_label statics_green">
                        <span>
                          Net Profit{" "}
                          <img
                            src="assets/img/sales-statics-info-icon.svg"
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="statics_value">
                        <span>{`$${totals?.profit.toFixed(2) ?? 0}`}</span>
                      </div>
                    </div>
                  </div>
                  <div className="report_statics_column_right report_statics_column_custom">
                    <div className="report_statics_column">
                      <div className="statics_label statics_blue">
                        <span>
                          No. of Products Sold{" "}
                          <img
                            src="assets/img/sales-statics-info-icon.svg"
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="statics_value">
                        <span>{totals?.productCount ?? 0}</span>
                      </div>
                    </div>
                    <div className="report_statics_column">
                      <div className="statics_label statics_blue">
                        <span>
                          No. of Devices Sold{" "}
                          <img
                            src="assets/img/sales-statics-info-icon.svg"
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="statics_value">
                        <span>{totals?.deviceCount ?? 0}</span>
                      </div>
                    </div>
                    <div className="report_statics_column">
                      <div className="statics_label statics_red">
                        <span>
                          No. of Services{" "}
                          <img
                            src="assets/img/sales-statics-info-icon.svg"
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="statics_value">
                        <span>{totals?.serviceCount ?? 0}</span>
                      </div>
                    </div>
                    <div className="report_statics_column">
                      <div className="statics_label statics_purple">
                        <span>
                          No. of Invoices{" "}
                          <img
                            src="assets/img/sales-statics-info-icon.svg"
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="statics_value">
                        <span>{totals?.transactions ?? 0}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="customertablemain">
                    <div className="table-responsive">
                      <table className="table table-striped sales_report_datatable common_datatable table-class">
                        <thead className="customtheadpadding">
                          <tr>
                            <th
                              scope="col"
                              className="customnameth  customnamethactive"
                            >
                              Store Name (<span>Subdomain</span>)
                            </th>
                            <th scope="col" className="customnameth">
                              No. of Services
                              <span
                                className={`upperarrow ${
                                  sortState === 0 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortServices();
                                  setSortState(0);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-up ${
                                    sortState === 0 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span
                                className={`lowerarrow ${
                                  sortState === 1 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortServices1();
                                  setSortState(1);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-down ${
                                    sortState === 1 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </th>

                            <th scope="col" className="customnameth">
                              Services
                            </th>
                            <th scope="col" className="customnameth">
                              New Customers
                            </th>
                            <th scope="col" className="customnameth">
                              No. of Products Sold
                              <span
                                className={`upperarrow ${
                                  sortState === 2 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortProduct();
                                  setSortState(2);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-up ${
                                    sortState === 2 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span
                                className={`lowerarrow ${
                                  sortState === 3 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortProduct1();
                                  setSortState(3);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-down ${
                                    sortState === 3 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </th>
                            <th scope="col" className="customnameth">
                              No. of Devices Sold
                              <span
                                className={`upperarrow ${
                                  sortState === 4 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortDevice();
                                  setSortState(4);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-up ${
                                    sortState === 4 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span
                                className={`lowerarrow ${
                                  sortState === 5 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortDevice1();
                                  setSortState(5);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-down ${
                                    sortState === 5 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </th>
                            <th scope="col" className="customnameth">
                              No. of Transactions
                              <span
                                className={`upperarrow ${
                                  sortState === 6 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortTransactions();
                                  setSortState(6);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-up ${
                                    sortState === 6 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span
                                className={`lowerarrow ${
                                  sortState === 7 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortTransactions1();
                                  setSortState(7);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-down ${
                                    sortState === 7 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </th>
                            <th scope="col" className="customnameth">
                              Total Sales{" "}
                              <img
                                src="assets/img/sales-statics-info-icon.svg"
                                alt=""
                              />{" "}
                              <span
                                className={`upperarrow ${
                                  sortState === 8 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortTotalSales();
                                  setSortState(8);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-up ${
                                    sortState === 8 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span
                                className={`lowerarrow ${
                                  sortState === 9 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortTotalSales1();
                                  setSortState(9);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-down ${
                                    sortState === 9 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </th>
                            <th scope="col" className="customnameth">
                              Avg Sales{" "}
                              <img
                                src="assets/img/sales-statics-info-icon.svg"
                                alt=""
                              />{" "}
                              <span
                                className={`upperarrow ${
                                  sortState === 10 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortAvgSales();
                                  setSortState(10);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-up ${
                                    sortState === 10 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span
                                className={`lowerarrow ${
                                  sortState === 11 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortAvgSales1();
                                  setSortState(11);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-down ${
                                    sortState === 11 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="sales_th_darkred customnameth"
                            >
                              Discounts{" "}
                              <img
                                src="assets/img/sales-statics-info-icon.svg"
                                alt=""
                              />{" "}
                              <span
                                className={`upperarrowred ${
                                  sortState === 12 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortDiscounts();
                                  setSortState(12);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-up ${
                                    sortState === 12 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span
                                className={`lowerarrowred ${
                                  sortState === 13 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortDiscounts1();
                                  setSortState(13);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-down ${
                                    sortState === 13 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="sales_th_darkred customnameth"
                            >
                              Refunds{" "}
                              <img
                                src="assets/img/sales-statics-info-icon.svg"
                                alt=""
                              />{" "}
                              <span
                                className={`upperarrowred ${
                                  sortState === 14 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortRefunds();
                                  setSortState(14);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-up ${
                                    sortState === 14 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span
                                className={`lowerarrowred ${
                                  sortState === 15 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortRefunds1();
                                  setSortState(15);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-down ${
                                    sortState === 15 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="sales_th_darkred customnameth"
                            >
                              COGS{" "}
                              <img
                                src="assets/img/sales-statics-info-icon.svg"
                                alt=""
                              />{" "}
                              <span
                                className={`upperarrowred ${
                                  sortState === 16 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortCOGS();
                                  setSortState(16);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-up ${
                                    sortState === 16 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span
                                className={`lowerarrowred ${
                                  sortState === 17 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortCOGS1();
                                  setSortState(17);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-down ${
                                    sortState === 17 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="sales_th_darkred customnameth"
                            >
                              Taxes{" "}
                              <img
                                src="assets/img/sales-statics-info-icon.svg"
                                alt=""
                              />{" "}
                              <span
                                className={`upperarrowred ${
                                  sortState === 18 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortTaxes();
                                  setSortState(18);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-up ${
                                    sortState === 18 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span
                                className={`lowerarrowred ${
                                  sortState === 19 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortTaxes1();
                                  setSortState(19);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-down ${
                                    sortState === 19 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </th>
                            <th scope="col" className="customnameth">
                              Net Profit Margin{" "}
                              <span
                                className={`upperarrow ${
                                  sortState === 20 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortNetProfitMargin();
                                  setSortState(20);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-up ${
                                    sortState === 20 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span
                                className={`lowerarrow ${
                                  sortState === 21 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortNetProfitMargin1();
                                  setSortState(21);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-down ${
                                    sortState === 21 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </th>
                            <th scope="col" className="sales_th_green ">
                              Net Profit{" "}
                              <span
                                className={`upperarrowgreen ${
                                  sortState === 22 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortNetProfit();
                                  setSortState(22);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-up ${
                                    sortState === 22 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span
                                className={`lowerarrowgreen ${
                                  sortState === 23 ? "active" : ""
                                }`}
                                onClick={() => {
                                  sortNetProfit1();
                                  setSortState(23);
                                }}
                              >
                                <i
                                  className={`fa fa-long-arrow-down ${
                                    sortState === 23 ? "active" : ""
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.map((store, index) => (
                            <tr key={index}>
                              <td>
                                <img
                                  src="assets/img/sales-report-prod-img.svg"
                                  alt=""
                                />
                                <span>{store.subDomain}</span>
                                <br />
                                <a href="#">View Report</a>{" "}
                                <span>
                                  (
                                  <a href="#">
                                    {store.subDomain.toUpperCase()}
                                  </a>
                                  )
                                </span>
                              </td>
                              <td>{store.serviceCount || 0}</td>
                              <td>
                                ${store.serviceCost?.toFixed(2) || "0.00"}
                              </td>
                              <td>{store.customerCount || 0}</td>
                              <td>{store.productCount || 0}</td>
                              <td>{store.deviceCount || 0}</td>
                              <td>{store.transactions || 0}</td>
                              <td>${store.sales?.toFixed(2) || "0.00"}</td>
                              <td>${store.avgSales?.toFixed(2) || "0.00"}</td>
                              <td>${store.discount?.toFixed(2) || "0.00"}</td>
                              <td>${store.refund?.toFixed(2) || "0.00"}</td>
                              <td>${store.cogs?.toFixed(2) || "0.00"}</td>
                              <td>${store.tax?.toFixed(2) || "0.00"}</td>
                              <td>{store.margin?.toFixed(2) || "0.00"}%</td>
                              <td>${store.profit?.toFixed(2) || "0.00"}</td>
                            </tr>
                          ))}
                          {/* {selectedStore === ""
                            ? data.map((store, index) => (
                                <tr key={index}>
                                  <td>
                                    <img
                                      src="assets/img/sales-report-prod-img.svg"
                                      alt=""
                                    />
                                    <span>{store.subDomain}</span>
                                    <br />
                                    <a href="#">View Report</a>{" "}
                                    <span>
                                      (
                                      <a href="#">
                                        {store.subDomain.toUpperCase()}
                                      </a>
                                      )
                                    </span>
                                  </td>
                                  <td>{store.serviceCount || 0}</td>
                                  <td>
                                    $
                                    {store.serviceCost
                                      ? store.serviceCost
                                      : "0.00"}
                                  </td>
                                  <td>{store.customerCount || 0}</td>
                                  <td>{store.productCount || 0}</td>
                                  <td>{store.deviceCount || 0}</td>
                                  <td>{store.transactions || 0}</td>
                                  <td>${store.sales}</td>
                                  <td>${store.avgSales}</td>
                                  <td>(${store.discount})</td>
                                  <td>(${store.refund})</td>
                                  <td>(${store.cogs})</td>
                                  <td>${store.tax}</td>
                                  <td>{store.margin}%</td>
                                  <td>${store.profit}</td>
                                </tr>
                              ))
                            : data
                                .filter(
                                  (storeData) =>
                                    storeData.subDomain === selectedStore
                                )
                                .map((store, index) => (
                                  <tr key={index}>
                                    <td>
                                      <img
                                        src="assets/img/sales-report-prod-img.svg"
                                        alt=""
                                      />
                                      <span>{store.subDomain}</span>
                                      <br />
                                      <a href="#">View Report</a>{" "}
                                      <span>
                                        (
                                        <a href="#">
                                          {store.subDomain.toUpperCase()}
                                        </a>
                                        )
                                      </span>
                                    </td>
                                    <td>{store.serviceCount || 0}</td>
                                    <td>
                                      $
                                      {store.serviceCost
                                        ? store.serviceCost
                                        : "0.00"}
                                    </td>
                                    <td>{store.customerCount || 0}</td>
                                    <td>{store.productCount || 0}</td>
                                    <td>{store.deviceCount || 0}</td>
                                    <td>{store.transactions || 0}</td>
                                    <td>${store.sales}</td>
                                    <td>${store.avgSales}</td>
                                    <td>(${store.discount})</td>
                                    <td>(${store.refund})</td>
                                    <td>(${store.cogs})</td>
                                    <td>${store.tax}</td>
                                    <td>{store.margin}%</td>
                                    <td>${store.profit}</td>
                                  </tr>
                                ))} */}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Totals</td>
                            <td>{totals.serviceCount}</td>
                            <td>${totals.serviceCost.toFixed(2)}</td>
                            <td>{totals.customerCount}</td>
                            <td>{totals.productCount}</td>
                            <td>{totals.deviceCount}</td>
                            <td>{totals.transactions}</td>
                            <td>${totals.sales.toFixed(2)}</td>
                            <td>${totals.avgSales.toFixed(2)}</td>
                            <td>${totals.discount.toFixed(2)}</td>
                            <td>${totals.refund.toFixed(2)}</td>
                            <td>${totals.cogs.toFixed(2)}</td>
                            <td>${totals.tax.toFixed(2)}</td>
                            <td>{totals.margin.toFixed(2)}%</td>
                            <td>${totals.profit.toFixed(2)}</td>
                          </tr>
                        </tfoot>
                      </table>
                      {data.length > 0 ? (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="custombottompaginationbox">
                              <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                  <li
                                    className="page-item custompaginationli  paginationleftbtnbox"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <button
                                      type="button"
                                      className="page-link"
                                      onClick={() =>
                                        handlePageClick(currentPage - 1)
                                      }
                                      disabled={currentPage === 1}
                                    >
                                      <img src="assets/img/previous.webp" />
                                    </button>
                                  </li>{" "}
                                  {pages.map((page, key) => {
                                    if (
                                      page === 1 ||
                                      page === totalPages ||
                                      (page >= startPage && page <= endPage)
                                    ) {
                                      return (
                                        <li
                                          className="page-item custompaginationli"
                                          key={key}
                                        >
                                          <a
                                            className={
                                              currentPage === page
                                                ? "page-link active customcountpagination"
                                                : "page-link customcountpagination"
                                            }
                                            onClick={() =>
                                              handlePageClick(page)
                                            }
                                            disabled={currentPage === page}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {page}
                                          </a>
                                        </li>
                                      );
                                    } else if (
                                      page === startPage - 1 ||
                                      page === endPage + 1
                                    ) {
                                      return (
                                        <li
                                          className="page-item custompaginationli"
                                          key={key}
                                        >
                                          <a
                                            className="page-link customcountpagination"
                                            style={{ cursor: "pointer" }}
                                          >
                                            ...
                                          </a>
                                        </li>
                                      );
                                    } else {
                                      return null;
                                    }
                                  })}
                                  <li
                                    className="page-item custompaginationli paginationrightbtnbox"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <button
                                      type="button"
                                      className="page-link"
                                      href="#"
                                      onClick={() =>
                                        handlePageClick(currentPage + 1)
                                      }
                                      disabled={currentPage === totalPages}
                                    >
                                      <img src="assets/img/next.webp" />
                                    </button>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>
      <div
        className="modal common_modal noteinfo_modal"
        id="noteinfoModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="noteinfoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="noteclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="assets/img/notes-close-icon.svg" alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className="notes_tabs">
                <ul
                  className="nav nav-pills notetabs"
                  id="notetabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="video_demo-tab"
                      data-toggle="tab"
                      href="#video_demo"
                      role="tab"
                      aria-controls="video_demo"
                      aria-selected="true"
                    >
                      Video Demo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="page_info-tab"
                      data-toggle="tab"
                      href="#page_info"
                      role="tab"
                      aria-controls="page_info"
                      aria-selected="false"
                    >
                      Page Information
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="settingContent">
                  <div
                    className="tab-pane fade active show"
                    id="video_demo"
                    role="tabpanel"
                    aria-labelledby="video_demo-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Video Demo</h4>
                      <p>
                        In this short tutorial, we’ll walk you through the key
                        features and functions of the Dashboard page.
                      </p>
                      <div className="notes_videos">
                        <img
                          src="assets/img/notes-demo-videos-img.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="page_info"
                    role="tabpanel"
                    aria-labelledby="page_info-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Page Information</h4>
                      <p>
                        This page provides a comprehensive view of all the tools
                        and features available to you. Each section is designed
                        to help you manage your business more efficiently,
                        whether you’re tracking sales, monitoring inventory, or
                        analyzing reports.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MultiStorereport;
