import React, { useEffect, useRef, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Link, useNavigate } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Swal from "sweetalert2";
import {
  AddBrandApi,
  AddCustomerDeviceApi,
  AddServiceApi,
  AddTagsApi,
  AddTicketApi,
  GetAllServiceApi,
  GetStatusApi,
  baseurl,
  customerAddApi,
  uploadImageApi,
} from "../Utility/Api";
import TicketDeviceModal from "./TicketDeviceModal";
import $ from "jquery";

const TicketModalList = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [images, setImages] = useState("");
  const [images1, setImages1] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, SetCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");
  const [error, setError] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [validstate, setValidState] = useState(false);
  const [imageMsg, setImageMsg] = useState("");
  const [countrycode, setCountryCode] = useState({});
  const [allDevices, setAllDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [searchTerm1, setSearchTerm1] = useState("");
  const [AllBrand1, setAllBrand1] = useState([]);
  const [typedText, setTypedText] = useState("");
  const [selectedBrand1, setSelectedBrand1] = useState(null);
  const [selectedBrandId1, setSelectedBrandId1] = useState(null);
  const [showList1, setShowList1] = useState(false);
  const [searchTermDevice, setSearchTermDevice] = useState("");
  const [showListDevice, setShowListDevice] = useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [driverLiecense, setDriverLiecense] = useState("");
  const [binLocation, setBinLocation] = useState("");
  const [allCustomer, setAllCustomer] = useState([]);
  const [showListCustomer, setShowListCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerPhone, setSelectedCustomerPhone] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [searchTermCustomer, setsearchTermCustomer] = useState("");
  const [showListService, setShowListService] = useState(false);
  const [showListService1, setShowListService1] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [selectedService1, setSelectedService1] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const [selectedServiceId1, setSelectedServiceId1] = useState("");
  const [searchTermService, setSearchTermService] = useState("");
  const [searchTermService1, setSearchTermService1] = useState("");
  const [password, setPassword] = useState("");
  const [description, setDescription] = useState("");
  const [allService, setAllService] = useState([]);
  const [allService1, setAllService1] = useState([]);
  const [allcustomerDevice, setAllCustomerDevice] = useState([]);
  const [adminState, setAdminState] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [imeiNumber, setIMEINumber] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [model, setModel] = useState("");
  const [description1, setDescription1] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const inputRef5 = useRef(null);
  const inputRef6 = useRef(null);
  const dropdownRef5 = useRef(null);
  const dropdownRef6 = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const dropdownRef3 = useRef(null);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      if (hasMore && !loading1) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore, loading1]);

  useEffect(() => {
    setAllCustomer([]);
    setFilteredData([]);
    setPage(1);
    GetCustomer(1, searchTermCustomer);
  }, [searchTermCustomer]);

  useEffect(() => {
    if (page > 1) {
      GetCustomer(page, searchTermCustomer);
    }
  }, [page]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef5.current &&
        !dropdownRef5.current.contains(event.target)
      ) {
        setShowList1(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef5]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef6.current &&
        !dropdownRef6.current.contains(event.target)
      ) {
        setShowListDevice(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef6]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        setShowListCustomer(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef1]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setShowListService(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef2]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef3.current &&
        !dropdownRef3.current.contains(event.target)
      ) {
        setShowListService1(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef3]);

  const GetAllDevices = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AJ0pT5xJJsG8rHqot1A6txMupaTPvbUM2.LHRBUtee7s0DQmEebl5p1hig5dbcABIB0m4VWSzru%2Fo"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllItems?productCategoryId=2`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setLoading(false);
          setAllDevices(result?.data.reverse());
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    $(".duedatepicker").datepicker({
      beforeShow: function (input, inst) {
        $(inst.dpDiv).addClass("commondatepicker");
      },
    });

    let localAdmin = localStorage.getItem("admin");
    GetStatus();
    setAdminState(localAdmin);
    GetAllBrandDevice();
    GetAllService();
    GetAllDevices();
    GetAllService1();
    // GetCustomer();
    GetCustomerDevice();
    // fetch(
    //   "https://ip-api.io/api/json?api_key=fabc32c1-943d-43c7-8a13-ea82cd6ac052"
    // )
    fetch(
      "https://ip-api.io/api/json?api_key=fabc32c1-943d-43c7-8a13-ea82cd6ac052"
    )
      .then((response) => response.json())
      .then((json) => {
        setCountryCode(json);
      });
  }, []);
  const GetCustomer = (page, searchTerm) => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${baseurl}getAllCustomer?pageSize=10&page=${page}&keyword=${searchTerm}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.status === true) {
          // setData(result?.data);
          // setFilteredData((prevData) =>
          //   Array.isArray(prevData)
          //     ? [...prevData, ...result?.data]
          //     : result?.data
          // );
          setFilteredData((prevData) => {
            // Combine previous data and new data
            const combinedData = Array.isArray(prevData)
              ? [...prevData, ...result?.data]
              : result?.data;

            // Remove duplicates by creating a Set of unique items based on a unique identifier (e.g., id)
            const uniqueData = Array.from(
              new Set(combinedData.map((item) => item.id))
            ).map((id) => combinedData.find((item) => item.id === id));

            return uniqueData;
          });

          setAllCustomer((prevData) =>
            Array.isArray(prevData)
              ? [...prevData, ...result.data]
              : result.data
          );
          // setFilteredData((prevData) =>
          //   Array.isArray(prevData)
          //     ? [...prevData, ...result?.data]
          //     : result?.data
          // );
          setHasMore(result?.data?.length > 0);
          // setData1(result?.data);
          // setSelectedCustomer(result?.data);
        } else {
          setHasMore(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  // const GetCustomer = () => {
  //   // setLoading(true);
  //   let local = localStorage.getItem("token");

  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer ${local}`);
  //   myHeaders.append(
  //     "Cookie",
  //     "connect.sid=s%3AxfhcNCivOB41NYdRGjOUwdWALKDJJF3B.6J1Kn6raommbRtkpj3s6PUE8uEwlSbM2zUY0hAUYBpI"
  //   );

  //   var requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(`${baseurl}getAllCustomer?pageSize=100&page=1`, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.status === true) {
  //         // setLoading(false);
  //         setAllCustomer(result?.data.reverse());
  //       } else {
  //         //setLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //       setLoading(false);
  //     });
  // };

  const isDisposableEmail = (email) => {
    const domain = email.split("@")[1];
    return disposableEmailDomains.includes(domain);
  };

  const disposableEmailDomains = [
    // "gmail.com",
    // "yahoo.com",
    // "outlook.com",
    // "hotmail.com",
    // "aol.com",
    // "icloud.com",
    // "mail.com",
    // "yandex.com",
    // "protonmail.com",
    // "zoho.com",
    // "gmx.com",
    // "ymail.com",
    // "comcast.net",
    // "me.com",
    // "msn.com",
    // "live.com",
    // "sbcglobal.net",
    // "verizon.net",
    // "att.net",
    // "cox.net",
    // "smartitventures.com",
    // Add more domains as needed
    "mailinator.com",
    "guerrillamail.com",
    "10minutemail.com",
    "temp-mail.org",
    "yopmail.com",
    "getnada.com",
    "trashmail.com",
    "fakeinbox.com",
    "throwawaymail.com",
    "maildrop.cc",
    "mytemp.email",
    "tempmailo.com",
    "dispostable.com",
    "mintemail.com",
    "airmail.com",
  ];

  const Validation = () => {
    let error = {};
    let reg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;
    // const isValid = /^[0-9]+$/.test(value);
    const regex = /^[A-Za-z ]+$/;

    const isNumeric = /^[0-9]+$/;
    if (email && !reg.test(email)) {
      error.email = "Invalid email.";
    } else if (email && isDisposableEmail(email)) {
      error.email =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }
    if (!firstName) {
      error.firstName = "First Name Is Required";
    } else if (!regex.test(firstName)) {
      error.firstName = "First Name Is Invalid";
    }

    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      CustomerApi();
    }
    return error;
  };

  const CustomerApi = () => {
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    let local = localStorage.getItem("token");
    let userid = localStorage.getItem("userId");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AyaHFql-YNXNgdQlka4nRvpWL-iJLSdB-.%2B6wW6d812YXYU4ihAgIwRTFu54mu6VtFKEcdK50W57A"
    );

    var raw = JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNo, // phoneNo ? phoneNo.replace(/^\+/, "") : "",
      profileImage: images,
      country: country,
      countryCode: String(countrycode.country_code),
      streetAddress: address,
      state: state,
      zipCode: zipcode,
      city: city,
      mainUserId: userid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("raww", raw);
    fetch(customerAddApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("result", result);
        if (result.status === true) {
          setShowModal(false);
          GetCustomer();
          setMessage(result?.message);
          // setFirstName(result?.data?.firstName);
          setSelectedCustomer(result?.data?.firstName);
          setSelectedCustomerPhone(result?.data?.phoneNumber);
          setSelectedCustomerId(result?.data?.id);
          setValidState(false);
        } else {
          setMessage(result?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const SubmitCus = () => {
    setError(Validation());
  };

  const handleImages = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 5000; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (file.size < minSizeInBytes) {
        setImageMsg("Image size is below the minimum allowed size (5 KB)");
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);
        return;
      }

      // Check if the file name contains only one dot before the extension
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf(".");
      const baseName = fileName.substring(0, lastDotIndex);
      const extension = fileName.substring(lastDotIndex + 1);

      // Only allow files with a single dot in the base name
      if (baseName.includes(".")) {
        setImageMsg(
          "File name is not valid. Only one dot is allowed before the extension."
        );
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);

        return;
      }

      let size = file.type.replace("/", ".");
      var blob = file.slice(0, file.size, file.type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: file.type });

      UploadImage(newFile);
    }
  };

  const UploadImage = async (newFile) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "customers-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setImages(result?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const handleImages1 = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 5000; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (file.size < minSizeInBytes) {
        setImageMsg("Image size is below the minimum allowed size (5 KB)");
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);
        return;
      }

      // Check if the file name contains only one dot before the extension
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf(".");
      const baseName = fileName.substring(0, lastDotIndex);
      const extension = fileName.substring(lastDotIndex + 1);

      // Only allow files with a single dot in the base name
      if (baseName.includes(".")) {
        setImageMsg(
          "File name is not valid. Only one dot is allowed before the extension."
        );
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);

        return;
      }

      let size = file.type.replace("/", ".");
      var blob = file.slice(0, file.size, file.type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: file.type });

      UploadImage1(newFile);
    }
  };

  const UploadImage1 = async (newFile) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "ticket-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setImages1(result?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handlePhoneChange = (value) => {
    setPhoneNo(value);
    const countryInfo = value && value.match(/^\+(\d+)/);
    const countryCode = countryInfo ? `+${countryInfo[1]}` : "";
  };

  const HandleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setImages("");
        });
      }
    });
  };
  const HandleDeleteOne = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setImages1("");
        });
      }
    });
  };

  const cancleValues = () => {
    setImages("");
    setEmail("");
    setError("");
    setFirstName("");
    setLastName("");
    setAddress("");
    setState("");
    setCountry("");
    SetCity("");
    setPhoneNo("");
    setZipcode("");
    setValidState(false);
    setMessage("");
  };

  const AddBrandDevice = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 2,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddBrandApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllBrandDevice();
          setSelectedBrandId1(result?.data?.id);
          setSearchTerm1("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllBrandDevice = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllBrand?productCategoryId=2`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllBrand1(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleAddNewClickDevice = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Brand name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    setSelectedBrand1(typedText);
    setShowList1(false);
    setSearchTerm1("");
    setTypedText("");
    AddBrandDevice();
  };
  const handleBrandSelect1 = (brand) => {
    setSelectedBrand1(brand.name);
    setSelectedBrandId1(brand.id);
    setShowList1(false);
  };
  const filteredBrands1 = AllBrand1.filter((brand) => {
    const name = brand.name;
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTerm1.toLowerCase());
    }

    return false;
  });

  const handleButtonClick1 = (e) => {
    e.preventDefault();
    setShowList1(!showList1);
  };

  const handleSearchChange1 = (e) => {
    setSearchTerm1(e.target.value);
    setTypedText(e.target.value);
    setSelectedBrand1(null);
  };

  const filteredDevice = allcustomerDevice.filter((brand) => {
    const name = brand.model;
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermDevice.toLowerCase());
    }

    return false;
  });

  const handleButtonClickDevice = (e) => {
    e.preventDefault();
    setShowListDevice(!showListDevice);
  };

  const handleSearchChangeDevice = (e) => {
    setSearchTermDevice(e.target.value);
    setTypedText(e.target.value);
    setSelectedDevice(null);
  };
  const handleBrandSelectDevice = (brand) => {
    setSelectedDevice(brand.model);
    setSelectedDeviceId(brand.id);
    setShowListDevice(false);
  };

  const onpress = () => {
    setShowModal1(false);
  };
  const filteredCustomer = filteredData.filter((customer) => {
    const firstName = customer?.firstName?.toLowerCase();
    const lastName = customer?.lastName?.toLowerCase();
    const phoneNumber = customer?.phoneNumber?.toLowerCase();
    const searchTerm = searchTermCustomer.toLowerCase();

    // Check if any of the fields contain the search term
    return (
      (firstName && firstName.includes(searchTerm)) ||
      (lastName && lastName.includes(searchTerm)) ||
      (phoneNumber && phoneNumber.includes(searchTerm))
    );
  });

  // const filteredCustomer = allCustomer.filter((brand) => {
  //   const name = brand.firstName;
  //   if (name && typeof name === "string") {
  //     return name.toLowerCase().includes(searchTermCustomer.toLowerCase());
  //   }

  //   return false;
  // });

  const handleCustomerSelect = (brand) => {
    setSelectedCustomer(brand.firstName);
    setSelectedCustomerId(brand.id);
    setShowListCustomer(false);
    GetCustomerDevice(brand.id);
    setSelectedCustomerPhone(brand?.phoneNumber);

    if (error.selectedCustomer) {
      setError({ ...error, selectedCustomer: "" });
    }
  };
  const handleSearchChangeCustomer = (e) => {
    setsearchTermCustomer(e.target.value);
    setTypedText(e.target.value);
    setSelectedCustomer(null);
  };

  const AddService = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: typedText,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddServiceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setSelectedService(result?.data?.name);
          setSelectedServiceId(result?.data?.id);
          GetAllService();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetAllService = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllServiceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllService(result?.data.reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const filteredService = allService.filter((brand) => {
    const name = brand.name;
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermService.toLowerCase());
    }

    return false;
  });
  const navigate = useNavigate();
  const handleServiceSelect = (brand) => {
    setSelectedService(brand.name);
    setSelectedServiceId(brand.id);
    setShowListService(false);
    if (error.selectedService) {
      setError({ ...error, selectedService: "" });
    }
  };
  const handleSearchChangeService = (e) => {
    setSearchTermService(e.target.value);
    setTypedText(e.target.value);
    setSelectedService(null);
  };
  const handleButtonClickService = (e) => {
    e.preventDefault();
    setShowListService(!showListService);
  };

  const handleAddNewClickService = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Service name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    setSelectedService(typedText);
    setShowListService(false);
    setSearchTermService("");
    setTypedText("");
    AddService();
  };

  const AddService1 = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: typedText,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddServiceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setSelectedService1(result?.data?.name);
          setSelectedServiceId1(result?.data?.id);
          GetAllService1();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetAllService1 = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllServiceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllService1(result?.data.reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const filteredService1 = allService1.filter((brand) => {
    const name = brand.name;
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermService.toLowerCase());
    }

    return false;
  });

  const handleServiceSelect1 = (brand) => {
    setSelectedService1(brand.name);
    setSelectedServiceId1(brand.id);
    setShowListService1(false);
  };
  const handleSearchChangeService1 = (e) => {
    setSearchTermService1(e.target.value);
    setTypedText(e.target.value);
    setSelectedService1(null);
  };
  const handleButtonClickService1 = (e) => {
    e.preventDefault();
    setShowListService1(!showListService);
  };

  const handleAddNewClickService1 = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Service name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    setSelectedService1(typedText);
    setShowListService1(false);
    setSearchTermService1("");
    setTypedText("");
    AddService1();
  };

  const ValidationTicket = () => {
    let error = {};
    if (!selectedCustomer) {
      error.selectedCustomer = "Customer Name is Required";
    }
    if (!selectedService) {
      error.selectedService = "Service is Required";
    }
    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      AddTicket();
    }
    return error;
  };

  const OnSubmitTicket = () => {
    setError(ValidationTicket());
  };

  const [allstatus, setAllStatus] = useState([]);
  const [statusId, setStatusId] = useState("");

  const GetStatus = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetStatusApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const id = result?.data;
        const sortedStatusList = result?.data.sort((a, b) => {
          if (a?.name === "New") return -1;
          if (b?.name === "New") return 1;

          if (a?.name === "Finished") return 1;
          if (b?.name === "Finished") return -1;
          return a.name.localeCompare(b.name);
        });
        // setAllStatus(sortedStatusList);
        const defaultNewStatus = sortedStatusList.find(
          (status) => status.name === "New"
        );

        if (defaultNewStatus) {
          setStatusId(defaultNewStatus.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const AddTicket = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    let userid = localStorage.getItem("userId");
    let subuserid = localStorage.getItem("subuserId");
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      customerId: selectedCustomerId,
      customerDeviceId: selectedDeviceId,
      brandId: selectedBrandId1,
      serviceId1: selectedServiceId,
      serviceId2: selectedServiceId1 ? selectedServiceId1 : 0,
      statusOptionId: props?.status,
      techAssignedId: adminState === "0" ? subuserid : userid,
      salesPersonId: adminState === "0" ? subuserid : userid,
      dueDate: dueDate,
      password: password,
      description: description,
      drivingLicence: driverLiecense,
      bin: binLocation,
      isAdmin: adminState,
      isTechAdmin: adminState,
      isSalesPersonAdmin: adminState,
      techAssignedName: "",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("adddticket raww", raw);

    fetch(AddTicketApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          props.onpress(false);
          navigate("/ticketdetail", { state: { data: result?.data } });
          // props.onload();
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const GetCustomerDevice = (id) => {
    let local = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllCustomerDevice?customerId=${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllCustomerDevice(result?.data.reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const Validate = () => {
    let error = {};
    if (!model) {
      error.model = "Model Name Is Required";
    }
    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      CustomerDevice();
    }
    return error;
  };

  const OnSubmit = () => {
    setError(Validate());
  };

  const maxDescriptionLength = 200;
  const handleDescriptionChange1 = (e) => {
    const newDescription = e.target.value;

    if (newDescription.length <= maxDescriptionLength) {
      setDescription1(newDescription);
      setErrormsg("");
    } else if (newDescription.length > maxDescriptionLength) {
      setTimeout(() => {
        setErrormsg("Allow Maximum 200 Characters");
      }, 2000);
    }
  };
  const CustomerDevice = () => {
    let local = localStorage.getItem("token");
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      brandId: selectedBrandId1,
      description: description1,
      imeiNumber: imeiNumber,
      customerId: selectedCustomerId,
      model: model,
      image: images1,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddCustomerDeviceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetCustomerDevice(selectedCustomerId);
          setSelectedDevice(result?.data?.model);
          setSelectedDeviceId(result?.data?.id);
          setShowModal1(false);
          //  props.onpress(false);
          //  props.onload(result?.data?.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      {loading ? (
        <div className="landing_loader">
          <div className="innerloader">
            <img src="img/spin-1s-156px.gif" alt="" />
          </div>
        </div>
      ) : null}
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="newserviceModalLabel">
              Create New Service:
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setSelectedCustomer("");
                setSelectedBrand1("");
                setSelectedDevice("");
                setSelectedService("");
                setSelectedService1("");
                setDescription("");
                setBinLocation("");
                setPassword("");
                setDriverLiecense("");
                setError("");
              }}
            >
              <span className="close_icon"></span>
            </button>
            <p>To start selling, all you need is a name and a price.</p>
          </div>
          <div className="modal-body">
            <div className="custom_form newservice_form">
              <form action="" method="" className="cus_form">
                <div className="form_row">
                  <div className="form_column col_full">
                    <div className="form-group">
                      <label className="cus_label label_red">
                        Customer Name<span>*</span>
                      </label>
                      <div
                        className="formfield field_wd75  add_cust_field selectcustomerboxcustmlist"
                        ref={dropdownRef1}
                      >
                        <div className="tagsscustomdiv">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={() =>
                              setShowListCustomer(!showListCustomer)
                            }
                          >
                            <input
                              type="text"
                              className="form-control customsearchinputboxsetting  "
                              name="firstName"
                              placeholder="Enter Customer Name"
                              value={
                                selectedCustomerPhone
                                  ? `${selectedCustomer} (${selectedCustomerPhone})`
                                  : selectedCustomer || searchTermCustomer
                              }
                              // value={selectedCustomer || searchTermCustomer}
                              onChange={handleSearchChangeCustomer}
                              onClick={() =>
                                setShowListCustomer(!showListCustomer)
                              }
                            />
                            <a
                              href="#"
                              className="newcust_add"
                              data-toggle="modal"
                              data-target="#addcustomerModal"
                              onClick={() => {
                                setShowModal(true);
                                setImages("");
                                setEmail("");
                                setError("");
                                setFirstName("");
                                setLastName("");
                                setAddress("");
                                setState("");
                                setCountry("");
                                SetCity("");
                                setPhoneNo("");
                                setZipcode("");
                                setValidState(false);
                                setMessage("");
                              }}
                            >
                              <i className="fa fa-plus"></i> New
                            </a>{" "}
                          </div>
                          {showListCustomer && (
                            <div className="addselectbox">
                              <ul>
                                {filteredCustomer.map((brand) => (
                                  <li
                                    key={brand.id}
                                    onClick={() => handleCustomerSelect(brand)}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    {brand?.firstName}{" "}
                                    {brand?.phoneNumber
                                      ? `(${brand.phoneNumber})`
                                      : ""}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {error.selectedCustomer && (
                      <span
                        style={{
                          fontSize: "12px",
                          color: "red",
                          marginLeft: "120px",
                        }}
                      >
                        {error.selectedCustomer}
                      </span>
                    )}
                  </div>
                  {/* <div className="form_column col_full">
                    <div className="form-group">
                      <label className="cus_label">Brand:</label>
                      <div
                        className="formfield field_wd75 selectcustomerboxcustmlist"
                        ref={dropdownRef5}
                      >
                        <div className="tagsscustomdiv">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={() => setShowList1(!showList1)}
                          >
                            <input
                              ref={inputRef5}
                              type="text"
                              className="customsearchinputboxsetting"
                              placeholder={`Select Brand`}
                              value={selectedBrand1 || searchTerm1}
                              onChange={handleSearchChange1}
                              onClick={() => setShowList1(!showList1)}
                            />
                            <button
                              onClick={handleButtonClick1}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src="assets/img/select-arrow.webp"
                                alt="Handy Icon"
                              />
                            </button>
                          </div>

                          {showList1 && (
                            <div className="addselectbox">
                              <ul>
                                {filteredBrands1.map((brand) => (
                                  <li
                                    key={brand.id}
                                    onClick={() => handleBrandSelect1(brand)}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    {brand.name}
                                  </li>
                                ))}
                              </ul>

                              <button
                                onClick={() => {
                                  handleAddNewClickDevice();
                                  if (inputRef5.current) {
                                    inputRef5.current.focus();
                                  }
                                }}
                                style={{ backgroundColor: "#9d3dd3" }}
                                type="button"
                                className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                              >
                                <span>+</span> Add New Brand
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="form_column col_full cus_searchdropdown">
                    <div className="form-group">
                      <label className="cus_label">Device:</label>
                      <div
                        className="formfield field_wd75 selectcustomerboxcustmlist"
                        ref={dropdownRef6}
                      >
                        <div
                          class="form-control"
                          onClick={() => setShowListDevice(!showListDevice)}
                        >
                          {selectedDevice || "Select Device"}
                          <img
                            src="assets/img/select-arrow.webp"
                            alt="Handy Icon"
                            onClick={handleButtonClickDevice}
                            style={{ cursor: "pointer" }}
                          />
                        </div>

                        {showListDevice && (
                          <>
                            <div className="tagsscustomdiv">
                              <input
                                ref={inputRef6}
                                type="text"
                                className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                placeholder={`Enter a Device`}
                                value={selectedDevice || searchTermDevice}
                                onChange={handleSearchChangeDevice}
                              />
                              <div className="addselectbox">
                                <ul>
                                  {filteredDevice.length > 0 ? (
                                    filteredDevice.map((brand) => (
                                      <li
                                        key={brand.id}
                                        onClick={() =>
                                          handleBrandSelectDevice(brand)
                                        }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {brand.model}
                                      </li>
                                    ))
                                  ) : (
                                    <div
                                      style={{
                                        padding: "8px",
                                        color: "#999",
                                        textAlign: "center",
                                      }}
                                    >
                                      No results matching "{searchTermDevice}"
                                    </div>
                                  )}
                                </ul>
                                <button
                                  data-toggle="modal"
                                  data-target="#createproductModal"
                                  onClick={() => {
                                    if (selectedCustomerId) {
                                      setShowModal1(true);
                                    } else {
                                      Swal.fire({
                                        title: "Please Select Customer First!",
                                        customClass: {
                                          container: "custom-swal-container",

                                          confirmButton:
                                            "custom-swal-confirm-button",
                                        },
                                      });
                                    }
                                    if (inputRef6.current) {
                                      inputRef6.current.focus();
                                    }
                                  }}
                                  style={{ backgroundColor: "#9d3dd3" }}
                                  type="button"
                                  className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                >
                                  <span>+</span> Add New Device
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form_column col_full cus_searchdropdown">
                    <div className="form-group">
                      <label className="cus_label label_red">Service:</label>
                      <div
                        className="formfield field_wd75 selectcustomerboxcustmlist"
                        ref={dropdownRef2}
                      >
                        <div
                          class="form-control"
                          onClick={() => setShowListService(!showListService)}
                        >
                          {selectedService || " Select Service"}
                          <img
                            src="assets/img/select-arrow.webp"
                            alt="Handy Icon"
                            onClick={handleButtonClickService}
                            style={{ cursor: "pointer" }}
                          />
                        </div>

                        {showListService && (
                          <>
                            <div className="tagsscustomdiv">
                              <input
                                ref={inputRef2}
                                type="text"
                                className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                placeholder={`Enter a Service`}
                                value={selectedService || searchTermService}
                                onChange={handleSearchChangeService}
                              />
                              <div className="addselectbox">
                                <ul>
                                  {filteredService.length > 0 ? (
                                    filteredService.map((brand) => (
                                      <li
                                        key={brand.id}
                                        onClick={() =>
                                          handleServiceSelect(brand)
                                        }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {brand.name}
                                      </li>
                                    ))
                                  ) : (
                                    <div
                                      style={{
                                        padding: "8px",
                                        color: "#999",
                                        textAlign: "center",
                                      }}
                                    >
                                      No results matching "{searchTermService}"
                                    </div>
                                  )}
                                </ul>
                                {typedText &&
                                  !filteredService.includes(typedText) && (
                                    <button
                                      onClick={() => {
                                        handleAddNewClickService();
                                        if (inputRef2.current) {
                                          inputRef2.current.focus();
                                        }
                                      }}
                                      style={{ backgroundColor: "#9d3dd3" }}
                                      type="button"
                                      className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                    >
                                      <span>+</span> Add New Service "
                                      {typedText}" as a new service
                                    </button>
                                  )}
                              </div>{" "}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {error.selectedService && (
                      <span
                        style={{
                          fontSize: "12px",
                          color: "red",
                          marginLeft: "120px",
                        }}
                      >
                        {error.selectedService}
                      </span>
                    )}
                  </div>
                  <div className="form_column col_full cus_searchdropdown">
                    <div className="form-group">
                      <label className="cus_label">Service2:</label>
                      <div
                        className="formfield field_wd75 selectcustomerboxcustmlist"
                        ref={dropdownRef3}
                      >
                        <div
                          class="form-control"
                          onClick={() => setShowListService1(!showListService1)}
                        >
                          {selectedService1 || "Select Service2"}
                          <img
                            src="assets/img/select-arrow.webp"
                            alt="Handy Icon"
                            onClick={handleButtonClickService1}
                            style={{ cursor: "pointer" }}
                          />
                        </div>

                        {showListService1 && (
                          <>
                            <div className="tagsscustomdiv">
                              <input
                                ref={inputRef3}
                                type="text"
                                className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                placeholder={`Enter a Service2`}
                                value={selectedService1 || searchTermService1}
                                onChange={handleSearchChangeService1}
                              />
                              <div className="addselectbox">
                                <ul>
                                  {filteredService1.length > 0 ? (
                                    filteredService1.map((brand) => (
                                      <li
                                        key={brand.id}
                                        onClick={() =>
                                          handleServiceSelect1(brand)
                                        }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {brand.name}
                                      </li>
                                    ))
                                  ) : (
                                    <div
                                      style={{
                                        padding: "8px",
                                        color: "#999",
                                        textAlign: "center",
                                      }}
                                    >
                                      No results matching "{searchTermService1}"
                                    </div>
                                  )}
                                </ul>
                                {typedText &&
                                  !filteredService1.includes(typedText) && (
                                    <button
                                      onClick={() => {
                                        handleAddNewClickService1();
                                        if (inputRef3.current) {
                                          inputRef3.current.focus();
                                        }
                                      }}
                                      style={{ backgroundColor: "#9d3dd3" }}
                                      type="button"
                                      className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                    >
                                      <span>+</span> Add New Service2 "
                                      {typedText}" as a new service2
                                    </button>
                                  )}
                              </div>{" "}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form_column col_full">
                    <div className="form-group">
                      <label className="cus_label">Due Date:</label>
                      <div className="formfield field_wd75">
                        <input
                          type="date"
                          className="form-control"
                          name="duedate"
                          placeholder="03/26/2023"
                          autoComplete="off"
                          value={dueDate}
                          onChange={(e) => setDueDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form_column col_full">
                    <div className="form-group">
                      <label className="cus_label">Password:</label>
                      <div className="formfield field_wd100">
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          placeholder="*****"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          autoComplete="new-password"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form_column col_full">
                    <div className="form-group">
                      <label className="cus_label">Bin Location:</label>
                      <div className="formfield field_wd100">
                        <input
                          type="text"
                          className="form-control"
                          name="binLocation"
                          placeholder="Location"
                          value={binLocation}
                          onChange={(e) => setBinLocation(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form_column col_full">
                    <div className="form-group">
                      <label className="cus_label">Drivers Licence:</label>
                      <div className="formfield field_wd100">
                        <input
                          type="text"
                          className="form-control"
                          name="driverLiecense"
                          placeholder="Drivers Licence"
                          value={driverLiecense}
                          onChange={(e) => setDriverLiecense(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form_column col_full">
                    <div className="form-group">
                      <label className="cus_label">Description:</label>
                      <div className="formfield field_wd100">
                        <textarea
                          className="form-control"
                          name="description"
                          placeholder="Enter Description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary default_btn"
              data-dismiss="modal"
              onClick={() => {
                setSelectedCustomer("");
                setSelectedBrand1("");
                setSelectedDevice("");
                setSelectedService("");
                setSelectedService1("");
                setDescription("");
                setBinLocation("");
                setPassword("");
                setDriverLiecense("");
                setError("");
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary theme_btn"
              onClick={() => OnSubmitTicket()}
            >
              Done
            </button>
          </div>
        </div>
      </div>

      {showModal === true ? (
        <div
          className="modal fade common_modal addcustomer_modal"
          id="addcustomerModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="addcustomerModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="addcustomerModalLabel">
                  Add New Customer
                </h5>
                <button
                  type="button"
                  className="close"
                  //data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    handleModalClose();
                    cancleValues();
                    document
                      .querySelectorAll(".modal-backdrop")
                      .forEach((el) => el.classList.remove("modal-backdrop"));
                  }}
                >
                  <span className="close_icon" />
                </button>
              </div>
              <div className="modal-body">
                <div className="custom_form customer_form">
                  <form action="" method="" className="cus_form">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="upload_files">
                            {images ? (
                              <img
                                className="uploaded_img"
                                src={images}
                                alt=""
                              />
                            ) : (
                              <img
                                style={{ display: "none" }}
                                className="uploaded_img"
                                src=""
                                alt=""
                              />
                            )}
                            <div className="file_upload">
                              <input
                                type="file"
                                name="files"
                                accept="image/jpeg, image/jpg, image/png"
                                onChange={handleImages}
                              />
                              <div className="fileedit_icon">
                                <img
                                  src="assets/img/file-edit-icon.webp"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="filetrash_icon deleteimgcust">
                              <a
                                href="#"
                                onClick={() => {
                                  if (images) {
                                    HandleDelete();
                                  }
                                }}
                              >
                                <img
                                  src="assets/img/file-trash-icon.webp"
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>
                          {imageMsg && (
                            <span className="customvalidationdesignlogin">
                              {imageMsg}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <h5>Credentials</h5>
                      </div>

                      <div className="col-md-6">
                        <div className="form_column col_half">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="first_name"
                              placeholder="First Name"
                              value={firstName}
                              onChange={(e) => {
                                setFirstName(e.target.value.trim());
                                if (error.firstName) {
                                  setError({ ...error, firstName: "" });
                                }
                              }}
                            />
                            {error.firstName && (
                              <span className="customvalidationdesignlogin">
                                {error.firstName}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 lastnamecust">
                        <div className="form_column col_half">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="last_name"
                              placeholder="Last Name"
                              value={lastName}
                              onChange={(e) => {
                                setLastName(e.target.value.trim());
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form_column col_half">
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="Email Address"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value.trim());
                                if (error.email) {
                                  setError({ ...error, email: "" });
                                }
                              }}
                            />
                            {error.email && (
                              <span className="customvalidationdesignlogin">
                                {error.email}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 lastnamecust">
                        <div className="form_column col_half">
                          <div className="form-group">
                            <PhoneInput
                              className="form-control"
                              placeholder="Enter phone number"
                              international
                              countryCallingCodeEditable={false}
                              //limitMaxLength={10}
                              value={phoneNo}
                              onChange={(val) => {
                                handlePhoneChange(val);
                                // if (error.phoneNo) {
                                //   setError({ ...error, phoneNo: "" });
                                // }
                                setValidState(true);
                              }}
                              //   onKeyup={setValidState(true)}
                              defaultCountry={countrycode.country_code}
                              //defaultCountry="IN"
                              // defaultCountry={country.countryCode}
                              // error={
                              //   value
                              //     ? isValidPhoneNumber(value)
                              //       ? undefined
                              //       : "Invalid phone number"
                              //     : "Phone number required"
                              // }
                            />

                            {/* {error.phoneNo && (
                              <span className="customvalidationdesignlogin">
                                {error.phoneNo}
                              </span>
                            )} */}
                            {validstate === true ? (
                              <span className="customvalidationdesignlogin">
                                {phoneNo && isValidPhoneNumber(phoneNo)
                                  ? ""
                                  : "Invalid phone number"}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <h5>Address</h5>
                      </div>
                      <div className="col-md-12">
                        <div className="form_column col_full">
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              name="address"
                              placeholder="Street Address"
                              value={address}
                              onChange={(e) => {
                                setAddress(e.target.value);
                              }}
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form_column col_half">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="country"
                              placeholder="Country"
                              value={country}
                              onChange={(e) => {
                                setCountry(e.target.value.trim());
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form_column col_half">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="city"
                              placeholder="City"
                              value={city}
                              onChange={(e) => {
                                SetCity(e.target.value.trim());
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form_column col_half">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="state"
                              placeholder="State / Province"
                              value={state}
                              onChange={(e) => {
                                setState(e.target.value.trim());
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form_column col_half">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="zipcode"
                              placeholder="Zip Code"
                              value={zipcode}
                              onChange={(e) => {
                                setZipcode(e.target.value.trim());
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {message && (
                        <span className="customvalidationdesignlogin">
                          {message}
                        </span>
                      )}
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary default_btn"
                  //data-dismiss="modal"
                  onClick={() => {
                    handleModalClose();
                    cancleValues();
                    document
                      .querySelectorAll(".modal-backdrop")
                      .forEach((el) => el.classList.remove("modal-backdrop"));
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary theme_btn"
                  onClick={() => SubmitCus()}
                >
                  Save Customer
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showModal1 === true ? (
        <div
          className="modal fade common_modal createproduct_modal"
          id="createproductModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="createproductModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="createproductModalLabel">
                  Create New Device
                </h5>

                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={() => {
                    onpress();
                    document
                      .querySelectorAll(".modal-backdrop")
                      .forEach((el) => el.classList.remove("modal-backdrop"));
                    // setTitle("");
                    // setImages("");
                    // setDescription("");
                    // setPrice("");
                    // setUnitCost("");
                    // setAverageCost("");
                    // setMinimunPrice("");
                    // setSKUFill("");
                    // setSelectedBrand("");
                    // setSelectedCategory("");
                    // setSelectedTags([]);
                    // setSelectedSuppiler("");
                  }}
                >
                  <span className="close_icon"></span>
                </button>
              </div>
              <div className="modal-body">
                <div className="custom_form createproduct_form">
                  <form action="" method="" className="cus_form">
                    <div className="form_row product_radio_row">
                      <div className="form_column col_full">
                        <div className="form-group"></div>
                      </div>
                    </div>

                    <div className="product_row">
                      <div className="product_col100 cus_prod_col">
                        <h4>
                          General information<span>*</span>
                        </h4>
                        {/* <p>
                          To start selling, all you need is a name and a price.
                        </p> */}
                        <div className="form_row">
                          <div className="form_column col_full">
                            <div className="form-group">
                              <label className="cus_label">IMEI/Serial</label>
                              <div className="formfield field_wd100">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="serialno"
                                  placeholder="Enter IMEI/Serial"
                                  value={imeiNumber}
                                  onChange={(e) =>
                                    setIMEINumber(e.target.value.trim())
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form_column col_full">
                            <div className="form-group">
                              <label className="cus_label">Brand:</label>
                              <div
                                className="formfield field_wd100 selectcustomerboxcustmlist"
                                ref={dropdownRef5}
                              >
                                <div className="tagsscustomdiv">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => setShowList1(!showList1)}
                                  >
                                    <input
                                      ref={inputRef5}
                                      type="text"
                                      className="customsearchinputboxsetting inputdeviceformcustmbox form-control"
                                      placeholder={`Select Brand`}
                                      value={selectedBrand1 || searchTerm1}
                                      onChange={handleSearchChange1}
                                      onClick={() => setShowList1(!showList1)}
                                    />

                                    <img
                                      src="assets/img/select-arrow.webp"
                                      alt="Handy Icon"
                                      onClick={handleButtonClick1}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>

                                  {showList1 && (
                                    <div className="addselectbox">
                                      <ul>
                                        {filteredBrands1.map((brand) => (
                                          <li
                                            key={brand.id}
                                            onClick={() =>
                                              handleBrandSelect1(brand)
                                            }
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            {brand.name}
                                          </li>
                                        ))}
                                      </ul>

                                      <button
                                        onClick={() => {
                                          handleAddNewClickDevice();
                                          if (inputRef5.current) {
                                            inputRef5.current.focus();
                                          }
                                        }}
                                        style={{ backgroundColor: "#9d3dd3" }}
                                        type="button"
                                        className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                      >
                                        <span>+</span> Add New Brand
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form_column col_full">
                            <div className="form-group">
                              <label className="cus_label label_red">
                                Model Name<span>*</span>
                              </label>
                              {/* <div className="formfield field_wd100">
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              placeholder="Classic Bathrobe"
                              value={title}
                              onChange={(e) => {
                                setTitle(e.target.value);
                                if (error.title) {
                                  setError({ ...error, title: "" });
                                }
                              }}
                            />{" "}


                          </div>{" "} */}

                              <div
                                className="formfield field_wd100  add_cust_field selectcustomerboxcustmlist"
                                //ref={dropdownRef1}
                              >
                                <div className="tagsscustomdiv">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    // onClick={() => setShowList(!showList)}
                                  >
                                    <input
                                      type="text"
                                      className="form-control customsearchinputboxsetting  "
                                      name="firstName"
                                      placeholder="Enter Model Name"
                                      value={model}
                                      onChange={(e) => {
                                        setModel(e.target.value);
                                        if (error.model) {
                                          setError({ ...error, model: "" });
                                        }
                                      }}
                                      //onClick={() => setShowList(!showList)}
                                    />
                                  </div>
                                  {/* {showList && (
                                    <div className="addselectbox">
                                      <ul>
                                        {filteredDevices.map((brand) => (
                                          <li
                                            key={brand.id}
                                            onClick={() =>
                                              handleDeviceSelect(brand)
                                            }
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            {brand.model}
                                          </li>
                                        ))}
                                      </ul>

                                      {/* <button
                                onClick={() => {
                                  handleAddNewClickDevice();
                                  if (inputRef5.current) {
                                    inputRef5.current.focus();
                                  }
                                }}
                                style={{ backgroundColor: "#9d3dd3" }}
                                type="button"
                                className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                              >
                                <span>+</span> Add New Brand
                              </button> 
                                    </div>
                                  )} */}
                                </div>
                              </div>
                            </div>
                          </div>
                          {error.model && (
                            <span
                              style={{
                                fontSize: "12px",
                                color: "red",
                                marginLeft: "120px",
                              }}
                            >
                              {error.model}
                            </span>
                          )}
                          <div className="form_column col_full">
                            <div className="form-group">
                              <label className="cus_label">
                                Description (optional)
                              </label>
                              <div className="formfield field_wd100">
                                <textarea
                                  className="form-control"
                                  name="description"
                                  placeholder="Enter Description"
                                  value={description1}
                                  onChange={handleDescriptionChange1}
                                ></textarea>
                              </div>{" "}
                              {errormsg && (
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "red",
                                    marginLeft: "110px",
                                  }}
                                >
                                  {errormsg}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="product_col100 cus_prod_col uploadimagedevicescustm">
                            <div className="uploadimgesrepairticketdevice">
                              <h4>Thumbnail</h4>
                              <p>Upload image of product</p>
                            </div>
                            <div className="form_row">
                              <div className="form_column col_full">
                                <div className="form-group">
                                  <div className="thumbnail_img">
                                    {images1 ? (
                                      <i
                                        className="fa-regular fa-circle-xmark deletimgcustm1"
                                        onClick={() => HandleDeleteOne()}
                                      ></i>
                                    ) : null}
                                    {images1 ? (
                                      <>
                                        <img
                                          src={images1}
                                          className="imgescstmdisply"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <input
                                          type="file"
                                          className="form-control"
                                          name="prod_thumnail"
                                          accept="image/jpeg, image/jpg, image/png"
                                          onChange={handleImages1}
                                        />
                                        <div className="file_upload">
                                          <img
                                            src="assets/img/thumbnail_img.webp"
                                            alt=""
                                          />
                                          <div className="file_txt">
                                            Drop your file here, or{" "}
                                            <span>click to browse</span>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>{" "}
                                  {imageMsg && (
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "red",
                                      }}
                                    >
                                      {imageMsg}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix"></div>

                    <div className="clearfix"></div>
                  </form>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary default_btn"
                  onClick={() => {
                    onpress();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary theme_btn"
                  onClick={() => OnSubmit()}
                >
                  Save Device
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default TicketModalList;
