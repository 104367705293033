// export const baseurl = "https://api.toolboxpos.com/";
export const baseurl = "https://api2.toolboxpos.com/";

export const baseurlImport = "https://api2.toolboxpos.com/";

export const loginApi = `${baseurl}login`;
export const checkSubdomainApi = `${baseurl}checkSubdomain`;
export const resendVerifyApi = `${baseurl}resendMailEmailVerify`;
export const updateVerifyEmailStatusApi = `${baseurl}updateVerifyEmailStatus`;
export const userRegisterApi = `${baseurl}userRegister`;
export const getComapnyCategoryApi = `${baseurl}getComapnyCategory`;
export const getProfileApi = `${baseurl}getProfile`;
export const checkSubDomainForLoginApi = `${baseurl}checkSubdomainForLogin`;
export const forgotPassApi = `${baseurl}forgotpassword`;
export const otpVerifyApi = `${baseurl}verifyotp`;
export const updateUserApi = `${baseurl}userUpdate`;
export const uploadImageApi = `${baseurl}uploadImage`;
export const customerAddApi = `${baseurl}addCustomer`;
export const customerGetApi = `${baseurl}getAllCustomer`;
export const addBrandApi = `${baseurl}addBrand`;
export const customerEditApi = `${baseurl}updateCustomerProfile/`;
export const AddBrandApi = `${baseurl}addBrand`;
export const GetAllBrandApi = `${baseurl}getAllBrand`;
export const AddSupplierApi = `${baseurl}addSuppiler`;
export const GetAllSupplierApi = `${baseurl}getAllSuppiler`;
export const AddProductApi = `${baseurl}addItems`;
export const AddItemApi = `${baseurl}addItemCategory`;
export const GetAllItemApi = `${baseurl}getAllItemCategory`;
export const AddTagsApi = `${baseurl}addTag`;
export const GetTagsApi = `${baseurl}getAllTag`;
export const GetAllProductApi = `${baseurl}getAllItems`;
export const GetAllStorageApi = `${baseurl}getAllStorage`;
export const AddStorageApi = `${baseurl}addStorage`;
export const GetAllColorApi = `${baseurl}getAllColor`;
export const AddColorApi = `${baseurl}addColor`;
export const GetAllConditionApi = `${baseurl}getAllCondition`;
export const AddConditionApi = `${baseurl}addCondition`;
export const UpdateSubscriptionApi = `${baseurl}updateSubscriptionStatus`;
export const AddCarrierApi = `${baseurl}addCarrier`;
export const AddShipToApi = `${baseurl}addShipTo`;
export const GetAllShipApi = `${baseurl}getAllShipTo`;
export const AddPurchaseOrderApi = `${baseurl}addPurchaseOrder`;
export const GetAllPurchaseOrderApi = `${baseurl}getPurchaseOrder`;
export const AddManufactureApi = `${baseurl}addManufacturer`;
export const GetManufactureApi = `${baseurl}getAllManufacturer`;
export const AddInvoiceApi = `${baseurl}addInvoice`;
export const GetInvoices = `${baseurl}getAllInvoice`;
export const AddUserRoleApi = `${baseurl}addUserRole`;
export const GetAllUserRoleApi = `${baseurl}getAllUserRole`;
export const AddSubUserApi = `${baseurl}addSubUser`;
export const GetAllSubUserApi = `${baseurl}getAllSubUser`;
export const GetAllRolePermissionApi = `${baseurl}getAllRolePermissionPage`;
export const GetSubUserProfileApi = `${baseurl}getProfileSubUser`;
export const CheckOutApi = `${baseurl}checkout`;
export const GetAllSubscriptionApi = `${baseurl}getAllSubscription`;
export const AddServiceApi = `${baseurl}addService`;
export const GetAllServiceApi = `${baseurl}getAllService`;
export const AddCustomerDeviceApi = `${baseurl}addCustomerDevice`;
export const GetCustomerDeviceApi = `${baseurl}getAllCustomerDevice`;
export const AddTicketApi = `${baseurl}addTicket`;
export const GetAllTicketApi = `${baseurl}getAllTicket`;
export const ContactUsApi = `${baseurl}admin/createContactUs`;
export const GetStatusApi = `${baseurl}getAllStatus`;
export const AddRecieptApi = `${baseurl}addReceiptConfig`;
export const GetRecieptApi = `${baseurl}getReceiptConfig`;
export const SendEmailPurchaseApi = `${baseurl}sendMailPurchaseOrder`;
export const AddCashLayoutApi = `${baseurl}addCashRegisterLayout`;
export const AddStatusApi = `${baseurl}addStatus`;
export const GetSalesApi = `${baseurl}salesDashBoard`;
export const GetTermsConditionPurchaseorderApi = `${baseurl}getTermAndConditionPurchaseOrder`;
export const AddPaymentOptionApi = `${baseurl}addPaymentOption`;
export const GettAllPaymentApi = `${baseurl}getAllPaymentOption`;
export const AddDiscountApi = `${baseurl}addDiscount`;
export const GetAllDiscountApi = `${baseurl}getAllDiscount`;
export const UpdatePasswordApi = `${baseurl}updatepasswordInapp`;
export const GetAllTaxApi = `${baseurl}getAllTax`;
export const AddTaxApi = `${baseurl}addTax`;
export const GetProductDeviceApi = `${baseurl}getAllItemsWithImei`;
export const ForgotPasswordSubApi = `${baseurl}forgotPasswordSubUser`;
export const ResetPasswordApi = `${baseurl}updtepassword`;
export const CheckItemApi = `${baseurl}checkItem`;
export const HomeDashboardApi = `${baseurl}home`;
export const VerifyLinkApi = `${baseurl}verifyPasseordLink`;
export const DashboardInvoiceApi = `${baseurl}getAllInvoiceDashboard`;
export const DashboardTicketApi = `${baseurl}getTicketDashboard`;
export const TicketStatusApi = `${baseurl}getInvoiceStatusCount`;
export const PlansAndBillingApi = `${baseurl}PlansPageData`;
export const AddLabelRecieptApi = `${baseurl}addLabelConfig`;
export const GetLabelRecieptApi = `${baseurl}getLabelConfig`;
export const UpdateLayoutApi = `${baseurl}updateLayoutButton`;
export const ImportApi = `${baseurl}importCustomerCsvFile`;
export const SalesReportDataApi = `${baseurl}salesReportData`;
export const DeleteSelectedCustomerApi = `${baseurl}deleteCustomerProfiles`;
export const SalesReportPersonApi = `${baseurl}salesReportDataBySalesperson`;
export const SalesReportPersonByDateApi = `${baseurl}salesReportDataBySalespersonByDate`;
export const SalesReportdataByDateApi = `${baseurl}salesReportDataByDate`;
export const TimeZoneAddApi = `${baseurl}addTimeZone`;
export const TimeZoneUpdateApi = `${baseurl}updateTimezone`;
export const TimeZoneGetApi = `${baseurl}getTimeZone`;
export const AddClockInApi = `${baseurl}start_ShiftTimer`;
export const ClockOutApi = `${baseurl}stop_ShiftTimer`;
export const GetClockInOutApi = `${baseurl}get_ShiftTimer`;
export const BreakStartApi = `${baseurl}start_BreakTimer`;
export const BreakEndApi = `${baseurl}stop_BreakTimer`;
export const GetBreakTimeApi = `${baseurl}get_BreakTimer`;
export const ImportProductApi = `${baseurlImport}ImportProductCsvFile`;
export const ImportDeviceApi = `${baseurlImport}ImportDeviceCsvFile`;
export const ImportTicketApi = `${baseurlImport}ImportRepairCsvFile`;
export const AddServiceReceiptApi = `${baseurl}addServiceReceiptConfig`;
export const GetServiceReceiptApi = `${baseurl}getServiceReceiptConfig`;
export const AddTicketInvoiceApi = `${baseurl}addServiceInvoice`;
export const OpenCloseAddApi = `${baseurl}cash_movements`;
export const OpenCloseGetApi = `${baseurl}getCompanyCashDetail`;
export const CashDrawerDetailsGetApi = `${baseurl}getCashDrawerCounter`;
export const LastCashDetailsGetApi = `${baseurl}getLastRegisterClosureSales`;
export const ProductBarcodeDetailsGetApi = `${baseurl}getBarCodeDetail`;
export const GetCashByDateApi = `${baseurl}getCashMovementByDate`;
export const GetMessagesApi = `${baseurl}getMessage`;
export const GetAllSubdomainApi = `${baseurl}getLocationByEmail`;
export const GetAllMultiStoreReportApi = `${baseurl}getAllMultiStoreReportByDate`;
