import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  AddCashLayoutApi,
  baseurl,
  GetAllItemApi,
  GetAllProductApi,
  getProfileApi,
  UpdateLayoutApi,
} from "../Utility/Api";
import { CirclePicker } from "react-color";
import { HexColorPicker } from "react-colorful";
import Swal from "sweetalert2";
import { hsvaToHex, getContrastingColor } from "@uiw/color-convert";
import Swatch from "@uiw/react-color-swatch";
import Draggable from "react-draggable";

const Layouts = () => {
  const [layoutState, setLayoutState] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [searchTermProduct, setSearchTermProduct] = useState("");
  const [productState, setProductState] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [displayProduct, setDisplayProduct] = useState(false);
  const [product, setProduct] = useState([]);
  const [selectedProduct, setselectedProduct] = useState();
  const [layoutName, setLayoutName] = useState("");
  const [allLayouts, setAllLayouts] = useState([]);
  const [productColors, setProductColors] = useState({});
  const [productName, setProductName] = useState("");
  const [editState, setEditState] = useState(false);
  const [id, setId] = useState("");
  const [Allcategory, setAllcategory] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [toggleValue, setToggleValue] = useState();
  const [category, setCategory] = useState();
  const [error, setError] = useState({});
  const [isCheckedImgbtn, setIsCheckedImgbtn] = useState(true);
  const [ImageOnOff, setImageOnOff] = useState(1);
  const [isCheckedImgbtnedit, setIsCheckedImgbtnedit] = useState();
  const [ImageOnOffedit, setImageOnOffedit] = useState();

  // Handle the toggle change
  const handleToggleImage = () => {
    setIsCheckedImgbtn((prevState) => {
      const newCheckedState = !prevState;
      setImageOnOff(newCheckedState ? 1 : 0); // Set ImageOnOff to 1 or 0 based on the checkbox state
      return newCheckedState;
    });
  };
  const handleToggleImageEdit = () => {
    setIsCheckedImgbtnedit((prevState) => {
      const newCheckedState = !prevState;
      setImageOnOffedit(newCheckedState ? 1 : 0); // Set ImageOnOff to 1 or 0 based on the checkbox state
      return newCheckedState;
    });
  };

  const Validation = () => {
    let error = {};
    if (!layoutName) {
      error.layoutName = "Layout Name is required.";
    }

    if (Object.keys(error).length === 0) {
      AddLayOut();
    }
    return error;
  };

  const Submit = () => {
    setError(Validation());
  };

  const handleChangeComplete = (color) => {
    setSelectedColor(color);
    if (editState === true) {
      handleProductSelect(selectedProduct, color);
    } else {
      handleProductSelectOne(selectedProduct, color);
    }
  };

  const handleToggleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };

  useEffect(() => {
    // handleToggle();
    ProductCategory();
    GetAllProducts();
    GetAllLayouts();
    GetAllItem();
  }, []);

  const GetAllItem = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllItemApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setAllItems(result?.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const GetAllProducts = () => {
    //setLoading(true);
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllProductApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          // setAllProducts(result?.data);
          setAllProducts(
            result?.data.filter((item) => item.activeInactive === 1)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        //setLoading(false);
      });
  };
  // const combinedData = [...allProducts, ...allItems];
  // // Filter the combined array based on the search term
  // const filteredData1 = combinedData?.filter((item) => {
  //   const lowerCaseSearchTerm = searchTermProduct.toLowerCase();

  //   return (
  //     item?.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
  //     item?.title?.toLowerCase().includes(lowerCaseSearchTerm) ||
  //     item?.brand?.name?.toLowerCase()?.includes(lowerCaseSearchTerm) ||
  //     item?.skuCode?.toString().includes(lowerCaseSearchTerm)
  //   );
  // });

  const [filteredData1, setFilteredData1] = useState([]);

  const handleSearchTermChangeProduct = (event) => {
    setSearchTermProduct(event.target.value);

    // Filter the combined data based on the search term
    const combinedData = [...allProducts, ...allItems];
    const lowerCaseSearchTerm = event.target.value.toLowerCase();
    const filteredData = combinedData.filter(
      (item) =>
        item?.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
        item?.title?.toLowerCase().includes(lowerCaseSearchTerm) ||
        item?.brand?.name?.toLowerCase()?.includes(lowerCaseSearchTerm) ||
        item?.skuCode?.toString().includes(lowerCaseSearchTerm)
    );
    setFilteredData1(filteredData);
    setShowModal2(true);
    setProductState(true);
  };

  const handleSaveProduct = () => {
    // const index = selectedProducts.findIndex(
    //   (p) => p.id === selectedProduct.id
    // );

    // if (index !== -1) {
    //   const updatedProducts = [...selectedProducts];
    //   updatedProducts[index] = selectedProduct;

    //   setSelectedProducts(updatedProducts);
    // }

    setShowModal(false);
  };
  // const handleSearchTermChangeProduct = (event) => {
  //   const term = event.target.value;
  //   setSearchTermProduct(term);
  //   setShowModal2(true);
  //   setProductState(true);
  // };

  const GetAllLayouts = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllCashRegisterLayout?type=0`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("getcashlayout", result);
        setAllLayouts(result?.data);
        setToggleValue(result?.data[0]?.layoutButton === 1);
        setIsChecked(result?.data[0]?.layoutButton === 1);
        setImageOnOffedit(result?.data[0]?.imageButton);
        setIsCheckedImgbtnedit(
          result?.data[0]?.imageButton === 1 ? true : false
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleToggle = async () => {
    const newLayoutButtonValue = isChecked ? 0 : 1; // Toggle the layoutButton value

    setIsChecked(!isChecked); // Update the isChecked state to toggle the switch

    // Call API to update layout button status
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(UpdateLayoutApi, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          layoutButton: newLayoutButtonValue,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update layout button");
      }
    } catch (error) {
      console.error("Error updating layout button:", error);
    }
  };

  const LayoutButton = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      layoutButton: 1,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(UpdateLayoutApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setIsChecked(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const AddLayOut = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const layoutItems = selectedProducts.map((product) => {
      return {
        itemId: product.id,
        name: product?.title || product?.name,
        colorCode: product?.color,
        skuCode: product?.skuCode,
        isCategory: product?.title ? 0 : 1,
      };
    });

    const raw = JSON.stringify({
      name: layoutName,
      layout: layoutItems,
      defaultLayout: 0,
      imageButton: ImageOnOff,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("addcashregister", raw);

    fetch(AddCashLayoutApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("addcashregisterresult", result);
        if (result?.status === true) {
          setLayoutState(false);
          GetAllLayouts();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleProductSelect = (item, color) => {
    const newProduct = {
      ...item,
      color: color || "#6b7280",
      name: item?.name || item?.title || productName,
      isCategory: item?.isCategory,
      itemId: item?.itemId || item?.id,
    };
    const index = selectedProducts.findIndex((p) => p.itemId === item.itemId);

    if (index === -1) {
      setSelectedProducts([newProduct, ...selectedProducts]);
    } else {
      setSelectedProducts([
        ...selectedProducts.slice(0, index),
        newProduct,
        ...selectedProducts.slice(index + 1),
      ]);
    }

    if (selectedProduct && selectedProduct.id === item.itemId) {
      setselectedProduct(newProduct);
    }

    setSelectedColor(color || "#6b7280");
    setCategory(item?.title ? 0 : 1);
  };

  const handleProductSelectOne = (item, color) => {
    const newProduct = {
      ...item,
      color: color || "#6b7280",
      name: item?.name || item?.title || productName,
      isCategory: item?.title ? 0 : 1,
    };
    const index = selectedProducts.findIndex((p) => p.id === item.id);

    if (index === -1) {
      setSelectedProducts([newProduct, ...selectedProducts]);
    } else {
      setSelectedProducts([
        ...selectedProducts.slice(0, index),
        newProduct,
        ...selectedProducts.slice(index + 1),
      ]);
    }
    setCategory(item?.title ? 0 : 1);

    if (selectedProduct && selectedProduct.id === item.id) {
      setselectedProduct(newProduct);
    }

    setSelectedColor(color || "#6b7280");
  };

  const DeleteLayout = (id) => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}deleteCashRegister/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        GetAllLayouts();
        // Swal.fire(result?.message);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const HandleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete layout",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          DeleteLayout(id);
        });
      }
    });
  };

  const UpdateLayout = (a) => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const layoutItems = a?.layout.map((product) => {
      return {
        itemId: product?.itemId,
        name: product?.name || product?.title,
        colorCode: product?.colorCode,
        skuCode: product?.skuCode,
        isCategory: product?.isCategory,
      };
    });
    const raw = JSON.stringify({
      name: a?.name,
      layout: layoutItems,
      defaultLayout: 1,
      imageButton: a?.ImageOnOff,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("updatecashregister", raw);
    fetch(`${baseurl}updateCashRegisterLayout/${a?.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllLayouts();
          // setIsChecked(false);
          //handleToggle();
          LayoutButton();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdateEditLayout = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const layoutItems = selectedProducts.map((product) => {
      return {
        itemId: product.itemId || product.id,
        name: product?.name || product?.title,
        colorCode: product.color || selectedColor.itemId || product.colorCode,
        skuCode: product?.skuCode,
        isCategory: product?.isCategory,
      };
    });
    const raw = JSON.stringify({
      name: layoutName,
      layout: layoutItems,
      defaultLayout: default2,
      imageButton: ImageOnOffedit,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("updatecashregister", raw);

    fetch(`${baseurl}updateCashRegisterLayout/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          // console.log("updatecashregisterresult", result);
          GetAllLayouts();
          setLayoutState(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [default2, setDefault] = useState("");

  const handleEditLayout = (layout) => {
    setEditState(true);
    setLayoutState(true);
    setDisplayProduct(true);
    setLayoutName(layout?.name);
    setSelectedProducts(layout?.layout);
    setSearchTermProduct("");
    setDefault(layout?.defaultLayout);
    setId(layout?.id);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const dropdown = document.querySelector(".seacrhproductlistcustm");

      if (dropdown && !dropdown.contains(event.target)) {
        setShowModal2(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [setShowModal2]);

  const ProductCategory = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllItemApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllcategory(result?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const handleProductDelete = () => {
  //   const updatedProducts = selectedProducts.filter(
  //     (product) => product.id !== selectedProduct.id
  //   );
  //   setSelectedProducts(updatedProducts);
  //   // Close the modal after deletion
  //   setShowModal(false);
  // };

  const handleProductDelete = () => {
    if (editState) {
      // Editing mode: Remove the prepopulated product
      const updatedProducts = selectedProducts.filter(
        (product) => product.itemId !== selectedProduct.itemId
      );
      setSelectedProducts(updatedProducts);
    } else {
      // Non-editing mode: Remove the selected product
      const updatedProducts = selectedProducts.filter(
        (product) => product.id !== selectedProduct.id
      );
      setSelectedProducts(updatedProducts);
    }
    setShowModal(false); // Close the modal after deletion
  };

  return (
    <>
      <div className="setting_content">
        <h4>Cash Register Layout</h4>
        <div className="inner_block plan_billing_setting">
          <form className="setting_form" action="" method="post">
            {layoutState === false ? (
              <>
                <div className="block_column wd100 add_layout_box">
                  <h5>Cash Register Layout</h5>
                  <p>Add or customize layouts for your cash register</p>
                  <div className="inner_field">
                    <div className="tax_label_block">
                      <div className="tax_label">
                        <h6>Enable Layout Buttons</h6>
                        <span>
                          These are the buttons under the search bar shown in in
                          cash register. Allowing for quicker selections of
                          products
                        </span>
                      </div>
                      <div className="layout_switch_field switch_field">
                        <label className="custom-switch">
                          <input
                            type="checkbox"
                            name="custom-switch-checkbox"
                            className="custom-switch-input"
                            checked={isChecked}
                            onChange={handleToggle}
                          />
                          <span className="custom-switch-indicator"></span>
                        </label>
                      </div>
                    </div>
                    <div className="tax_label_block">
                      <div className="tax_label">
                        <h6>Layouts</h6>
                        <span>These are your current Layouts</span>
                      </div>
                      <div className="edit_tax_btn">
                        <button
                          type="button"
                          className="cus_btn"
                          onClick={() => {
                            setLayoutState(true);

                            setLayoutName("");
                            setSelectedProducts([]);
                          }}
                        >
                          Add Layout
                        </button>
                      </div>
                    </div>
                    <div className="layout_setting">
                      {allLayouts?.map((item) => (
                        <>
                          <div className="layout_column">
                            <div className="layout_info">
                              <span>{item.name}</span>
                              {item?.defaultLayout === 1 ? (
                                <button type="button" className="current">
                                  Current
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="set_dafault"
                                  onClick={() => {
                                    UpdateLayout(item);
                                  }}
                                >
                                  Set as current
                                </button>
                              )}
                            </div>
                            <div className="layout_action">
                              <a
                                href="#"
                                className="edit_layout"
                                onClick={() => {
                                  handleEditLayout(item);

                                  setSelectedColor("");
                                }}
                              >
                                <img
                                  src="assets/img/layout-edit-icon.webp"
                                  alt=""
                                />
                              </a>
                              <a
                                href="#"
                                className="delete_layout"
                                onClick={() => HandleDelete(item.id)}
                              >
                                <img src="assets/img/delete-icon.webp" alt="" />
                              </a>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            ) : editState === true ? (
              <>
                <div className="add_layout">
                  <div className="block_column wd100">
                    <a
                      onClick={() => {
                        setLayoutState(false);
                        setEditState(false);
                      }}
                      className="back_layout"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src="assets/img/back-arrow.webp"
                        alt=""
                        className="settingusersubimgcstm"
                      />
                    </a>
                    <h5>Edit Layout</h5>
                    <p>Reposition catergory</p>
                    <div className="inner_field">
                      <div className="tax_label_block">
                        <div className="tax_label">
                          <h6>Select Register</h6>
                          <span>Please assign this layout to a register</span>
                        </div>
                        <div className="tax_field">
                          <select
                            className="form-control"
                            name="payment_option"
                          >
                            {" "}
                            {allLayouts?.map((item, index) => (
                              <option
                                value={item.name}
                                onClick={() => {
                                  setSelectedProducts(item);

                                  setDisplayProduct(true);
                                }}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>

                          <img src="assets/img/select-arrow.webp" alt="" />
                        </div>
                      </div>
                      <div className="tax_label_block">
                        <div className="tax_label">
                          <h6>Enable Image Button</h6>
                          <span>
                            This button allows you to toggle the visibility of
                            images for selected products in the cash register.
                            When enabled, images of the selected products will
                            be displayed. When disabled, the images will not be
                            shown.
                          </span>
                        </div>
                        <div className="layout_switch_field switch_field">
                          <label className="custom-switch">
                            <input
                              type="checkbox"
                              name="custom-switch-checkbox"
                              className="custom-switch-input"
                              checked={isCheckedImgbtnedit}
                              onChange={handleToggleImageEdit}
                            />
                            <span className="custom-switch-indicator"></span>
                          </label>
                        </div>
                      </div>
                      <div className="tax_label_block">
                        <div className="tax_label">
                          <h6>Add Products</h6>
                          <span>
                            Add products or categories to your layout, click to
                            customize, label and colour
                          </span>
                        </div>
                      </div>
                      <div className="layout_search">
                        <div className="layout_field">
                          <div className="layout_name">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Layout Name"
                              name="layoutName"
                              value={layoutName}
                              onChange={(e) => setLayoutName(e.target.value)}
                            />
                          </div>
                          <div className="layout_srch">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search Product"
                              value={searchTermProduct}
                              onChange={handleSearchTermChangeProduct}
                              onClick={() => {
                                setSearchTermProduct("");
                              }}
                            />
                            <img src="assets/img/search-icon.webp" alt="" />

                            {showModal2 === true && productState === true ? (
                              <>
                                <div
                                  className={`searchcustomer_list seacrhproductlistcustm ${
                                    showModal2 ? "active" : ""
                                  }`}
                                >
                                  <div className="srchcustmer_title common_title">
                                    <h4>Select Product </h4>
                                  </div>

                                  <div className="customer_list">
                                    <ul>
                                      {filteredData1.length ? (
                                        <>
                                          {filteredData1?.map((item) => (
                                            <li
                                              key={item.id}
                                              onClick={() => {
                                                setShowModal2(false);
                                                handleProductSelect(item);
                                                setProductState(false);
                                                setDisplayProduct(true);
                                              }}
                                            >
                                              <div
                                                key={item?.id}
                                                className="custmer_img"
                                              >
                                                {item?.image ? (
                                                  <img
                                                    src={item?.image}
                                                    alt=""
                                                    className="imageslayoutrespcustm"
                                                  />
                                                ) : (
                                                  <img
                                                    src="assets/img/customer-info-img.webp"
                                                    alt=""
                                                    className="imageslayoutrespcustm"
                                                  />
                                                )}
                                              </div>
                                              <span>
                                                {item?.title || item?.name}
                                                <small>{item?.skuCode}</small>
                                              </span>
                                            </li>
                                          ))}
                                        </>
                                      ) : (
                                        <div className="text-center">
                                          <h5>No data available</h5>
                                        </div>
                                      )}
                                    </ul>
                                  </div>
                                </div>{" "}
                              </>
                            ) : null}
                          </div>
                        </div>
                        <div
                          className="layout_product_list"
                          style={{
                            maxHeight:
                              selectedProducts.length > 12 ? "400px" : "auto",
                            overflowY:
                              selectedProducts.length > 12 ? "auto" : "visible",
                          }}
                        >
                          {selectedProducts.map((product, index) => (
                            <div
                              className="layoout_prod_col"
                              onClick={() => {
                                setShowModal(true);
                                setselectedProduct(product);
                              }}
                            >
                              <Draggable
                                key={product.id}
                                axis="both"
                                defaultPosition={{ x: 0, y: 0 }}
                                position={null}
                                grid={[25, 25]}
                              >
                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#layoutModal"
                                >
                                  <div>
                                    {/* <div className="selectcustmproduct"> */}
                                    <div
                                      className="classcolorstyle"
                                      style={{
                                        background: product.color
                                          ? product?.color
                                          : product?.colorCode,
                                      }}
                                    >
                                      {" "}
                                    </div>

                                    <span>
                                      {product?.name ||
                                        product?.title ||
                                        productName}
                                    </span>
                                  </div>
                                </a>
                              </Draggable>
                              {/* <a
                                href="#"
                                data-toggle="modal"
                                data-target="#layoutModal"
                              >
                                <div className="selectcustmproduct">
                                  <div
                                    className="classcolorstyle"
                                    style={{
                                      background: product.color
                                        ? product?.color
                                        : product?.colorCode,
                                    }}
                                  >
                                    <p className="playouytcustmtextname">
                                      {product?.name ||
                                        product?.title ||
                                        productName}
                                    </p>
                                  </div>
                                </div>
                              </a> */}
                            </div>
                          ))}
                          {Array.from({
                            length: Math.max(12 - selectedProducts.length, 0),
                          }).map((_, index) => (
                            <div className="layoout_prod_col">
                              <Draggable
                                key={product.id}
                                axis="both"
                                defaultPosition={{ x: 0, y: 0 }}
                                position={null}
                                grid={[25, 25]}
                              >
                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#layoutModal"
                                ></a>
                              </Draggable>
                            </div>
                          ))}
                        </div>

                        {/* <div className="layout_product_list">
                          {selectedProducts.map((product, index) => (
                            <div
                              className="layoout_prod_col"
                              onClick={() => {
                                setShowModal(true);
                                setselectedProduct(product);
                                console.log("selects edit product", product);
                              }}
                            >
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#layoutModal"
                              >
                                {" "}
                                <div className="selectcustmproduct">
                                  <div
                                    className="classcolorstyle"
                                    style={{
                                      background: product.color
                                        ? product.color
                                        : product.colorCode,
                                    }}
                                  >
                                    <p className="playouytcustmtextname">
                                      {product?.name ||
                                        productName ||
                                        product?.title}
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          ))}
                          {Array(12 - selectedProducts.length)
                            .fill()
                            .map((_, index) => (
                              <div className="layoout_prod_col">
                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#layoutModal"
                                ></a>
                              </div>
                            ))}
                        </div> */}

                        <div className="clearfix"></div>
                        <div className="layout_prod_btn">
                          <button
                            type="button"
                            className="prod_save_btn"
                            onClick={UpdateEditLayout}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="add_layout">
                  <div className="block_column wd100">
                    <a
                      onClick={() => setLayoutState(false)}
                      className="back_layout"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src="assets/img/back-arrow.webp"
                        alt=""
                        className="settingusersubimgcstm"
                      />
                    </a>
                    <h5>Add Layout</h5>
                    <p>Reposition catergory</p>
                    <div className="inner_field">
                      <div className="tax_label_block">
                        <div className="tax_label">
                          <h6>Select Register</h6>
                          <span>Please assign this layout to a register</span>
                        </div>
                        <div className="tax_field">
                          <select
                            className="form-control"
                            name="payment_option"
                          >
                            {" "}
                            {allLayouts?.map((item, index) => (
                              <option
                                value={item.name}
                                onClick={() => {
                                  setSelectedProducts(item);
                                  setDisplayProduct(true);
                                }}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>

                          <img src="assets/img/select-arrow.webp" alt="" />
                        </div>
                      </div>
                      <div className="tax_label_block">
                        <div className="tax_label">
                          <h6>Enable Image Button</h6>
                          <span>
                            This button allows you to toggle the visibility of
                            images for selected products in the cash register.
                            When enabled, images of the selected products will
                            be displayed. When disabled, the images will not be
                            shown.
                          </span>
                        </div>
                        <div className="layout_switch_field switch_field">
                          <label className="custom-switch">
                            <input
                              type="checkbox"
                              name="custom-switch-checkbox"
                              className="custom-switch-input"
                              checked={isCheckedImgbtn}
                              onChange={handleToggleImage}
                            />
                            <span className="custom-switch-indicator"></span>
                          </label>
                        </div>
                      </div>
                      <div className="tax_label_block">
                        <div className="tax_label">
                          <h6>Add Products</h6>
                          <span>
                            Add products or categories to your layout, click to
                            customize, label and colour
                          </span>
                        </div>
                      </div>
                      <div className="layout_search">
                        <div className="layout_field">
                          <div className="layout_name">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Layout Name"
                              name="layoutName"
                              value={layoutName}
                              onChange={(e) => {
                                setLayoutName(e.target.value);
                                if (error.layoutName) {
                                  setError({ ...error, layoutName: "" });
                                }
                              }}
                            />
                            {error.layoutName && (
                              <span className="customvalidationdesignlogin">
                                {error.layoutName}
                              </span>
                            )}
                          </div>
                          <div className="layout_srch">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search Product"
                              value={searchTermProduct}
                              onChange={handleSearchTermChangeProduct}
                              onClick={() => {
                                setSearchTermProduct("");
                              }}
                            />

                            <img src="assets/img/search-icon.webp" alt="" />

                            {showModal2 === true && productState === true ? (
                              <>
                                <div
                                  className={`searchcustomer_list seacrhproductlistcustm ${
                                    showModal2 ? "active" : ""
                                  }`}
                                >
                                  <div className="srchcustmer_title common_title">
                                    <h4 className="selectcustmproductcash">
                                      Select Product{" "}
                                    </h4>
                                  </div>

                                  <div className="customer_list">
                                    <ul>
                                      {filteredData1.length ? (
                                        <>
                                          {filteredData1?.map((item) => (
                                            <li
                                              key={item.id}
                                              onClick={() => {
                                                setShowModal2(false);

                                                handleProductSelectOne(item);

                                                setProductState(false);
                                                setDisplayProduct(true);
                                                //setProduct(item);
                                              }}
                                            >
                                              <div
                                                key={item?.id}
                                                // className="custmer_img"
                                              >
                                                {item?.image ? (
                                                  <img
                                                    src={item?.image}
                                                    alt=""
                                                    className="imageslayoutrespcustm"
                                                  />
                                                ) : (
                                                  <img
                                                    src="assets/img/customer-info-img.webp"
                                                    alt=""
                                                    className="imageslayoutrespcustm"
                                                  />
                                                )}
                                              </div>
                                              <span>
                                                {item?.name ||
                                                  item?.title ||
                                                  productName}
                                                <small>{item?.skuCode}</small>
                                              </span>
                                            </li>
                                          ))}
                                        </>
                                      ) : (
                                        <div className="text-center">
                                          <h5>No data available</h5>
                                        </div>
                                      )}
                                    </ul>
                                  </div>
                                </div>{" "}
                              </>
                            ) : null}
                          </div>
                        </div>
                        <div
                          className="layout_product_list"
                          style={{
                            maxHeight:
                              selectedProducts.length > 12 ? "400px" : "auto",
                            overflowY:
                              selectedProducts.length > 12 ? "auto" : "visible",
                          }}
                        >
                          {selectedProducts.map((product, index) => (
                            <div
                              className="layoout_prod_col"
                              onClick={() => {
                                setShowModal(true);
                                setselectedProduct(product);
                              }}
                            >
                              <Draggable
                                key={product.id}
                                axis="both"
                                defaultPosition={{ x: 0, y: 0 }}
                                position={null}
                                grid={[25, 25]}
                              >
                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#layoutModal"
                                >
                                  <div>
                                    {/* <div className="selectcustmproduct"> */}
                                    <div
                                      className="classcolorstyle"
                                      style={{
                                        background: product?.color
                                          ? product?.color
                                          : "#6b7280",
                                      }}
                                    ></div>
                                    <span>
                                      {product?.name ||
                                        product?.title ||
                                        productName}
                                    </span>
                                  </div>
                                </a>
                              </Draggable>
                              {/* <a
                                href="#"
                                data-toggle="modal"
                                data-target="#layoutModal"
                              >
                                <div className="selectcustmproduct">
                                  <div
                                    className="classcolorstyle"
                                    style={{
                                      background: product?.color
                                        ? product?.color
                                        : "#6b7280",
                                    }}
                                  >
                                    <p className="playouytcustmtextname">
                                      {product?.name ||
                                        product?.title ||
                                        productName}
                                    </p>
                                  </div>
                                </div>
                              </a> */}
                            </div>
                          ))}
                          {Array.from({
                            length: Math.max(12 - selectedProducts.length, 0),
                          }).map((_, index) => (
                            <div className="layoout_prod_col">
                              <Draggable
                                key={product.id}
                                axis="both"
                                defaultPosition={{ x: 0, y: 0 }}
                                position={null}
                                grid={[25, 25]}
                              >
                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#layoutModal"
                                ></a>
                              </Draggable>
                            </div>
                          ))}
                        </div>
                        {/* <div className="layout_product_list">
                          {selectedProducts.map((product, index) => (
                            <div
                              className="layoout_prod_col"
                              onClick={() => {
                                setShowModal(true);
                                setselectedProduct(product);
                              }}
                            >
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#layoutModal"
                              >
                                {" "}
                                <div className="selectcustmproduct">
                                  <div
                                    className="classcolorstyle"
                                    style={{
                                      background: product.color
                                        ? product.color
                                        : "#6b7280",
                                    }}
                                  >
                                    <p className="playouytcustmtextname">
                                      {product?.name ||
                                        product?.title ||
                                        productName}
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          ))}
                          {Array(12 - selectedProducts.length)
                            .fill()
                            .map((_, index) => (
                              <div className="layoout_prod_col">
                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#layoutModal"
                                ></a>
                              </div>
                            ))}
                        </div> */}

                        <div className="clearfix"></div>
                        <div className="layout_prod_btn">
                          <button
                            type="button"
                            className="prod_save_btn"
                            onClick={Submit}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </form>
          <div className="clearfix"></div>
        </div>
      </div>
      {showModal === true ? (
        <>
          <div
            className="modal common_modal layout_modal"
            id="layoutModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="layoutModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="layoutModalLabel">
                    Customize Button
                  </h5>
                  <p>Add name for button and unique color.</p>
                </div>
                <div className="modal-body">
                  <div className="prod_layout_sec">
                    <form method="" action="">
                      <div className="prod_name">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={"Product Name"}
                          value={selectedProduct?.name || ""}
                          onChange={(e) =>
                            setselectedProduct({
                              ...selectedProduct,
                              name: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div
                        className="prod_colors"
                        style={{ display: " flex", marginLeft: " 110px" }}
                      >
                        <Swatch
                          colors={[
                            "#000",
                            "#E20000",
                            "#9664FF",
                            "#28DA2E",
                            "#FFC700",
                            "#FF00E5",
                            "#0500FF",
                            "#D0D0D0",
                            "#00D1FF",
                          ]}
                          color={selectedColor}
                          rectProps={{
                            //children: <Point />,
                            style: {
                              display: " block",
                              marginLeft: "10px",
                              alignItems: "center",
                              justifyContent: "center",
                              width: " 28px",
                              height: "23px",
                              borderRadius: "5px",
                            },
                          }}
                          onChange={(hsvColor) => {
                            handleChangeComplete(hsvaToHex(hsvColor));
                          }}
                        />
                        <div
                          className="disvcolorcustmslect"
                          onClick={handleToggleColorPicker}
                        >
                          <span>+</span>
                        </div>

                        {showColorPicker && (
                          // <HexColorPicker
                          //   color={selectedColor}
                          //   onChange={(color) => {
                          //     // Update the color property of selectedProduct
                          //     const updatedProduct = {
                          //       ...selectedProduct,
                          //       colorCode: color,
                          //     };
                          //     setselectedProduct(updatedProduct);
                          //     setSelectedColor(color);
                          //   }}
                          // />

                          <HexColorPicker
                            color={selectedColor}
                            onChange={(color) => {
                              setSelectedColor(color);
                              if (editState === true) {
                                handleProductSelect(selectedProduct, color);
                              } else {
                                handleProductSelectOne(selectedProduct, color);
                              }
                            }}
                          />
                        )}
                      </div>
                      <div className="prod_info">
                        <div className="prod_left ">
                          <div
                            className="classcolorstyle"
                            style={{
                              background:
                                selectedProduct?.color ||
                                selectedColor ||
                                "#6b7280",
                            }}
                          >
                            <h4 className="layouttextproductcstm">
                              {selectedProduct.title || selectedProduct.name}
                            </h4>
                          </div>
                        </div>
                        <div className="prod_right">
                          <h4>
                            {selectedProduct?.title || selectedProduct?.name}
                          </h4>
                          <span>{selectedProduct?.skuCode}</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn deletecsscustm"
                    //data-dismiss="modal"
                    onClick={() => {
                      setShowModal(false);
                      setSelectedColor("");
                      handleProductDelete();
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    //data-dismiss="modal"
                    onClick={() => {
                      setShowModal(false);
                      setSelectedColor("");

                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={() => {
                      handleSaveProduct();
                      setShowModal(false);
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default Layouts;
