import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import Footer from "../Footer";
import MainSidebar from "./MainSidebar";
import SideUl from "./SideUl";
import FreeTrail from "./FreeTrail";
import {
  baseurl,
  CashDrawerDetailsGetApi,
  GetMessagesApi,
  getProfileApi,
  GetSalesApi,
  GetSubUserProfileApi,
  OpenCloseAddApi,
  OpenCloseGetApi,
} from "../Utility/Api";
import Swal from "sweetalert2";
import OpenCloseCash from "./OpenCloseCash";
import { Link } from "react-router-dom";
import moment from "moment";
import BannerMessage from "./BannerMessage";

const OpenCloseCashRegister = () => {
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [error, setError] = useState("");
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);
  const [cashDrawerId, setCashDrawerId] = useState("");
  const [userName, setUserName] = useState();
  const [UserEmail, setUserEmail] = useState();
  const [userCompanyName, setUserCompanyName] = useState();
  const [RegisterData, setRegisterData] = useState([]);
  const [cashDetails, setCasgDetails] = useState([]);
  const [registerId, setRegisterId] = useState(null);
  const [openRegisterDetails, setOpenRegisterDetails] = useState(null);
  const [countedAmount, setCountedAmount] = useState(); // State for counted amount
  const [difference, setDifference] = useState(0);
  const [isFolded, setIsFolded] = useState(false);
  const [paymentarr, setPaymentArr] = useState([]);
  const [todaySales, setTodaySales] = useState("");
  const [data, setData] = useState([]);
  const [messageData, setMessageData] = useState();

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  };

  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);

  useEffect(() => {
    GetMessages();
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));
    if (localAdmin === "0" && localPerminssion.length) {
      GetSubUserProfile();
    }
    GetProfile();

    GetCashDetails();
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
    TodaySaleApi();
  }, []);

  const handleOpenRegister = () => {
    if (countedAmount === "") {
      setError("Please Enter The Amount.");
      return;
    }

    // Call API if validation passes
    AddOpenClose();
  };

  const AddOpenClose = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const formatDate = (date) => {
      const dateOptions = {
        month: "short",
        day: "numeric",
        year: "numeric",
      };

      const timeOptions = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };

      // Get the date and time separately
      const formattedDate = date.toLocaleDateString("en-US", dateOptions);
      const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

      // Combine the date and time with the " @ " separator
      return `${formattedDate} @ ${formattedTime}`;
    };

    // Example usage:
    const currentDateFormatted = formatDate(new Date());
    console.log(currentDateFormatted);

    const raw = JSON.stringify({
      name: userName,
      email: UserEmail,
      reason: "",
      amount: expectedAmount.toFixed(2),
      note: note,
      date: currentDateFormatted,
      isclosed: 1,
      drawerCash: countedAmount,
      registerId: registerId,
      changeGiven: difference,
      type: "Closing float",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("openclose", raw);
    fetch(OpenCloseAddApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("add openclose", result);
        if (result?.status === true) {
          setAmount("");
          setNote("");
          Swal.fire(result?.message);
          GetCashDetails();
        } else {
          setAmount("");
          setNote("");
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetProfile = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("gfdgh", result);
        if (result.status === true) {
          //setData1(result?.data);
          setUserCompanyName(result?.data?.companyDetails?.companyName);
          setUserEmail(result?.data?.userDetails?.email);
          setUserName(result?.data?.userDetails?.userName);
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetSubUserProfile = () => {
    let local = localStorage.getItem("token");
    //console.log(local);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetSubUserProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("subuser", result);
        if (result.status === true) {
          setUserCompanyName(result?.data?.companyDetails?.companyName);
          setUserEmail(result?.data?.userDetails?.email);
          setUserName(result?.data?.userDetails?.name);
          // setDataUser(result?.data);
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetCashDetails = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(OpenCloseGetApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("OpenClose Register details", result);
        if (result?.status === true) {
          setRegisterData(result?.data);
          setLoading(false);
          setCasgDetails(result?.data);
          const openRegister = result?.data?.find(
            (item) => item?.cashManagementDetail?.isclosed === 0
          );

          if (openRegister) {
            // Set the register ID of the open register
            setRegisterId(openRegister?.cashManagementDetail?.id);
            console.log("regidterre", openRegister?.cashManagementDetail?.id);
            setOpenRegisterDetails(openRegister);
          } else {
            // If no open register is found, set it to null (or handle it as needed)
            setRegisterId(null);
            setOpenRegisterDetails(null);
          }
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
    //     if (result.data) {
    //       setLoading(false);
    //       setRegisterData(result?.data);
    //     } else {
    //       setLoading(false);
    //     }
    //   })
    //   .catch((error) => console.error(error));
  };

  const getLatestRegister = useMemo(() => {
    if (!RegisterData || RegisterData.length === 0) return null;

    // Sort by createdAt to find the latest entry
    return RegisterData.sort(
      (a, b) =>
        new Date(b.cashManagementDetail.createdAt) -
        new Date(a.cashManagementDetail.createdAt)
    )[0];
  }, [RegisterData]);

  // Use getLatestRegister directly (do not call it as a function)
  const latestRegister = getLatestRegister; // This is already memoized

  // Check if the latest register entry is closed (isclosed === 1)
  const hasClosedRegister = useMemo(() => {
    return latestRegister?.cashManagementDetail.isclosed === 1;
  }, [latestRegister]);

  const handleCountedChange = (e) => {
    let inputValue = e.target.value;

    // Allow only numbers and a single dot for decimal values
    if (!/^\d*\.?\d*$/.test(inputValue)) return;

    // Convert to a number or default to 0
    const countedValue = parseFloat(inputValue) || 0;

    // Update state
    setCountedAmount(inputValue); // Store as string for better handling
    // const countedValue = parseFloat(e.target.value) || 0;
    // setCountedAmount(countedValue);

    // Use the calculated expectedAmount based on cash movements
    // const expectedAmount = openRegisterDetails?.companyCashDetail?.reduce(
    //   (total, movement) => {
    //     const amount = movement.totalAmount;
    //     if (
    //       movement.type === "Cash Out" ||
    //       movement.type === "Petty Cash Out"
    //     ) {
    //       return total - amount; // Subtract for outflows
    //     } else {
    //       return total + amount; // Add for inflows
    //     }
    //   },
    //   0
    // );

    const totalCashMovement = openRegisterDetails?.companyCashDetail?.reduce(
      (total, movement) => {
        const amount = movement.totalAmount;
        if (
          movement.type === "Cash Out" ||
          movement.type === "Petty Cash Out"
        ) {
          return total - amount; // Subtract for outflows
        } else {
          return total + amount; // Add for inflows
        }
      },
      0
    );
    const expectedAmount = totalCashMovement + totalCashPayments;

    // const expectedAmount = totalCashMovement + Number(todaySales);

    // Calculate the difference based on the new expected amount
    setDifference((countedValue - expectedAmount).toFixed(2));
  };

  // const expectedAmount = openRegisterDetails?.companyCashDetail?.reduce(
  //   (total, movement) => {
  //     const amount = movement.totalAmount;
  //     if (movement.type === "Cash Out" || movement.type === "Petty Cash Out") {
  //       return total - amount; // Subtract for outflows
  //     } else {
  //       return total + amount + Number(todaySales); // Add for inflows
  //     }
  //   },
  //   0
  // );

  const totalCashMovement = openRegisterDetails?.companyCashDetail?.reduce(
    (total, movement) => {
      const amount = movement.totalAmount;
      if (movement.type === "Cash Out" || movement.type === "Petty Cash Out") {
        return total - amount; // Subtract for outflows
      } else {
        return total + amount; // Add for inflows
      }
    },
    0
  );

  const totalCashPayments = paymentarr
    ?.filter((payment) => payment?.paymentType === "Cash") // Filter only cash payments
    ?.reduce((sum, payment) => sum + Number(payment?.totalGrandTotal || 0), 0); // Sum up the totalGrandTotal values

  // const expectedAmount = totalCashMovement + Number(todaySales);
  const expectedAmount = totalCashMovement + totalCashPayments;

  const toggleFold = () => {
    setIsFolded(!isFolded); // Toggle the folded state
  };

  const TodaySaleApi = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    const raw = JSON.stringify({
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      status: 1,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(GetSalesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          console.log("resultt", result.data);
          setLoading(false);
          setData(result?.data);
          setPaymentArr(result?.data?.paymentArr);
          setTodaySales(result?.data?.totalSalesAmountGrandTotal);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const GetMessages = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(GetMessagesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result?.status === true) {
          const bannerData = result?.data?.bannerText;
          setMessageData(bannerData);
        } else {
          // Swal.fire(
          //   "To ensure you see the most recent changes, please clear your browser's cache"
          // );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial || messageData
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {loading ? (
            <div className="landing_loader">
              <div className="innerloader">
                <img src="img/spin-1s-156px.gif" alt="" />
              </div>
            </div>
          ) : null}
          {isFreeTrial ? (
            <div className="app-container-trial">
              <FreeTrail />
            </div>
          ) : messageData ? (
            <div className="app-container-trial bannercssmsg">
              <BannerMessage />
            </div>
          ) : null}
          <div style={sidebarStyleone}>
            <nav className="navbar navbar-expand-lg main-navbar sticky">
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();
                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto">
                <h4>
                  Open & Close Register{" "}
                  <a
                    className="notes_icon"
                    href="#"
                    data-toggle="modal"
                    data-target="#noteinfoModal"
                  >
                    <img src="assets/img/notes-icon.svg" alt="" />
                  </a>
                </h4>
              </div>
              <SideUl />
            </nav>
            <MainSidebar />
            {RegisterData?.length === 0 || hasClosedRegister ? (
              <OpenCloseCash onLoad={GetCashDetails} onGet={data} />
            ) : (
              <>
                <div className="main-content tableback">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="cash_mange_record">
                        <div className="cash_manag_left">
                          <h4>Open &amp; Close Register</h4>
                          <p>
                            Record all your cash movements for the day. If you
                            need help
                          </p>
                          <p>
                            Petty cash is&nbsp;a small amount of money kept on
                            hand for small purchases or expenses.
                          </p>
                        </div>
                      </div>
                      <div className="clearfix" />
                    </div>
                  </div>
                  <div className="cus_regis_openclose_sec">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="borderline" />
                        <div className="product_details_info">
                          <div className="prod_detail_left">
                            <div className="custom_heading">
                              <h4>Register Details</h4>
                            </div>
                          </div>
                          <div className="prod_detail_right">
                            <div className="prod_col_label">
                              <label>Register:</label>
                              <span>Cash Register</span>
                            </div>
                            <div className="prod_col_label">
                              <label>Closure #:</label>
                              <span>
                                {openRegisterDetails?.cashManagementDetail?.id}
                              </span>
                            </div>
                            <div className="prod_col_label">
                              <label>Opening time:</label>
                              <span>
                                {
                                  openRegisterDetails?.cashManagementDetail
                                    ?.openingDateTime
                                }
                              </span>
                            </div>
                            <div className="prod_col_label">
                              <label>Opening User:</label>
                              <span>
                                {
                                  openRegisterDetails?.companyCashDetail[0]
                                    ?.name
                                }{" "}
                                (
                                {
                                  openRegisterDetails?.companyCashDetail[0]
                                    ?.email
                                }
                                )
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="borderline" />
                        <div className="product_summary_table">
                          <div className="product_summary_heading custom_heading">
                            <h4>Payments Summary</h4>
                            <span>
                              Record all your cash movements for the day. If you
                              need help
                            </span>
                          </div>
                          <div className="table-responsive summary_table_data">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Payment Types</th>
                                  <th>Expected ($)</th>
                                  <th>Counted ($)</th>
                                  <th>Diffrences ($)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="tr_view" onClick={toggleFold}>
                                  <td>
                                    <div className="down_arrow">
                                      <img
                                        src="assets/img/table-angle-down.svg"
                                        alt=""
                                        style={{
                                          transform: isFolded
                                            ? "rotate(180deg)"
                                            : "rotate(0deg)", // Rotate the arrow when folded
                                          transition: "transform 0.3s",
                                        }}
                                      />
                                    </div>
                                    Cash{" "}
                                    <span>
                                      View Payments, Floats and Movements
                                    </span>
                                  </td>
                                  <td>{expectedAmount.toFixed(2)}</td>
                                  <td className="input_field">
                                    <input
                                      type="text"
                                      className="cus_input"
                                      placeholder="Enter Amount"
                                      value={countedAmount}
                                      onChange={handleCountedChange}
                                    />
                                    {error && (
                                      <p style={{ color: "red" }}>{error}</p>
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      color:
                                        difference > 0
                                          ? "green"
                                          : difference < 0
                                          ? "red"
                                          : "black",
                                    }}
                                  >
                                    {/* {difference} */}
                                    {difference < 0
                                      ? `-${Math.abs(difference)}`
                                      : difference}
                                  </td>
                                  {/* <td style={{ color: "red" }}>{difference}</td> */}
                                </tr>
                                {isFolded && (
                                  <tr className="tr_fold">
                                    <td className="main_td" colSpan={4}>
                                      <table style={{ width: "100%" }}>
                                        <tbody>
                                          <tr>
                                            <th>Cash Movements</th>
                                            <th>Time &amp; User</th>
                                            <th>Amount ($)</th>
                                            <th>Reason</th>
                                          </tr>
                                          {openRegisterDetails
                                            ?.companyCashDetail?.length > 0 ? (
                                            openRegisterDetails?.companyCashDetail
                                              .filter(
                                                (item) =>
                                                  item.type !== "Closing float"
                                              )
                                              .map((movement, index) => {
                                                // Determine color and symbol based on type
                                                let amountStyle = {};
                                                let displayAmount =
                                                  movement?.totalAmount.toFixed(
                                                    2
                                                  );

                                                if (
                                                  movement?.type ===
                                                  "Opening float"
                                                ) {
                                                  amountStyle = {
                                                    color: "black",
                                                  };
                                                } else if (
                                                  movement?.type ===
                                                    "Cash In" ||
                                                  movement?.type ===
                                                    "Petty Cash In"
                                                ) {
                                                  amountStyle = {
                                                    color: "green",
                                                  };
                                                } else if (
                                                  movement?.type ===
                                                    "Cash Out" ||
                                                  movement?.type ===
                                                    "Petty Cash Out"
                                                ) {
                                                  amountStyle = {
                                                    color: "red",
                                                  };
                                                  displayAmount = `-${displayAmount}`; // Add minus sign for cash outflows
                                                }

                                                return (
                                                  <tr key={index}>
                                                    <td className="empty_td" />
                                                    <td>
                                                      {movement?.date} <br />
                                                      {movement?.name} (
                                                      {movement?.email})
                                                    </td>
                                                    <td style={amountStyle}>
                                                      {displayAmount}
                                                    </td>
                                                    <td>
                                                      {movement?.type || "--"}
                                                    </td>
                                                  </tr>
                                                );
                                              })
                                          ) : (
                                            <tr>
                                              <td colSpan={4}>
                                                No Cash Movements
                                              </td>
                                            </tr>
                                          )}

                                          <tr>
                                            <th colSpan={4}>
                                              Cash Payments Recieved
                                            </th>
                                          </tr>
                                          <tr>
                                            <td colSpan={4}>
                                              {paymentarr?.find(
                                                (payment) =>
                                                  payment?.paymentType ===
                                                  "Cash"
                                              )?.totalGrandTotal || "0.00"}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                )}
                                {(paymentarr || [])
                                  ?.filter(
                                    (item) => item.paymentType !== "Cash"
                                  )
                                  .map((item, index) => {
                                    return (
                                      <tr className="tr_view" key={index}>
                                        <td>{item?.paymentType}</td>
                                        <td>
                                          {item?.totalGrandTotal?.toFixed(2)}
                                        </td>
                                        <td className="input_field">
                                          {/* <input
          type="text"
          className="cus_input"
          placeholder="Enter Amount"
        /> */}
                                        </td>
                                        <td>
                                          {item?.totalGrandTotal?.toFixed(2)}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td>
                                    <b>Total</b>
                                  </td>
                                  <td>{expectedAmount.toFixed(2)}</td>
                                  <td>{countedAmount || 0.0}</td>
                                  <td
                                    style={{
                                      color:
                                        difference > 0
                                          ? "green"
                                          : difference < 0
                                          ? "red"
                                          : "black",
                                    }}
                                  >
                                    {difference < 0
                                      ? `-${Math.abs(difference)}`
                                      : difference}
                                  </td>
                                  {/* <td>{difference}</td> */}
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                        <div className="borderline" />
                        <div className="closing_notes">
                          <div className="closenote_heading custom_heading">
                            <h4>Closing Notes</h4>
                            <span>
                              Record all your cash movements for the day. If you
                              need help
                            </span>
                          </div>
                          <div className="closenote_field">
                            <textarea
                              className="cus_textfield"
                              placeholder="Enter Note"
                              value={note}
                              onChange={(e) => setNote(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="closing_btns">
                          <button
                            type="button"
                            className="close_reg_btn cus_btn"
                            onClick={handleOpenRegister}
                          >
                            Close Register
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bottom_btns">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="bottom_links">
                          <ul>
                            <li>
                              <Link to="/openclosecashregister">
                                Open/Close Float
                              </Link>
                              {/* <a href="#">--</a> */}
                            </li>
                            <li>
                              {/* <a href="#">--</a> */}

                              <Link to="/cashmanagement">Cash Management</Link>
                            </li>
                            <li>
                              <a href="#">Note</a>
                            </li>
                            <li>
                              <a href="#">Signature</a>
                            </li>
                            <li>
                              <a href="#">Start Over</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <Footer />
        </div>
      </div>
      <div
        className="modal common_modal noteinfo_modal"
        id="noteinfoModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="noteinfoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="noteclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="assets/img/notes-close-icon.svg" alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className="notes_tabs">
                <ul
                  className="nav nav-pills notetabs"
                  id="notetabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="video_demo-tab"
                      data-toggle="tab"
                      href="#video_demo"
                      role="tab"
                      aria-controls="video_demo"
                      aria-selected="true"
                    >
                      Video Demo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="page_info-tab"
                      data-toggle="tab"
                      href="#page_info"
                      role="tab"
                      aria-controls="page_info"
                      aria-selected="false"
                    >
                      Page Information
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="settingContent">
                  <div
                    className="tab-pane fade active show"
                    id="video_demo"
                    role="tabpanel"
                    aria-labelledby="video_demo-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Video Demo</h4>
                      <p>
                        In this short tutorial, we’ll walk you through the key
                        features and functions of the Dashboard page.
                      </p>
                      <div className="notes_videos">
                        <img
                          src="assets/img/notes-demo-videos-img.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="page_info"
                    role="tabpanel"
                    aria-labelledby="page_info-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Page Information</h4>
                      <p>
                        This page provides a comprehensive view of all the tools
                        and features available to you. Each section is designed
                        to help you manage your business more efficiently,
                        whether you’re tracking sales, monitoring inventory, or
                        analyzing reports.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OpenCloseCashRegister;
