import React, { useEffect, useState, useRef } from "react";
import SideUl from "../MainDashboard/SideUl";
import MainSidebar from "../MainDashboard/MainSidebar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  AddBrandApi,
  AddCustomerDeviceApi,
  AddServiceApi,
  GetAllServiceApi,
  GetAllSubUserApi,
  GetAllTicketApi,
  GetLabelRecieptApi,
  GetMessagesApi,
  GetRecieptApi,
  GetServiceReceiptApi,
  GetStatusApi,
  GetSubUserProfileApi,
  baseurl,
  customerAddApi,
  getProfileApi,
  uploadImageApi,
} from "../Utility/Api";
import Swal from "sweetalert2";
import moment from "moment";
import TicketDeviceModal from "../MainDashboard/TicketDeviceModal";
import TicketCash from "./TicketCash";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import FreeTrail from "../MainDashboard/FreeTrail";
import Barcode from "react-barcode";
import BannerMessage from "../MainDashboard/BannerMessage";

const TicketDetail = () => {
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [service, setService] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [techAssigned, setTechAssigned] = useState("");
  const [salesPerson, setSalesPerson] = useState("");
  const [binLocation, setBinLocation] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [address, setAddress] = useState("");
  const [imeiNumber, setIMEINumber] = useState("");
  const [password, setPassword] = useState("");
  const [driverLiecence, setDriverLiecence] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  const [LastDate, setLastDate] = useState("");
  const [description, setDescription] = useState("");
  let location = useLocation();
  const [selectedStatus, setSelectedStatus] = useState("Select");
  const [selectedStatus1, setSelectedStatus1] = useState("Select");
  const [selectedStatusId, setSelectedStatusId] = useState("");
  const [customerData, setCustomerData] = useState({});
  const [searchTerm1, setSearchTerm1] = useState("");
  const [AllBrand1, setAllBrand1] = useState([]);
  const [typedText, setTypedText] = useState("");
  const [selectedBrand1, setSelectedBrand1] = useState(null);
  const [selectedBrandId1, setSelectedBrandId1] = useState(null);
  const [showList1, setShowList1] = useState(false);
  const [showListService, setShowListService] = useState(false);
  const [showListService1, setShowListService1] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [selectedService1, setSelectedService1] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const [selectedServiceId1, setSelectedServiceId1] = useState("");
  const [searchTermService, setSearchTermService] = useState("");
  const [searchTermService1, setSearchTermService1] = useState("");
  const [salesPersonId, setSalesPersonId] = useState("");
  const [allService, setAllService] = useState([]);
  const [allService1, setAllService1] = useState([]);
  const [otherInfo, setOtherInfo] = useState("");
  const [showListDevice, setShowListDevice] = useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [allcustomerDevice, setAllCustomerDevice] = useState([]);
  const [adminState, setAdminState] = useState("");
  const [formDate, setFormDate] = useState("");
  const [formDate1, setFormDate1] = useState("");
  const [allSubuser, setAllSubuser] = useState([]);
  const [selectedTech, setSelectedTech] = useState(null);
  const [selectedTechId1, setSelectedTechId1] = useState(null);
  const [showListTech, setShowListTech] = useState(false);
  const [searchTermTech, setSearchTermTech] = useState("");
  const [searchTermDevice, setSearchTermDevice] = useState("");
  const [showModal1, setShowModal1] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [images, setImages] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [images1, setImages1] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [city, SetCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [validstate, setValidState] = useState(false);
  const [imageMsg, setImageMsg] = useState("");
  const [countrycode, setCountryCode] = useState({});
  const [selectedColor, setSelectedColor] = useState("Select");
  const [errormsg, setErrormsg] = useState("");
  const [selectedOption, setSelectedOption] = useState({
    customerPhone: 0,
    customerEmail: 0,
    none: 0,
  });
  const [customerDetails, setCustomerDetails] = useState({
    imei: 0,
    dueDate: 0,
    service: 0,
    brand: 0,
    barcode: 0,
    model: 0,
    password: 0,
  });
  const [selectedServiceOption, setSelectedServiceOption] = useState({
    prodname: 0,
    prodname_sku: 0,
    sku: 0,
  });
  const [customerServiceDetails, setCustomerServiceDetails] = useState({
    name: 0,
    email: 0,
    phone: 0,
    address: 0,
  });
  const [serviceDetails, setServiceDetails] = useState({
    dueDate: 0,
    service: 0,
    brand: 0,
    model: 0,
  });
  const [printTypeService, setPrintTypeService] = useState();
  const [title, setTitle] = useState("");
  const [logo, setLogo] = useState("");
  const [isLogo, setIsLogo] = useState(true);
  const [logoSize, setLogoSize] = useState();
  const [logoPosition, setLogoPosition] = useState();
  const [header, setHeader] = useState("");
  const [displySales, setDisplaySales] = useState(false);
  const [footer, setFooter] = useState("");
  const [note, setNote] = useState(false);
  const [displayBarcode, setDisplayBarcode] = useState(false);
  const [displaySignature, setDisplaySignature] = useState(false);
  const [displayNote, setDisplayNote] = useState(false);
  const [states, setStates] = useState(false);
  const [priceZero, setPriceZero] = useState(false);
  const [servedPerson, setServedPerson] = useState(false);
  const [TechPerson, setTechPerson] = useState(false);
  const [ticketNo, setTicketNo] = useState(false);
  const [signature, setSignature] = useState(false);
  const [cashierName, setCashierName] = useState("");
  const [technicianName, setTechnicianName] = useState("");
  const [gstShow, setGstShow] = useState();
  const [leftMargin, setLeftMargin] = useState("0px");
  const [rightMargin, setRightMargin] = useState("0px");
  const [gstNumber, setSGTNumber] = useState("");
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [description1, setDescription1] = useState("");
  const [Status, setStatus] = useState([]);
  const [statusColors, setStatusColors] = useState({});
  const inputRef5 = useRef(null);
  const inputRef6 = useRef(null);
  const dropdownRef5 = useRef(null);
  const dropdownRef6 = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const dropdownRef3 = useRef(null);
  const [unit, setUnit] = useState("");
  const [techpersonsub, setTechPersonSub] = useState("");
  const [showModal9, setShowModal9] = useState(false);
  // const [states, setStates] = useState(false);
  const [printType, setPrintType] = useState();
  const [showModal11, setShowModal11] = useState(false);
  const [statusId, setStatusId] = useState();
  const [orientation, setOrientation] = useState("");
  const [FontSize, setFontSize] = useState("");
  const [updateUserName, setUpdateUserName] = useState("");
  const [messageData, setMessageData] = useState();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef5.current &&
        !dropdownRef5.current.contains(event.target)
      ) {
        setShowList1(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef5]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setShowListService(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef2]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef3.current &&
        !dropdownRef3.current.contains(event.target)
      ) {
        setShowListService1(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef3]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef6.current &&
        !dropdownRef6.current.contains(event.target)
      ) {
        setShowListDevice(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef6]);

  const handleStatusSelect = (status) => {
    console.log("statuss", status);
    setSelectedStatus(status?.name);
    setSelectedColor(status?.color);
    setSelectedStatusId(status?.id);
    EditTicket(status?.id);
  };

  const handleDueDateChange = (event) => {
    setDueDate(event.target.value);
  };

  const handleTechAssignedChange = (event) => {
    setTechAssigned(event.target.value);
  };

  const handleBinLocationChange = (event) => {
    setBinLocation(event.target.value);
  };

  const GetAllSubUser = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllSubUserApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setAllSubuser(result?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetCustomerDevice = (id) => {
    let local = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllCustomerDevice?customerId=${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllCustomerDevice(result?.data.reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [items, setItems] = useState([]);
  const [itemIds, setItemIds] = useState([]);
  const [grand, setGrand] = useState("");
  const [sub, setSub] = useState("");
  const [tax, setTax] = useState("");
  const [discount, setDiscount] = useState("");
  const [taxPer, setTaxPer] = useState("");
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [change, setChange] = useState("");
  const [time, setTime] = useState("");
  const [invoiceData, setInvoiceData] = useState([]);

  useEffect(() => {
    GetAllStatus();
    let localAdmin = localStorage.getItem("admin");
    // GetReciept();
    GetCustomerId();
    setAdminState(localAdmin);
    GetAllBrandDevice();
    GetAllService();
    GetAllSubUser();
    GetAllService1();
    let data = location?.state?.data;
    GetCustomerDevice(data?.ticket?.customer?.id);
    setInvoiceData(data?.invoice);
    console.log("ticketdetail", data);
    setItems(data?.invoice?.items);
    setItemIds(data?.invoice?.itemIds);
    setGrand(data?.invoice?.grandTotal);
    setSub(data?.invoice?.subTotal);
    setTax(data?.invoice?.taxAmount);
    setDiscount(data?.invoice?.discountAmount);
    setTaxPer(data?.invoice?.tax);
    setPaymentDetails(data?.invoice?.payment);
    setChange(data?.invoice?.due);
    setTime(data?.ticket?.timeZone);
    setModel(data?.ticket?.customerDevice?.model);
    setSelectedDevice(data?.ticket?.customerDevice?.model);
    setSelectedDeviceId(data?.ticket?.customerDevice?.id);
    setMake(data?.ticket?.customerDevice?.model);
    setSelectedService(data?.ticket?.service1?.name);
    setSelectedServiceId(data?.ticket?.service1?.id);
    setSelectedService1(data?.ticket?.service2?.name);
    setSelectedServiceId1(data?.ticket?.service2?.id);
    setTicketId(data?.ticket?.id);
    setFirstName(data?.ticket?.customer?.firstName);
    setEmail(data?.ticket?.customer?.email);
    setPhoneNo(data?.ticket?.customer?.phoneNumber);
    setLastName(data?.ticket?.customer?.lastName);
    setAddress(data?.ticket?.customer?.streetAddress);
    setIMEINumber(data?.ticket?.customerDevice?.imeiNumber);
    setPassword(data?.ticket?.password);
    setDriverLiecence(data?.ticket?.drivingLicence);
    setBinLocation(data?.ticket?.bin);
    setDueDate(data?.ticket?.dueDate);
    if (data?.salesPerson?.name || data?.techPerson?.name) {
      setSalesPerson(data?.salesPerson?.name);
      setTechPersonSub(data?.techPerson?.name);
      setTechAssigned(data?.techPerson?.name);
    } else {
      setSalesPerson(data?.salesPerson?.userName);
      setTechAssigned(data?.techPerson?.userName);
    }
    setSalesPersonId(data?.salesPerson?.id);

    setCustomerData(data?.ticket?.customer);

    setSelectedBrand1(data?.ticket?.brand?.name);
    setSelectedBrandId1(data?.ticket?.brand?.id);
    setDateCreated(data?.ticket?.timeZone);
    setLastDate(data?.ticket?.updatedTimeZone);
    setSelectedTech(data?.techPerson?.userName);
    setSelectedTechId1(data?.techPerson?.id);
    setImages(data?.ticket?.customer?.profileImage);
    SetCity(data?.ticket?.customer?.city);
    setCountry(data?.ticket?.customer?.country);
    setState(data?.ticket?.customer?.state);
    setZipcode(data?.ticket?.customer?.zipCode);
    setDescription(data?.ticket?.description);
    setImages1(data?.ticket?.customerDevice?.image);
    setSelectedStatusId(data?.ticket?.statusOption?.id);
    setSelectedStatus(data?.ticket?.statusOption?.name);
    setSelectedStatus1(data?.ticket?.statusOption?.name);
    setSelectedColor(data?.ticket?.statusOption?.color);
    const dateString = data?.ticket?.timeZone;
    const date = moment(dateString).tz(moment.tz.guess());
    const formattedDate = date.format("ddd, MMM DD, YYYY, h:mma");
    setFormDate(formattedDate);
    const dateString1 = data?.ticket?.updatedTimeZone;
    const date1 = moment(dateString1).tz(moment.tz.guess());

    const formattedDate1 = date1.format("ddd, MMM DD, YYYY, h:mma");
    setFormDate1(formattedDate1);
  }, []);

  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) {
      return "";
    }

    const digitsOnly = phoneNumber.replace(/\D/g, "");

    if (digitsOnly.length > 10) {
      const countryCode = digitsOnly.slice(0, digitsOnly.length - 10);
      const phoneNumberWithoutCode = digitsOnly.slice(-10);
      return `(+${countryCode})${phoneNumberWithoutCode}`;
    } else {
      const areaCode = digitsOnly.slice(0, 3);
      const firstPart = digitsOnly.slice(3, 6);
      const secondPart = digitsOnly.slice(6);

      return `(${areaCode})${firstPart}-${secondPart}`;
    }
  }

  const handleSearchChange1 = (e) => {
    setSearchTerm1(e.target.value);
    setTypedText(e.target.value);
    setSelectedBrand1(null);
  };

  const handleAddNewClickDevice = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Brand name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }

    setSelectedBrand1(typedText);
    setShowList1(false);
    setSearchTerm1("");
    setTypedText("");
    AddBrandDevice();
  };
  const handleBrandSelect1 = (brand) => {
    setSelectedBrand1(brand.name);
    setSelectedBrandId1(brand.id);
    setShowList1(false);
  };
  const filteredBrands1 = AllBrand1.filter((brand) => {
    const name = brand.name;
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTerm1.toLowerCase());
    }

    return false;
  });

  const AddBrandDevice = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 2,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddBrandApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllBrandDevice();
          setSelectedBrandId1(result?.data?.id);
          setSearchTerm1("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllBrandDevice = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllBrand?productCategoryId=2`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllBrand1(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddService = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: typedText,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddServiceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setSelectedService(result?.data?.name);
          setSelectedServiceId(result?.data?.id);
          GetAllService();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetAllService = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllServiceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllService(result?.data.reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const filteredService = allService.filter((brand) => {
    const name = brand.name;
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermService.toLowerCase());
    }

    return false;
  });
  const navigate = useNavigate();
  const handleServiceSelect = (brand) => {
    setSelectedService(brand.name);
    setSelectedServiceId(brand.id);
    setShowListService(false);
  };
  const handleSearchChangeService = (e) => {
    setSearchTermService(e.target.value);
    setTypedText(e.target.value);
    setSelectedService(null);
  };
  const handleButtonClickService = (e) => {
    e.preventDefault();
    setShowListService(!showListService);
  };

  const handleAddNewClickService = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Service name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    setSelectedService(typedText);
    setShowListService(false);
    setSearchTermService("");
    setTypedText("");
    AddService();
  };

  const AddService1 = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: typedText,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddServiceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setSelectedService1(result?.data?.name);
          setSelectedServiceId1(result?.data?.id);
          GetAllService1();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetAllService1 = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllServiceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllService1(result?.data.reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleImages1 = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 5000; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (file.size < minSizeInBytes) {
        setImageMsg("Image size is below the minimum allowed size (5 KB)");
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);
        return;
      }

      // Check if the file name contains only one dot before the extension
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf(".");
      const baseName = fileName.substring(0, lastDotIndex);
      const extension = fileName.substring(lastDotIndex + 1);

      // Only allow files with a single dot in the base name
      if (baseName.includes(".")) {
        setImageMsg(
          "File name is not valid. Only one dot is allowed before the extension."
        );
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);

        return;
      }

      let size = file.type.replace("/", ".");
      var blob = file.slice(0, file.size, file.type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: file.type });

      UploadImage1(newFile);
    }
  };

  const UploadImage1 = async (newFile) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "ticket-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setImages1(result?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const filteredService1 = allService1.filter((brand) => {
    const name = brand.name;
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermService.toLowerCase());
    }

    return false;
  });

  const handleServiceSelect1 = (brand) => {
    setSelectedService1(brand.name);
    setSelectedServiceId1(brand.id);
    setShowListService1(false);
  };
  const handleSearchChangeService1 = (e) => {
    setSearchTermService1(e.target.value);
    setTypedText(e.target.value);
    setSelectedService1(null);
  };

  const handleAddNewClickService1 = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Service name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    setSelectedService1(typedText);
    setShowListService1(false);
    setSearchTermService1("");
    setTypedText("");
    AddService1();
  };

  const EditTicket = (id, invoiceId) => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      customerId: customerData?.id,
      customerDeviceId: selectedDeviceId ? selectedDeviceId : null,
      brandId: selectedBrandId1 ? selectedBrandId1 : null,
      serviceId1: selectedServiceId ? selectedServiceId : null,
      serviceId2: selectedServiceId1 ? selectedServiceId1 : null,
      statusOptionId: id,
      techAssignedId: selectedTechId1,
      salesPersonId: salesPersonId,
      dueDate: dueDate,
      password: password,
      description: description,
      drivingLicence: driverLiecence,
      bin: binLocation,
      isAdmin: adminState,
      isTechAdmin: adminState,
      isSalesPersonAdmin: adminState,
      isInvoice: 1,
      invoiceId: invoiceId,
      techAssignedName: techAssigned,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("rwa editticket", raw);
    fetch(`${baseurl}updateTicket/${ticketId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("edit ticket status", result);
        if (result?.status === true) {
          // navigate("/ticketlist");
          document
            .querySelectorAll(".modal-backdrop")
            .forEach((el) => el.classList.remove("modal-backdrop"));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const filteredTech = allSubuser.filter((brand) => {
    const name = brand.name;
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermTech.toLowerCase());
    }

    return false;
  });

  const formattedDueDate = dueDate ? dueDate.split("T")[0] : "";
  const filteredDevice = allcustomerDevice.filter((brand) => {
    const name = brand.model;
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermDevice.toLowerCase());
    }

    return false;
  });

  const handleSearchChangeDevice = (e) => {
    setSearchTermDevice(e.target.value);
    setTypedText(e.target.value);
    setSelectedDevice(null);
  };
  const handleBrandSelectDevice = (brand) => {
    setSelectedDevice(brand.model);
    setSelectedDeviceId(brand.id);
    setShowListDevice(false);
  };

  const isDisposableEmail = (email) => {
    const domain = email.split("@")[1];
    return disposableEmailDomains.includes(domain);
  };

  const disposableEmailDomains = [
    "mailinator.com",
    "guerrillamail.com",
    "10minutemail.com",
    "temp-mail.org",
    "yopmail.com",
    "getnada.com",
    "trashmail.com",
    "fakeinbox.com",
    "throwawaymail.com",
    "maildrop.cc",
    "mytemp.email",
    "tempmailo.com",
    "dispostable.com",
    "mintemail.com",
    "airmail.com",
  ];

  const Validation = () => {
    let error = {};
    let reg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;
    // const isValid = /^[0-9]+$/.test(value);
    // const regex = /^[A-Za-z ]+$/;
    const regex = /^[A-Za-z0-9 ]+$/;
    const isNumeric = /^[0-9]+$/;
    // if (!email) {
    //   error.email = "Email is required.";
    // } else
    if (email && !reg.test(email)) {
      error.email = "Invalid email.";
    } else if (email && isDisposableEmail(email)) {
      error.email =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }
    if (!firstName) {
      error.firstName = "First Name Is Required";
    } else if (!regex.test(firstName)) {
      error.firstName = "First Name Is Invalid";
    }
    // if (!phoneNo) {
    //   error.phoneNo = "Phone Number Is Required";
    // }

    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      CustomerApi();
    }
    return error;
  };

  const CustomerApi = () => {
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    let local = localStorage.getItem("token");
    let userid = localStorage.getItem("userId");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AyaHFql-YNXNgdQlka4nRvpWL-iJLSdB-.%2B6wW6d812YXYU4ihAgIwRTFu54mu6VtFKEcdK50W57A"
    );

    var raw = JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNo, // phoneNo ? phoneNo.replace(/^\+/, "") : "",
      profileImage: images,
      country: country,
      countryCode: String(countrycode.country_code),
      streetAddress: address,
      state: state,
      zipCode: zipcode,
      city: city,
      storeCredit: 32.23,
      account: 10.23,
      mainUserId: userid,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log("customer raww", raw);
    fetch(`${baseurl}updateCustomerProfile/${customerData?.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("result cutomer", result);
        if (result.status === true) {
          setShowModal(false);
          GetCustomerId(result?.data?.id);
          setMessage(result?.message);
          setFirstName("");
          setLastName("");
          setImages("");
          setAddress("");
          setPhoneNo("");
          setCountry("");
          SetCity("");
          setZipcode("");
          setState("");
          setEmail("");
          setMessage("");
          setValidState(false);
        } else {
          setMessage(result?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const SubmitCus = () => {
    setError(Validation());
  };

  const handleImages = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 5000; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (file.size < minSizeInBytes) {
        setImageMsg("Image size is below the minimum allowed size (5 KB)");
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);
        return;
      }

      // Check if the file name contains only one dot before the extension
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf(".");
      const baseName = fileName.substring(0, lastDotIndex);
      const extension = fileName.substring(lastDotIndex + 1);

      // Only allow files with a single dot in the base name
      if (baseName.includes(".")) {
        setImageMsg(
          "File name is not valid. Only one dot is allowed before the extension."
        );
        e.target.value = null;
        setTimeout(() => {
          setImageMsg("");
        }, 2000);

        return;
      }

      let size = file.type.replace("/", ".");
      var blob = file.slice(0, file.size, file.type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: file.type });

      UploadImage(newFile);
    }
  };

  const UploadImage = async (newFile) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "customers-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setImages(result?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };
  const handleModalClose = () => {
    setShowModal(false);
  };

  const handlePhoneChange = (value) => {
    setPhoneNo(value);
    const countryInfo = value && value.match(/^\+(\d+)/);
    const countryCode = countryInfo ? `+${countryInfo[1]}` : "";
  };

  const HandleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setImages("");
        });
      }
    });
  };

  const HandleDelete1 = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setImages1("");
        });
      }
    });
  };

  useEffect(() => {
    // GetCustomer();
    // fetch(
    //   "https://ip-api.io/api/json?api_key=fabc32c1-943d-43c7-8a13-ea82cd6ac052"
    // )
    fetch(
      "https://ip-api.io/api/json?api_key=fabc32c1-943d-43c7-8a13-ea82cd6ac052"
    )
      .then((response) => response.json())
      .then((json) => {
        setCountryCode(json);
      });
  }, []);

  const GetCustomerId = (id) => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getCustomerProfile/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setFirstName(result?.data?.firstName);
          setLastName(result?.data?.lastName);
          setPhoneNo(result?.data?.phoneNumber);
          setEmail(result?.data?.email);
          setAddress(result?.data?.streetAddress);
          setCountry(result?.data?.country);
          SetCity(result?.data?.city);
          setZipcode(result?.data?.zipCode);
          setState(result?.data?.state);
          setImages(result?.data?.profileImage);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const Validate = () => {
    let error = {};
    // if (!selectedDevice) {
    //   error.selectedDevice = "Model Name Is Required";
    // } else if (selectedDevice.trim().length === 0) {
    //   error.selectedDevice = "Model NAME Is Required";
    // }
    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      CustomerDevice();
    }
    return error;
  };

  const OnSubmit = () => {
    setError(Validate());
  };

  const maxDescriptionLength = 200;
  const handleDescriptionChange1 = (e) => {
    const newDescription = e.target.value;

    if (newDescription.length <= maxDescriptionLength) {
      setDescription1(newDescription);
      setErrormsg("");
    } else if (newDescription.length > maxDescriptionLength) {
      setTimeout(() => {
        setErrormsg("Allow Maximum 200 Characters");
      }, 2000);
    }
  };
  const CustomerDevice = () => {
    let local = localStorage.getItem("token");
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      brandId: selectedBrandId1 ? selectedBrandId1 : null,
      description: description1,
      imeiNumber: imeiNumber,
      customerId: customerData?.id,
      model: model,
      image: images1,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddCustomerDeviceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetCustomerDevice(customerData?.id);
          setSelectedDevice(result?.data?.model);
          setSelectedDeviceId(result?.data?.id);
          setImages1(result?.data?.image);
          setShowModal1(false);
          //  props.onpress(false);
          //  props.onload(result?.data?.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetAllStatus = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetStatusApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("statusss", result);
        const filteredStatusList = result?.data?.filter(
          (status) =>
            status?.name !== "Invoiced" && status?.name !== "Cancelled"
        );

        // Step 2: Sort the status list, with "New" on top and "Finished" at the bottom
        const sortedStatusList = filteredStatusList?.sort((a, b) => {
          if (a?.name === "New") return -1;
          if (b?.name === "New") return 1;

          if (a?.name === "Finished") return 1;
          if (b?.name === "Finished") return -1;

          return a.name.localeCompare(b.name);
        });

        // Step 3: Set the sorted and filtered status list in the state
        setStatus(sortedStatusList);

        // Find the status with name "Invoiced"
        const invoicedStatus = result?.data?.find(
          (status) => status?.name === "Invoiced"
        );
        setStatusId(invoicedStatus.id);

        // const sortedStatusList = result?.data.sort((a, b) => {
        //   if (a?.name === "New") return -1;
        //   if (b?.name === "New") return 1;

        //   if (a?.name === "Finished") return 1;
        //   if (b?.name === "Finished") return -1;
        //   return a.name.localeCompare(b.name);
        // });
        // setStatus(sortedStatusList);

        // Initialize statusColors state with default colors
        const defaultColors = result?.data?.reduce((acc, curr) => {
          acc[curr.id] = "#ffffff"; // You can set a default color here
          return acc;
        }, {});
        setStatusColors(defaultColors);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const printTable = (orientation1) => {
    const printContents =
      document.querySelector(".recieptlabelprint").innerHTML;
    const originalContents = document.body.innerHTML;

    let width = 800;
    let height = 600;

    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const printWindow = window.open(
      "",
      "_blank",
      `width=${width},height=${height},top=${top},left=${left}`
    );

    if (!printWindow) {
      Swal.fire(
        "Unable to open print window. Please check your pop-up settings."
      );
      return;
    }

    printWindow.document.open();
    printWindow.document.write(`
    <html>
      <head>
        <style>
          @media print {
            @page {
              size: ${orientation1 === "Portrait" ? "portrait" : "landscape"};
              margin: 0;
            }

            .recieptlabelprint {
              width: fit-content;
              margin: auto;
              /* Adjust margins and padding as needed based on the image */
            }

            /* Portrait-specific styles (optional) */
             /* Portrait-specific styles */
            @media print and (orientation1: portrait) {
              .recieptlabelprint {
                height: fit-content;
              }
            }

            /* Landscape-specific styles */
            @media print and (orientation1: landscape) {
              .recieptlabelprint {
                width: fit-content;
              }
            }
           
          }
        </style>
      </head>
      <body>${printContents}</body>
    </html>
  `);
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 200);
  };

  //   const printTable = () => {
  //     const printContents =
  //       document.querySelector(".recieptlabelprint").innerHTML;
  //     const originalContents = document.body.innerHTML;

  //     const width = 800;
  //     const height = 600;
  //     const left = (window.innerWidth - width) / 2;
  //     const top = (window.innerHeight - height) / 2;

  //     // Open a new window with centered position
  //     const printWindow = window.open(
  //       "",
  //       "_blank",
  //       `width=${width},height=${height},top=${top},left=${left}`
  //     );

  //     if (!printWindow) {
  //       Swal.fire(
  //         "Unable to open print window. Please check your pop-up settings."
  //       );
  //       return;
  //     }

  //     // const printWindow = window.open("", "_blank");
  //     printWindow.document.open();
  //     printWindow.document.write(`
  //     <html>
  //       <head>
  //      <style>
  //      @media print {
  //   @page {

  //     margin:auto; /* Remove default margins to ensure there's no space at the top and bottom */
  //   }
  //   body {

  //  /* Remove margins on body as well */
  //      margin-left:55px;
  //     margin-right: 0px;
  //      height:auto
  //   }
  //   /* Hide browser print headers and footers */
  //   header,
  //   footer {
  //     display: none;
  //   }
  // }
  //      </style>

  //      </head>
  //       <body>${printContents}</body>
  //     </html>
  //   `);
  //     printWindow.document.close();
  //     setTimeout(() => {
  //       printWindow.print();
  //       printWindow.close();
  //     }, 200);
  //   };

  const printSetupService = () => {
    Swal.fire("You have not  Setup Receipt Configuration ");
  };
  const printTableService = () => {
    const printContents = document.querySelector(
      ".reciepttableservice"
    ).innerHTML;
    const originalContents = document.body.innerHTML;
    // Calculate the position to center the new window
    const width = 800;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    // Open a new window with centered position
    const printWindow = window.open(
      "",
      "_blank",
      `width=${width},height=${height},top=${top},left=${left}`
    );

    if (!printWindow) {
      Swal.fire(
        "Unable to open print window. Please check your pop-up settings."
      );
      return;
    }

    // const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
    <html>
      <head>
     <style>
     @media print {
  @page {
    margin:auto; /* Remove default margins to ensure there's no space at the top and bottom */
  }
  body {
 /* Remove margins on body as well */
     margin-left:0px;
    margin-right: 0px;
   height:auto;
    overflow: hidden; /* Prevent overflow scrolling */
         transform-origin: top left;    

  }
   .reciepttableservice {
              transform: scale(0.99); /* Adjust this scale factor as needed */
              width: 100%;
                margin-left:0px;
    margin-right: 0px;
            }
  /* Hide browser print headers and footers */
  header,
  footer {
    display: none;
  }
}
     </style>

     </head>
      <body>
    <div class="reciepttableservice">${printContents}</div>
      </body>
    </html>
  `);
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 200); // Adjust the delay as needed
    // printWindow.print();
    // printWindow.close();
  };

  const GetReciept = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetLabelRecieptApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          if (!result?.data) {
            //setStates(true);
          } else {
            const data = result?.data;

            let { height, width, unit } = data;
            if (unit === "Inches") {
              height = convertInchesToMm(height);
              width = convertInchesToMm(width);
            }
            setHeight(height);
            setWidth(width);
            setFontSize(data?.fontSize);
            setOrientation(data?.orientation);
            console.log("orientation", data?.orientation);
            setUnit("mm");

            const parsedItems = JSON.parse(data.items);
            const parsedCustomer = JSON.parse(data.customer);

            setSelectedOption({
              customerPhone: parsedCustomer.phone === 1 ? 1 : 0,
              customerEmail: parsedCustomer.email === 1 ? 1 : 0,
              none: parsedCustomer.none === 1 ? 1 : 0,
            });

            setCustomerDetails({
              imei: parsedItems.imei,
              dueDate: parsedItems.dueDate,
              service: parsedItems.service,
              brand: parsedItems.brand,
              barcode: parsedItems.barcode,
              model: parsedItems.model,
              password: parsedItems.password,
            });
            setTimeout(() => {
              printTable(data?.orientation);
            }, 1100);
          }
        } else {
          Swal.fire(result?.message);
          navigate("/settings", { state: { activeState: 11 } });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const convertInchesToMm = (inches) => {
    return parseFloat(inches) * 25.4;
  };
  const EditTicket1 = (invoiceId) => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      customerId: customerData?.id,
      customerDeviceId: selectedDeviceId ? selectedDeviceId : null,
      brandId: selectedBrandId1 ? selectedBrandId1 : null,
      serviceId1: selectedServiceId ? selectedServiceId : null,
      serviceId2: selectedServiceId1 ? selectedServiceId1 : null,
      statusOptionId: statusId,
      techAssignedId: selectedTechId1,
      salesPersonId: salesPersonId,
      dueDate: dueDate,
      password: password,
      description: description,
      drivingLicence: driverLiecence,
      bin: binLocation,
      isAdmin: adminState,
      isTechAdmin: adminState,
      isSalesPersonAdmin: adminState,
      isInvoice: 1,
      invoiceId: invoiceId,
      techAssignedName: techAssigned,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    //console.log("rwa editticket2222", raw);
    fetch(`${baseurl}updateTicket/${ticketId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("edit ticket status", result);
        if (result?.status === true) {
          // navigate("/ticketlist");
          document
            .querySelectorAll(".modal-backdrop")
            .forEach((el) => el.classList.remove("modal-backdrop"));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));

    if (localAdmin === "0" && localPerminssion?.length) {
      GetSubUserProfile();
    } else {
      GetProfile();
    }
    GetRecieptInvoice();
    GetServiceReciept();
    GetAddTicketData();
    // GetAlltickets();
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  const navbarStyle = {
    // top: isFreeTrialVisible ? "-4px" : "-50px",
  };
  const mainContentStyle = {
    // marginTop: isFreeTrialVisible ? "132px" : "92px",
  };

  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);
  useEffect(() => {
    GetMessages();
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const GetServiceReciept = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetServiceReceiptApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("service reciept", result);
        if (result?.status === true) {
          if (!result?.data) {
            setStates(true);
          } else {
            const data = result?.data;
            //   setRecieptId(data?.id);
            setPrintType(data?.type);
            setTitle(data?.receiptTitle);
            setLogo(data?.logo);
            setIsLogo(data?.isLogo === 1);
            setLogoSize(data?.logoSize);
            setLogoPosition(data?.logoPosition);
            setHeader(data?.headerInfo);
            setDisplaySales(data?.displaySalesPersonName === 1);
            setTicketNo(data?.displayNextTicket === 1);
            setFooter(data?.footerInfo);
            setNote(data?.displayPublicNote === 1);
            setDisplayBarcode(data?.displayBarcode === 1);
            setDisplaySignature(data?.displaySignatureLine);
            setDisplayNote(data?.items?.note === 1);
            setPriceZero(data?.printProducts);
            setSignature(data?.displaySignatureLine);
            setServedPerson(data?.displaySalesPersonName);
            setCashierName(data?.servedBySalesPersonlabel);
            setTechnicianName(data?.service?.servedByTechnicianlabel);
            //   //setSGTNumber(data?.gst);
            //   // setLeftMargin(data?.leftMargin);
            //   // setRightMargin(data?.rightMargin);
            //   // setGstOnOff(data?.gstButton);
            //   // setImageMsg(""); // Assuming image message is not returned from API
            //   //setStates(data.activeInactive === 1);

            //   // Set selected options based on API response
            setSelectedServiceOption({
              prodname: data?.items.name === 1 ? 1 : 0,
              prodname_sku:
                data?.items.name === 1 && data?.items.sku === 1 ? 1 : 0,
              sku: data?.items.sku === 1 ? 1 : 0,
            });

            //   // Set customer details based on API response
            setCustomerServiceDetails({
              name: data?.customer.name === 1 ? 1 : 0,
              email: data?.customer.email === 1 ? 1 : 0,
              phone: data?.customer.phoneNumber === 1 ? 1 : 0,
              address: data?.customer.address === 1 ? 1 : 0,
            });
            setServiceDetails({
              dueDate: data?.service.dueDate === 1 ? 1 : 0,
              brand: data?.service.brand === 1 ? 1 : 0,
              model: data?.service.model === 1 ? 1 : 0,
              service: data?.service.service === 1 ? 1 : 0,
            });
            setTechPerson(data?.service?.displayTechnicianName);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetRecieptInvoice = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetRecieptApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("reciept", result?.data);
        if (result?.status === true) {
          const data = result?.data;

          setSGTNumber(data?.gst);
          setLeftMargin(data?.leftMargin || "10mm");
          setRightMargin(data?.rightMargin || "10mm");
          setGstShow(data?.gstButton);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const ValidationEmail = () => {
    let error = {};
    let reg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (email && !reg.test(email)) {
      error.email = "Invalid email.";
    } else if (email && isDisposableEmail(email)) {
      error.email =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }

    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      EmailSend1();
    }
    return error;
  };

  const OnSubmitEmail = () => {
    setError(ValidationEmail());
  };

  const EmailSend1 = () => {
    setLoading(true);
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));

    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      // name: data?.customer?.firstName ? data?.customer?.firstName : "user",
      email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}sendServiceMail/${ticketId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setLoading(false);
          //  Swal.fire("Receipt sent to email successfully!! ");
          Swal.fire({
            title: "Receipt sent to email successfully!!",
            customClass: {
              container: "custom-swal-container",
              confirmButton: "custom-swal-confirm-button",
            },
          });
          setShowModal11(false);
          document
            .querySelectorAll(".modal-backdrop")
            .forEach((el) => el.classList.remove("modal-backdrop"));
          setEmail("");
        } else {
          setLoading(false);
          //Swal.fire(result.message);
          Swal.fire({
            title: result.message,
            customClass: {
              container: "custom-swal-container",
              confirmButton: "custom-swal-confirm-button",
            },
          });
          setEmail("");
          // setShowModal7(false);
          document
            .querySelectorAll(".modal-backdrop")
            .forEach((el) => el.classList.remove("modal-backdrop"));
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const GetAddTicketData = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    // console.log("bnbvnbv", `${baseurl}/getTicketById/${props?.ticketid}`);
    fetch(`${baseurl}getTicketById/${ticketId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("ticket listdadadadd", result);
        setItems(result?.data?.invoice?.items);
        setItemIds(result?.data?.invoice?.itemIds);
        setGrand(result?.data?.invoice?.grandTotal);
        setSub(result?.data?.invoice?.subTotal);
        setTax(result?.data?.invoice?.taxAmount);
        setDiscount(result?.data?.invoice?.discountAmount);
        setTaxPer(result?.data?.invoice?.tax);
        setPaymentDetails(result?.data?.invoice?.payment);
        setChange(result?.data?.invoice?.due);
        setTime(result?.data?.ticket?.timeZone);
        setModel(result?.data?.ticket?.customerDevice?.model);
        // setInvoiceAdd(result?.data?.invoice);
        // setSelectedProduct(result?.data?.invoice?.itemIds);
        // setUnitCosts(
        //   result?.data?.invoice?.items.map((item) => item?.unitCost)
        // );
        // console.log(
        //   "gunitcost",
        //   result?.data?.invoice?.items.map((item) => item?.unitCost)
        // );

        // setReceivedQuantities(
        //   result?.data?.invoice?.items?.map((item) => item?.quantity)
        // );
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const GetProfile = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          console.log("profile", result);

          setUpdateUserName(result?.data?.userDetails?.userName);
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetSubUserProfile = () => {
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetSubUserProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("subuser profile", result);
        if (result.status === true) {
          //   setData(result?.data);

          setUpdateUserName(result?.data?.userDetails?.name);
        } else {
          console.log(result.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetMessages = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(GetMessagesApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result?.status === true) {
          const bannerData = result?.data?.bannerText;
          setMessageData(bannerData);
        } else {
          // Swal.fire(
          //   "To ensure you see the most recent changes, please clear your browser's cache"
          // );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        {loading ? (
          <div className="landing_loader">
            <div className="innerloader">
              <img src="img/spin-1s-156px.gif" alt="" />
            </div>
          </div>
        ) : null}
        <div
          className={`${
            isFreeTrial || messageData
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {isFreeTrial ? (
            <>
              {localStorage.getItem("substatus") === "null" ||
              localStorage.getItem("substatus") === "canceled" ||
              localStorage.getItem("substatus") === "expired" ? (
                <>
                  <div className="app-container-trial">
                    <FreeTrail />
                  </div>
                  <div style={sidebarStyleone}>
                    <nav className="navbar navbar-expand-lg main-navbar sticky">
                      <div className="sidebar-arrow">
                        <a
                          href="#"
                          data-toggle="sidebar"
                          onClick={(e) => {
                            toggleSidebar();

                            e.preventDefault();
                          }}
                        >
                          <img src="assets/img/sidebar-toggle.webp" alt="" />
                        </a>
                      </div>
                      <div className="header-title mr-auto">
                        <h4>
                          Ticket#{ticketId}{" "}
                          <a
                            className="notes_icon"
                            href="#"
                            data-toggle="modal"
                            data-target="#noteinfoModal"
                          >
                            <img src="assets/img/notes-icon.svg" alt="" />
                          </a>
                        </h4>
                      </div>
                      <SideUl />
                    </nav>
                    <MainSidebar />{" "}
                    <div className="main-content">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="ticket_status_btn">
                            <div className="status_btn">
                              {selectedStatus1 === "Invoiced" ||
                              selectedStatus1 === "Cancelled" ? (
                                <>
                                  <div className="dropdown">
                                    <button
                                      style={{
                                        background: selectedColor,
                                      }}
                                      className="btn btn-secondary dropdown-toggle"
                                      type="button"
                                      // id="dropdownMenuButton"
                                      // data-toggle="dropdown"
                                      // aria-haspopup="true"
                                      // aria-expanded="false"
                                    >
                                      Status:{" "}
                                      <span
                                        className="customspanlistsatutus"
                                        style={{
                                          background: selectedColor,
                                        }}
                                      ></span>
                                      {selectedStatus}
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="dropdown">
                                    <button
                                      style={{
                                        background: selectedColor,
                                      }}
                                      className="btn btn-secondary dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      Status:{" "}
                                      <span
                                        className="customspanlistsatutus"
                                        style={{
                                          background: selectedColor,
                                        }}
                                      ></span>
                                      {selectedStatus}
                                    </button>
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                      style={{ width: "100%" }}
                                    >
                                      {Status?.filter(
                                        (status) =>
                                          status?.id !== selectedStatusId
                                      )?.map((item) => (
                                        <a
                                          key={item?.id}
                                          className="dropdown-item"
                                          href="#"
                                          onClick={() =>
                                            handleStatusSelect(item)
                                          }
                                        >
                                          <span
                                            className="customspanlistsatutus"
                                            style={{
                                              background: item?.color,
                                            }}
                                          ></span>{" "}
                                          {item?.name}{" "}
                                        </a>
                                      ))}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="ticket_extra_btns">
                              <button type="text" className="cus_btn email_btn">
                                Email
                              </button>
                              <button type="text" className="cus_btn text_btn">
                                Text
                              </button>
                              <button
                                type="text"
                                className="cus_btn invoice_btn"
                              >
                                Print Invoice
                              </button>
                              <button
                                type="text"
                                className="cus_btn label_btn"
                                onClick={GetReciept}
                              >
                                Print Label
                              </button>
                              <button
                                type="text"
                                className="cus_btn servicetkt_btn"
                                data-toggle="modal"
                                data-target="#paymentModal"
                                onClick={(e) => {
                                  // setErrorAmount(false);
                                  // e.preventDefault();
                                  // Refund();
                                  setShowModal9(true);
                                }}
                              >
                                Print Service Ticket
                              </button>
                              <button
                                type="text"
                                className="cus_btn thermal_recepit_btn"
                              >
                                Print Thermal Receipt
                              </button>
                            </div>{" "}
                          </div>
                          <div className="ticket_block_content">
                            <div className="ticket_block_box ticketinfo_box">
                              <div className="tkt_box_title">
                                <h4>Ticket Info</h4>
                                <span>#{ticketId}</span>
                              </div>
                              <div className="tkt_box_content">
                                <div className="picturebrandticketdetail">
                                  <div className="ticket_box_img">
                                    {images1 ? (
                                      <img
                                        className="imagesdevicelistticket"
                                        src={images1}
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src="assets/img/phone_img.webp"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <ul className="uleditrepairticketcustm tkt_box_ul">
                                    <li
                                      className="editrepairticketlistshowcustm cus_searchdropdown"
                                      ref={dropdownRef5}
                                    >
                                      <span>Brand:</span>
                                      <div
                                        class="pos_relative"
                                        bis_skin_checked="1"
                                        onClick={() => setShowList1(!showList1)}
                                      >
                                        {selectedBrand1 || "Select Brand"}
                                        {/* <img
                                      src="assets/img/select-arrow.webp"
                                      alt="Handy Icon"
                                      style={{ cursor: "pointer" }}
                                    /> */}
                                      </div>
                                      {showList1 && (
                                        <>
                                          <div
                                            class="tagsscustomdiv"
                                            bis_skin_checked="1"
                                          >
                                            <input
                                              ref={inputRef5}
                                              type="text"
                                              placeholder={`Enter a Brand`}
                                              value={
                                                selectedBrand1 || searchTerm1
                                              }
                                              onChange={handleSearchChange1}
                                              // onClick={() =>
                                              //   setShowList1(!showList1)
                                              // }
                                              className="ticketinput inputcustmeditrepairticketcustm"
                                            />
                                            <div className="addselectbox addbrandnamecustmeditticket">
                                              <ul>
                                                {filteredBrands1.length > 0 ? (
                                                  filteredBrands1?.map(
                                                    (brand) => (
                                                      <li
                                                        key={brand.id}
                                                        onClick={() =>
                                                          handleBrandSelect1(
                                                            brand
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className="liuleditbrandrepaircstm"
                                                      >
                                                        {brand?.name}
                                                      </li>
                                                    )
                                                  )
                                                ) : (
                                                  <div
                                                    style={{
                                                      padding: "8px",
                                                      color: "#999",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    No results matching "
                                                    {searchTerm1}"
                                                  </div>
                                                )}
                                              </ul>
                                              {typedText &&
                                                !filteredBrands1.includes(
                                                  typedText
                                                ) && (
                                                  <button
                                                    onClick={() => {
                                                      handleAddNewClickDevice();
                                                      if (inputRef5.current) {
                                                        inputRef5.current.focus();
                                                      }
                                                    }}
                                                    style={{
                                                      backgroundColor:
                                                        "#9d3dd3",
                                                    }}
                                                    type="button"
                                                    className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                                  >
                                                    <span className="addbrandbtnrepairticketcstm">
                                                      +
                                                    </span>{" "}
                                                    Add New Brand "{typedText}"
                                                    as a new brand
                                                  </button>
                                                )}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </li>
                                    <li
                                      className="editrepairticketlistshowcustm cus_searchdropdown"
                                      ref={dropdownRef6}
                                    >
                                      <span>Model:</span>
                                      <div
                                        class="pos_relative"
                                        bis_skin_checked="1"
                                        onClick={() =>
                                          setShowListDevice(!showListDevice)
                                        }
                                      >
                                        {selectedDevice || "Select Device"}
                                      </div>

                                      {showListDevice && (
                                        <>
                                          <div className="tagsscustomdiv">
                                            <input
                                              type="text"
                                              placeholder={`Enter a Device`}
                                              value={
                                                selectedDevice ||
                                                searchTermDevice
                                              }
                                              onChange={
                                                handleSearchChangeDevice
                                              }
                                              // onClick={() =>
                                              //   setShowListDevice(!showListDevice)
                                              // }
                                              className="ticketinput inputcustmeditrepairticketcustm"
                                            />
                                            <div className="addselectbox addbrandnamecustmeditticket">
                                              <ul>
                                                {filteredDevice.length > 0 ? (
                                                  filteredDevice?.map(
                                                    (brand) => (
                                                      <li
                                                        key={brand.id}
                                                        onClick={() =>
                                                          handleBrandSelectDevice(
                                                            brand
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className="liuleditbrandrepaircstm"
                                                      >
                                                        {brand?.model}
                                                      </li>
                                                    )
                                                  )
                                                ) : (
                                                  <div
                                                    style={{
                                                      padding: "8px",
                                                      color: "#999",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    No results matching "
                                                    {searchTermDevice}"
                                                  </div>
                                                )}
                                              </ul>
                                              <button
                                                data-toggle="modal"
                                                data-target="#createproductModal"
                                                onClick={() => {
                                                  if (customerData?.id) {
                                                    setShowModal1(true);
                                                    setShowListDevice(false);
                                                  } else {
                                                    Swal.fire({
                                                      title:
                                                        "Please Select Customer First!",
                                                      customClass: {
                                                        container:
                                                          "custom-swal-container",

                                                        confirmButton:
                                                          "custom-swal-confirm-button",
                                                      },
                                                    });
                                                  }
                                                  if (inputRef6.current) {
                                                    inputRef6.current.focus();
                                                  }
                                                }}
                                                style={{
                                                  backgroundColor: "#9d3dd3",
                                                }}
                                                type="button"
                                                className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                              >
                                                <span className="addbrandbtnrepairticketcstm">
                                                  +
                                                </span>{" "}
                                                Add New Device
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </li>
                                    <li
                                      className="editrepairticketlistshowcustm cus_searchdropdown"
                                      ref={dropdownRef2}
                                    >
                                      <span>Serivce:</span>
                                      <div
                                        class="pos_relative"
                                        bis_skin_checked="1"
                                        onClick={() =>
                                          setShowListService(!showListService)
                                        }
                                      >
                                        {selectedService || "Select Service"}
                                      </div>
                                      {showListService && (
                                        <>
                                          <div className="tagsscustomdiv">
                                            <input
                                              type="text"
                                              ref={inputRef2}
                                              placeholder={`Enter a Service`}
                                              value={
                                                selectedService ||
                                                searchTermService
                                              }
                                              onChange={
                                                handleSearchChangeService
                                              }
                                              // onClick={() =>
                                              //   setShowListService(!showListService)
                                              // }
                                              className="ticketinput inputcustmeditrepairticketcustm"
                                            />
                                            <div className="addselectbox addbrandnamecustmeditticket">
                                              <ul>
                                                {filteredService.length > 0 ? (
                                                  filteredService?.map(
                                                    (brand) => (
                                                      <li
                                                        key={brand.id}
                                                        onClick={() =>
                                                          handleServiceSelect(
                                                            brand
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className="liuleditbrandrepaircstm"
                                                      >
                                                        {brand?.name}
                                                      </li>
                                                    )
                                                  )
                                                ) : (
                                                  <div
                                                    style={{
                                                      padding: "8px",
                                                      color: "#999",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    No results matching "
                                                    {searchTermService}"
                                                  </div>
                                                )}
                                              </ul>
                                              {typedText &&
                                                !filteredService.includes(
                                                  typedText
                                                ) && (
                                                  <button
                                                    onClick={() => {
                                                      handleAddNewClickService();
                                                      if (inputRef2.current) {
                                                        inputRef2.current.focus();
                                                      }
                                                    }}
                                                    style={{
                                                      backgroundColor:
                                                        "#9d3dd3",
                                                    }}
                                                    type="button"
                                                    className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                                  >
                                                    <span className="addbrandbtnrepairticketcstm">
                                                      +
                                                    </span>{" "}
                                                    Add New Service "{typedText}
                                                    " as a new service
                                                  </button>
                                                )}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </li>

                                    <li
                                      className="editrepairticketlistshowcustm cus_searchdropdown"
                                      ref={dropdownRef3}
                                    >
                                      <span>Serivce 2:</span>
                                      <div
                                        class="pos_relative"
                                        bis_skin_checked="1"
                                        onClick={() =>
                                          setShowListService1(!showListService1)
                                        }
                                      >
                                        {selectedService1 || "Select Service2"}
                                        {/* <img
                                      src="assets/img/select-arrow.webp"
                                      alt="Handy Icon"
                                      style={{ cursor: "pointer" }}
                                    /> */}
                                      </div>

                                      {showListService1 && (
                                        <>
                                          <div className="tagsscustomdiv">
                                            <input
                                              type="text"
                                              ref={inputRef3}
                                              placeholder={`Enter a Service2`}
                                              value={
                                                selectedService1 ||
                                                searchTermService1
                                              }
                                              onChange={
                                                handleSearchChangeService1
                                              }
                                              className="ticketinput inputcustmeditrepairticketcustm"
                                            />
                                            <div className="addselectbox addbrandnamecustmeditticket">
                                              <ul>
                                                {filteredService1.length > 0 ? (
                                                  filteredService1?.map(
                                                    (brand) => (
                                                      <li
                                                        key={brand.id}
                                                        onClick={() =>
                                                          handleServiceSelect1(
                                                            brand
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className="liuleditbrandrepaircstm"
                                                      >
                                                        {brand?.name}
                                                      </li>
                                                    )
                                                  )
                                                ) : (
                                                  <div
                                                    style={{
                                                      padding: "8px",
                                                      color: "#999",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    No results matching "
                                                    {searchTermService1}"
                                                  </div>
                                                )}
                                              </ul>
                                              {typedText &&
                                                !filteredService1.includes(
                                                  typedText
                                                ) && (
                                                  <button
                                                    onClick={() => {
                                                      handleAddNewClickService1();
                                                      if (inputRef3.current) {
                                                        inputRef3.current.focus();
                                                      }
                                                    }}
                                                    style={{
                                                      backgroundColor:
                                                        "#9d3dd3",
                                                    }}
                                                    type="button"
                                                    className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                                  >
                                                    <span className="addbrandbtnrepairticketcstm">
                                                      +
                                                    </span>{" "}
                                                    Add New Service2 "
                                                    {typedText}" as a new
                                                    service2
                                                  </button>
                                                )}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </li>
                                    <li>
                                      <span>Passcode:</span>
                                      <input
                                        type="password"
                                        value={password}
                                        onChange={(e) =>
                                          setPassword(e.target.value)
                                        }
                                        className="ticketinput"
                                        autoComplete="new-password"
                                      />
                                    </li>
                                    <li>
                                      <span>IMEI/Serial:</span>
                                      <input
                                        type="text"
                                        value={imeiNumber}
                                        onChange={(e) =>
                                          setIMEINumber(e.target.value)
                                        }
                                        className="ticketinput"
                                      />
                                    </li>
                                    <li>
                                      <span>Drivers Licenses:</span>
                                      <input
                                        type="text"
                                        value={driverLiecence}
                                        onChange={(e) =>
                                          setDriverLiecence(e.target.value)
                                        }
                                        className="ticketinput"
                                      />
                                    </li>
                                    <li>
                                      <span>Additional Details:</span>
                                      <input
                                        type="text"
                                        value={description}
                                        onChange={(e) =>
                                          setDescription(e.target.value)
                                        }
                                        className="ticketinput"
                                      />
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="ticket_block_box customerinfo_box">
                              <div className="tkt_box_title">
                                <h4>Customer Info</h4>

                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#addcustomerModal"
                                  onClick={() => setShowModal(true)}
                                >
                                  Edit info
                                </a>
                              </div>
                              <div className="tkt_box_content">
                                <div className="ticket_box_img">
                                  {images ? (
                                    <img
                                      src={images}
                                      className="customerimagescustmeditticket"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src="assets/img/customer-info-img.webp"
                                      alt=""
                                    />
                                  )}
                                </div>
                                <ul className="tkt_box_ul">
                                  <li>
                                    <span>Name:</span>
                                    <b>
                                      {firstName} {lastName}
                                    </b>
                                  </li>
                                  <li>
                                    <span>Phone no:</span>
                                    <b>{formatPhoneNumber(phoneNo)}</b>
                                  </li>
                                  <li>
                                    <span>Email:</span>
                                    <b>{email}</b>
                                  </li>
                                  <li>
                                    <span>Address:</span>
                                    <b>{address}</b>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="ticket_block_box ticketdate_info">
                              <div className="tkt_box_title">
                                <h4>Date</h4>
                              </div>
                              <div className="tkt_box_content">
                                <ul className="tkt_box_ul">
                                  <li>
                                    <span>Date Created:</span>
                                    <b>{formDate}</b>
                                  </li>
                                  <li>
                                    <span>Last Modified:</span>
                                    <b>{formDate1}</b>
                                  </li>
                                  <li>
                                    <span>Due Date:</span>
                                    <input
                                      type="date"
                                      value={formattedDueDate}
                                      onChange={handleDueDateChange}
                                      className="ticketinput"
                                    />
                                  </li>
                                  <li>
                                    <span>Tech Assigned:</span>
                                    <input
                                      type="text"
                                      placeholder="Tech Name"
                                      value={techAssigned}
                                      readOnly
                                      onChange={handleTechAssignedChange}
                                      // onClick={() => setShowListTech(!showListTech)}
                                      className="ticketinput"
                                    />{" "}
                                  </li>
                                  <li>
                                    <span>Sales Person:</span>
                                    <b>{salesPerson}</b>
                                  </li>
                                  <li className="maintitle">Bin</li>
                                  <li>
                                    <span>Location</span>
                                    <input
                                      type="text"
                                      value={binLocation}
                                      onChange={handleBinLocationChange}
                                      className="ticketinput"
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                      <TicketCash
                        status={selectedStatus}
                        status1={selectedStatus1}
                        statusId={selectedStatusId}
                        onload={EditTicket}
                        ticketid={ticketId}
                        customer={customerData}
                        data={location?.state?.data}
                        onloaded={GetAddTicketData}
                        onEdit={EditTicket1}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div style={sidebarStyleone}>
                  <nav className="navbar navbar-expand-lg main-navbar sticky">
                    <div className="sidebar-arrow">
                      <a
                        href="#"
                        data-toggle="sidebar"
                        onClick={(e) => {
                          toggleSidebar();

                          e.preventDefault();
                        }}
                      >
                        <img src="assets/img/sidebar-toggle.webp" alt="" />
                      </a>
                    </div>
                    <div className="header-title mr-auto">
                      <h4>
                        Ticket#{ticketId}{" "}
                        <a
                          className="notes_icon"
                          href="#"
                          data-toggle="modal"
                          data-target="#noteinfoModal"
                        >
                          <img src="assets/img/notes-icon.svg" alt="" />
                        </a>
                      </h4>
                    </div>
                    <SideUl />
                  </nav>
                  <MainSidebar />{" "}
                  <div className="main-content">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="ticket_status_btn">
                          <div className="status_btn">
                            {selectedStatus1 === "Invoiced" ||
                            selectedStatus1 === "Cancelled" ? (
                              <>
                                <div className="dropdown">
                                  <button
                                    style={{
                                      background: selectedColor,
                                    }}
                                    className="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    // id="dropdownMenuButton"
                                    // data-toggle="dropdown"
                                    // aria-haspopup="true"
                                    // aria-expanded="false"
                                  >
                                    Status:{" "}
                                    <span
                                      className="customspanlistsatutus"
                                      style={{
                                        background: selectedColor,
                                      }}
                                    ></span>
                                    {selectedStatus}
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="dropdown">
                                  <button
                                    style={{
                                      background: selectedColor,
                                    }}
                                    className="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    Status:{" "}
                                    <span
                                      className="customspanlistsatutus"
                                      style={{
                                        background: selectedColor,
                                      }}
                                    ></span>
                                    {selectedStatus}
                                  </button>
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                    style={{ width: "100%" }}
                                  >
                                    {Status?.filter(
                                      (status) =>
                                        status?.id !== selectedStatusId
                                    )?.map((item) => (
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        onClick={() => handleStatusSelect(item)}
                                      >
                                        <span
                                          className="customspanlistsatutus"
                                          style={{
                                            background: item?.color,
                                          }}
                                        ></span>{" "}
                                        {item?.name}{" "}
                                      </a>
                                    ))}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="ticket_extra_btns">
                            <button type="text" className="cus_btn email_btn">
                              Email
                            </button>
                            <button type="text" className="cus_btn text_btn">
                              Text
                            </button>
                            <button type="text" className="cus_btn invoice_btn">
                              Print Invoice
                            </button>
                            <button
                              type="text"
                              className="cus_btn label_btn"
                              onClick={GetReciept}
                              // onClick={printTable}
                            >
                              Print Label
                            </button>
                            <button
                              type="text"
                              className="cus_btn servicetkt_btn"
                              data-toggle="modal"
                              data-target="#paymentModal"
                              onClick={(e) => {
                                // setErrorAmount(false);
                                // e.preventDefault();
                                // Refund();
                                setShowModal9(true);
                              }}
                            >
                              Print Service Ticket
                            </button>
                            <button
                              type="text"
                              className="cus_btn thermal_recepit_btn"
                            >
                              Print Thermal Receipt
                            </button>
                          </div>{" "}
                        </div>
                        <div className="ticket_block_content">
                          <div className="ticket_block_box ticketinfo_box">
                            <div className="tkt_box_title">
                              <h4>Ticket Info</h4>
                              <span>#{ticketId}</span>
                            </div>
                            <div className="tkt_box_content">
                              <div className="picturebrandticketdetail">
                                <div className="ticket_box_img">
                                  {images1 ? (
                                    <img
                                      className="imagesdevicelistticket"
                                      src={images1}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src="assets/img/phone_img.webp"
                                      alt=""
                                    />
                                  )}
                                </div>
                                <ul className="uleditrepairticketcustm tkt_box_ul">
                                  <li
                                    className="editrepairticketlistshowcustm cus_searchdropdown"
                                    ref={dropdownRef5}
                                  >
                                    <span>Brand:</span>
                                    <div
                                      class="pos_relative"
                                      bis_skin_checked="1"
                                      onClick={() => setShowList1(!showList1)}
                                    >
                                      {selectedBrand1 || "Select Brand"}
                                      {/* <img
                                      src="assets/img/select-arrow.webp"
                                      alt="Handy Icon"
                                      style={{ cursor: "pointer" }}
                                    /> */}
                                    </div>
                                    {showList1 && (
                                      <>
                                        <div
                                          class="tagsscustomdiv"
                                          bis_skin_checked="1"
                                        >
                                          <input
                                            ref={inputRef5}
                                            type="text"
                                            placeholder={`Enter a Brand`}
                                            value={
                                              selectedBrand1 || searchTerm1
                                            }
                                            onChange={handleSearchChange1}
                                            // onClick={() =>
                                            //   setShowList1(!showList1)
                                            // }
                                            className="ticketinput inputcustmeditrepairticketcustm"
                                          />
                                          <div className="addselectbox addbrandnamecustmeditticket">
                                            <ul>
                                              {filteredBrands1.length > 0 ? (
                                                filteredBrands1?.map(
                                                  (brand) => (
                                                    <li
                                                      key={brand.id}
                                                      onClick={() =>
                                                        handleBrandSelect1(
                                                          brand
                                                        )
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      className="liuleditbrandrepaircstm"
                                                    >
                                                      {brand?.name}
                                                    </li>
                                                  )
                                                )
                                              ) : (
                                                <div
                                                  style={{
                                                    padding: "8px",
                                                    color: "#999",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  No results matching "
                                                  {searchTerm1}"
                                                </div>
                                              )}
                                            </ul>
                                            {typedText &&
                                              !filteredBrands1.includes(
                                                typedText
                                              ) && (
                                                <button
                                                  onClick={() => {
                                                    handleAddNewClickDevice();
                                                    if (inputRef5.current) {
                                                      inputRef5.current.focus();
                                                    }
                                                  }}
                                                  style={{
                                                    backgroundColor: "#9d3dd3",
                                                  }}
                                                  type="button"
                                                  className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                                >
                                                  <span className="addbrandbtnrepairticketcstm">
                                                    +
                                                  </span>{" "}
                                                  Add New Brand "{typedText}" as
                                                  a new brand
                                                </button>
                                              )}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </li>
                                  <li
                                    className="editrepairticketlistshowcustm cus_searchdropdown"
                                    ref={dropdownRef6}
                                  >
                                    <span>Model:</span>
                                    <div
                                      class="pos_relative"
                                      bis_skin_checked="1"
                                      onClick={() =>
                                        setShowListDevice(!showListDevice)
                                      }
                                    >
                                      {selectedDevice || "Select Device"}
                                    </div>

                                    {showListDevice && (
                                      <>
                                        <div className="tagsscustomdiv">
                                          <input
                                            type="text"
                                            placeholder={`Enter a Device`}
                                            value={
                                              selectedDevice || searchTermDevice
                                            }
                                            onChange={handleSearchChangeDevice}
                                            // onClick={() =>
                                            //   setShowListDevice(!showListDevice)
                                            // }
                                            className="ticketinput inputcustmeditrepairticketcustm"
                                          />
                                          <div className="addselectbox addbrandnamecustmeditticket">
                                            <ul>
                                              {filteredDevice.length > 0 ? (
                                                filteredDevice?.map((brand) => (
                                                  <li
                                                    key={brand.id}
                                                    onClick={() =>
                                                      handleBrandSelectDevice(
                                                        brand
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    className="liuleditbrandrepaircstm"
                                                  >
                                                    {brand?.model}
                                                  </li>
                                                ))
                                              ) : (
                                                <div
                                                  style={{
                                                    padding: "8px",
                                                    color: "#999",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  No results matching "
                                                  {searchTermDevice}"
                                                </div>
                                              )}
                                            </ul>
                                            <button
                                              data-toggle="modal"
                                              data-target="#createproductModal"
                                              onClick={() => {
                                                if (customerData?.id) {
                                                  setShowModal1(true);
                                                  setShowListDevice(false);
                                                } else {
                                                  Swal.fire({
                                                    title:
                                                      "Please Select Customer First!",
                                                    customClass: {
                                                      container:
                                                        "custom-swal-container",

                                                      confirmButton:
                                                        "custom-swal-confirm-button",
                                                    },
                                                  });
                                                }
                                                if (inputRef6.current) {
                                                  inputRef6.current.focus();
                                                }
                                              }}
                                              style={{
                                                backgroundColor: "#9d3dd3",
                                              }}
                                              type="button"
                                              className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                            >
                                              <span className="addbrandbtnrepairticketcstm">
                                                +
                                              </span>{" "}
                                              Add New Device
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </li>
                                  <li
                                    className="editrepairticketlistshowcustm cus_searchdropdown"
                                    ref={dropdownRef2}
                                  >
                                    <span>Serivce:</span>
                                    <div
                                      class="pos_relative"
                                      bis_skin_checked="1"
                                      onClick={() =>
                                        setShowListService(!showListService)
                                      }
                                    >
                                      {selectedService || "Select Service"}
                                    </div>
                                    {showListService && (
                                      <>
                                        <div className="tagsscustomdiv">
                                          <input
                                            type="text"
                                            ref={inputRef2}
                                            placeholder={`Enter a Service`}
                                            value={
                                              selectedService ||
                                              searchTermService
                                            }
                                            onChange={handleSearchChangeService}
                                            // onClick={() =>
                                            //   setShowListService(!showListService)
                                            // }
                                            className="ticketinput inputcustmeditrepairticketcustm"
                                          />
                                          <div className="addselectbox addbrandnamecustmeditticket">
                                            <ul>
                                              {filteredService.length > 0 ? (
                                                filteredService?.map(
                                                  (brand) => (
                                                    <li
                                                      key={brand.id}
                                                      onClick={() =>
                                                        handleServiceSelect(
                                                          brand
                                                        )
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      className="liuleditbrandrepaircstm"
                                                    >
                                                      {brand?.name}
                                                    </li>
                                                  )
                                                )
                                              ) : (
                                                <div
                                                  style={{
                                                    padding: "8px",
                                                    color: "#999",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  No results matching "
                                                  {searchTermService}"
                                                </div>
                                              )}
                                            </ul>
                                            {typedText &&
                                              !filteredService.includes(
                                                typedText
                                              ) && (
                                                <button
                                                  onClick={() => {
                                                    handleAddNewClickService();
                                                    if (inputRef2.current) {
                                                      inputRef2.current.focus();
                                                    }
                                                  }}
                                                  style={{
                                                    backgroundColor: "#9d3dd3",
                                                  }}
                                                  type="button"
                                                  className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                                >
                                                  <span className="addbrandbtnrepairticketcstm">
                                                    +
                                                  </span>{" "}
                                                  Add New Service "{typedText}"
                                                  as a new service
                                                </button>
                                              )}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </li>

                                  <li
                                    className="editrepairticketlistshowcustm cus_searchdropdown"
                                    ref={dropdownRef3}
                                  >
                                    <span>Serivce 2:</span>
                                    <div
                                      class="pos_relative"
                                      bis_skin_checked="1"
                                      onClick={() =>
                                        setShowListService1(!showListService1)
                                      }
                                    >
                                      {selectedService1 || "Select Service2"}
                                      {/* <img
                                      src="assets/img/select-arrow.webp"
                                      alt="Handy Icon"
                                      style={{ cursor: "pointer" }}
                                    /> */}
                                    </div>

                                    {showListService1 && (
                                      <>
                                        <div className="tagsscustomdiv">
                                          <input
                                            type="text"
                                            ref={inputRef3}
                                            placeholder={`Enter a Service2`}
                                            value={
                                              selectedService1 ||
                                              searchTermService1
                                            }
                                            onChange={
                                              handleSearchChangeService1
                                            }
                                            className="ticketinput inputcustmeditrepairticketcustm"
                                          />
                                          <div className="addselectbox addbrandnamecustmeditticket">
                                            <ul>
                                              {filteredService1.length > 0 ? (
                                                filteredService1?.map(
                                                  (brand) => (
                                                    <li
                                                      key={brand.id}
                                                      onClick={() =>
                                                        handleServiceSelect1(
                                                          brand
                                                        )
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      className="liuleditbrandrepaircstm"
                                                    >
                                                      {brand?.name}
                                                    </li>
                                                  )
                                                )
                                              ) : (
                                                <div
                                                  style={{
                                                    padding: "8px",
                                                    color: "#999",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  No results matching "
                                                  {searchTermService1}"
                                                </div>
                                              )}
                                            </ul>
                                            {typedText &&
                                              !filteredService1.includes(
                                                typedText
                                              ) && (
                                                <button
                                                  onClick={() => {
                                                    handleAddNewClickService1();
                                                    if (inputRef3.current) {
                                                      inputRef3.current.focus();
                                                    }
                                                  }}
                                                  style={{
                                                    backgroundColor: "#9d3dd3",
                                                  }}
                                                  type="button"
                                                  className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                                >
                                                  <span className="addbrandbtnrepairticketcstm">
                                                    +
                                                  </span>{" "}
                                                  Add New Service2 "{typedText}"
                                                  as a new service2
                                                </button>
                                              )}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </li>
                                  <li>
                                    <span>Passcode:</span>
                                    <input
                                      type="password"
                                      value={password}
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                      className="ticketinput"
                                      autoComplete="new-password"
                                    />
                                  </li>
                                  <li>
                                    <span>IMEI/Serial:</span>
                                    <input
                                      type="text"
                                      value={imeiNumber}
                                      onChange={(e) =>
                                        setIMEINumber(e.target.value)
                                      }
                                      className="ticketinput"
                                    />
                                  </li>
                                  <li>
                                    <span>Drivers Licenses:</span>
                                    <input
                                      type="text"
                                      value={driverLiecence}
                                      onChange={(e) =>
                                        setDriverLiecence(e.target.value)
                                      }
                                      className="ticketinput"
                                    />
                                  </li>
                                  <li>
                                    <span>Additional Details:</span>
                                    <input
                                      type="text"
                                      value={description}
                                      onChange={(e) =>
                                        setDescription(e.target.value)
                                      }
                                      className="ticketinput"
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="ticket_block_box customerinfo_box">
                            <div className="tkt_box_title">
                              <h4>Customer Info</h4>
                              {adminState === "0" ? (
                                <a
                                  href="#"
                                  // data-toggle="modal"
                                  // data-target="#addcustomerModal"
                                  // onClick={() => setShowModal(true)}
                                >
                                  Edit info
                                </a>
                              ) : (
                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#addcustomerModal"
                                  onClick={() => setShowModal(true)}
                                >
                                  Edit info
                                </a>
                              )}
                            </div>
                            <div className="tkt_box_content">
                              <div className="ticket_box_img">
                                {images ? (
                                  <img
                                    src={images}
                                    className="customerimagescustmeditticket"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src="assets/img/customer-info-img.webp"
                                    alt=""
                                  />
                                )}
                              </div>
                              <ul className="tkt_box_ul">
                                <li>
                                  <span>Name:</span>
                                  <b>
                                    {firstName} {lastName}
                                  </b>
                                </li>
                                <li>
                                  <span>Phone no:</span>
                                  <b>{formatPhoneNumber(phoneNo)}</b>
                                </li>
                                <li>
                                  <span>Email:</span>
                                  <b>{email}</b>
                                </li>
                                <li>
                                  <span>Address:</span>
                                  <b>{address}</b>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="ticket_block_box ticketdate_info">
                            <div className="tkt_box_title">
                              <h4>Date</h4>
                            </div>
                            <div className="tkt_box_content">
                              <ul className="tkt_box_ul">
                                <li>
                                  <span>Date Created:</span>
                                  <b>{formDate}</b>
                                </li>
                                <li>
                                  <span>Last Modified:</span>
                                  <b>{formDate1}</b>
                                </li>
                                <li>
                                  <span>Due Date:</span>
                                  <input
                                    type="date"
                                    value={formattedDueDate}
                                    onChange={handleDueDateChange}
                                    className="ticketinput"
                                  />
                                </li>
                                <li>
                                  <span>Tech Assigned:</span>
                                  <input
                                    type="text"
                                    placeholder="Tech Name"
                                    value={techAssigned}
                                    // value={techAssigned}
                                    readOnly
                                    onChange={handleTechAssignedChange}
                                    // onClick={() => setShowListTech(!showListTech)}
                                    className="ticketinput"
                                  />{" "}
                                </li>
                                <li>
                                  <span>Sales Person:</span>
                                  <b>{salesPerson}</b>
                                </li>
                                <li className="maintitle">Bin</li>
                                <li>
                                  <span>Location</span>
                                  <input
                                    type="text"
                                    value={binLocation}
                                    onChange={handleBinLocationChange}
                                    className="ticketinput"
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                    <TicketCash
                      status={selectedStatus}
                      status1={selectedStatus1}
                      statusId={selectedStatusId}
                      onload={EditTicket}
                      ticketid={ticketId}
                      customer={customerData}
                      data={location?.state?.data}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {messageData ? (
                <div className="app-container-trial bannercssmsg">
                  <BannerMessage />
                </div>
              ) : null}
              <div style={sidebarStyleone}>
                <nav className="navbar navbar-expand-lg main-navbar sticky">
                  <div className="sidebar-arrow">
                    <a
                      href="#"
                      data-toggle="sidebar"
                      onClick={(e) => {
                        toggleSidebar();

                        e.preventDefault();
                      }}
                    >
                      <img src="assets/img/sidebar-toggle.webp" alt="" />
                    </a>
                  </div>
                  <div className="header-title mr-auto">
                    <h4>
                      Ticket#{ticketId}{" "}
                      <a
                        className="notes_icon"
                        href="#"
                        data-toggle="modal"
                        data-target="#noteinfoModal"
                      >
                        <img src="assets/img/notes-icon.svg" alt="" />
                      </a>
                    </h4>
                  </div>
                  <SideUl />
                </nav>
                <MainSidebar />{" "}
                <div className="main-content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="ticket_status_btn">
                        <div className="status_btn">
                          {selectedStatus1 === "Invoiced" ||
                          selectedStatus1 === "Cancelled" ? (
                            <>
                              <div className="dropdown">
                                <button
                                  style={{
                                    background: selectedColor,
                                  }}
                                  className="btn btn-secondary dropdown-toggle"
                                  type="button"
                                  // id="dropdownMenuButton"
                                  // data-toggle="dropdown"
                                  // aria-haspopup="true"
                                  // aria-expanded="false"
                                >
                                  Status:{" "}
                                  <span
                                    className="customspanlistsatutus"
                                    style={{
                                      background: selectedColor,
                                    }}
                                  ></span>
                                  {selectedStatus}
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="dropdown">
                                <button
                                  style={{
                                    background: selectedColor,
                                  }}
                                  className="btn btn-secondary dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  Status:{" "}
                                  <span
                                    className="customspanlistsatutus"
                                    style={{
                                      background: selectedColor,
                                    }}
                                  ></span>
                                  {selectedStatus}
                                </button>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                  style={{ width: "100%" }}
                                >
                                  {Status?.filter(
                                    (status) => status?.id !== selectedStatusId
                                  )?.map((item) => (
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={() => handleStatusSelect(item)}
                                    >
                                      <span
                                        className="customspanlistsatutus"
                                        style={{
                                          background: item?.color,
                                        }}
                                      ></span>{" "}
                                      {item?.name}{" "}
                                    </a>
                                  ))}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="ticket_extra_btns">
                          <button type="text" className="cus_btn email_btn">
                            Email
                          </button>
                          <button type="text" className="cus_btn text_btn">
                            Text
                          </button>
                          <button type="text" className="cus_btn invoice_btn">
                            Print Invoice
                          </button>
                          <button
                            type="text"
                            className="cus_btn label_btn"
                            // onClick={printTable}
                            onClick={GetReciept}
                          >
                            Print Label
                          </button>
                          <button
                            type="text"
                            className="cus_btn servicetkt_btn"
                            data-toggle="modal"
                            data-target="#paymentModal"
                            onClick={(e) => {
                              // setErrorAmount(false);
                              // e.preventDefault();
                              // Refund();
                              setShowModal9(true);
                            }}
                          >
                            Print Service Ticket
                          </button>
                          <button
                            type="text"
                            className="cus_btn thermal_recepit_btn"
                          >
                            Print Thermal Receipt
                          </button>
                        </div>{" "}
                      </div>
                      <div className="ticket_block_content">
                        <div className="ticket_block_box ticketinfo_box">
                          <div className="tkt_box_title">
                            <h4>Ticket Info</h4>
                            <span>#{ticketId}</span>
                          </div>
                          <div className="tkt_box_content">
                            <div className="picturebrandticketdetail">
                              <div className="ticket_box_img">
                                {images1 ? (
                                  <img
                                    className="imagesdevicelistticket"
                                    src={images1}
                                    alt=""
                                  />
                                ) : (
                                  <img src="assets/img/phone_img.webp" alt="" />
                                )}
                              </div>
                              <ul className="uleditrepairticketcustm tkt_box_ul">
                                <li
                                  className="editrepairticketlistshowcustm cus_searchdropdown"
                                  ref={dropdownRef5}
                                >
                                  <span>Brand:</span>
                                  <div
                                    class="pos_relative"
                                    bis_skin_checked="1"
                                    onClick={() => setShowList1(!showList1)}
                                  >
                                    {selectedBrand1 || "Select Brand"}
                                    {/* <img
                                      src="assets/img/select-arrow.webp"
                                      alt="Handy Icon"
                                      style={{ cursor: "pointer" }}
                                    /> */}
                                  </div>
                                  {showList1 && (
                                    <>
                                      <div
                                        class="tagsscustomdiv"
                                        bis_skin_checked="1"
                                      >
                                        <input
                                          ref={inputRef5}
                                          type="text"
                                          placeholder={`Enter a Brand`}
                                          value={selectedBrand1 || searchTerm1}
                                          onChange={handleSearchChange1}
                                          // onClick={() =>
                                          //   setShowList1(!showList1)
                                          // }
                                          className="ticketinput inputcustmeditrepairticketcustm"
                                        />
                                        <div className="addselectbox addbrandnamecustmeditticket">
                                          <ul>
                                            {filteredBrands1.length > 0 ? (
                                              filteredBrands1?.map((brand) => (
                                                <li
                                                  key={brand.id}
                                                  onClick={() =>
                                                    handleBrandSelect1(brand)
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  className="liuleditbrandrepaircstm"
                                                >
                                                  {brand?.name}
                                                </li>
                                              ))
                                            ) : (
                                              <div
                                                style={{
                                                  padding: "8px",
                                                  color: "#999",
                                                  textAlign: "center",
                                                }}
                                              >
                                                No results matching "
                                                {searchTerm1}"
                                              </div>
                                            )}
                                          </ul>
                                          {typedText &&
                                            !filteredBrands1.includes(
                                              typedText
                                            ) && (
                                              <button
                                                onClick={() => {
                                                  handleAddNewClickDevice();
                                                  if (inputRef5.current) {
                                                    inputRef5.current.focus();
                                                  }
                                                }}
                                                style={{
                                                  backgroundColor: "#9d3dd3",
                                                }}
                                                type="button"
                                                className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                              >
                                                <span className="addbrandbtnrepairticketcstm">
                                                  +
                                                </span>{" "}
                                                Add New Brand "{typedText}" as a
                                                new brand
                                              </button>
                                            )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </li>
                                <li
                                  className="editrepairticketlistshowcustm cus_searchdropdown"
                                  ref={dropdownRef6}
                                >
                                  <span>Model:</span>
                                  <div
                                    class="pos_relative"
                                    bis_skin_checked="1"
                                    onClick={() =>
                                      setShowListDevice(!showListDevice)
                                    }
                                  >
                                    {selectedDevice || "Select Device"}
                                  </div>

                                  {showListDevice && (
                                    <>
                                      <div className="tagsscustomdiv">
                                        <input
                                          type="text"
                                          placeholder={`Enter a Device`}
                                          value={
                                            selectedDevice || searchTermDevice
                                          }
                                          onChange={handleSearchChangeDevice}
                                          // onClick={() =>
                                          //   setShowListDevice(!showListDevice)
                                          // }
                                          className="ticketinput inputcustmeditrepairticketcustm"
                                        />
                                        <div className="addselectbox addbrandnamecustmeditticket">
                                          <ul>
                                            {filteredDevice.length > 0 ? (
                                              filteredDevice?.map((brand) => (
                                                <li
                                                  key={brand.id}
                                                  onClick={() =>
                                                    handleBrandSelectDevice(
                                                      brand
                                                    )
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  className="liuleditbrandrepaircstm"
                                                >
                                                  {brand?.model}
                                                </li>
                                              ))
                                            ) : (
                                              <div
                                                style={{
                                                  padding: "8px",
                                                  color: "#999",
                                                  textAlign: "center",
                                                }}
                                              >
                                                No results matching "
                                                {searchTermDevice}"
                                              </div>
                                            )}
                                          </ul>
                                          <button
                                            data-toggle="modal"
                                            data-target="#createproductModal"
                                            onClick={() => {
                                              if (customerData?.id) {
                                                setShowModal1(true);
                                                setShowListDevice(false);
                                              } else {
                                                Swal.fire({
                                                  title:
                                                    "Please Select Customer First!",
                                                  customClass: {
                                                    container:
                                                      "custom-swal-container",

                                                    confirmButton:
                                                      "custom-swal-confirm-button",
                                                  },
                                                });
                                              }
                                              if (inputRef6.current) {
                                                inputRef6.current.focus();
                                              }
                                            }}
                                            style={{
                                              backgroundColor: "#9d3dd3",
                                            }}
                                            type="button"
                                            className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                          >
                                            <span className="addbrandbtnrepairticketcstm">
                                              +
                                            </span>{" "}
                                            Add New Device
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </li>
                                <li
                                  className="editrepairticketlistshowcustm cus_searchdropdown"
                                  ref={dropdownRef2}
                                >
                                  <span>Serivce:</span>
                                  <div
                                    class="pos_relative"
                                    bis_skin_checked="1"
                                    onClick={() =>
                                      setShowListService(!showListService)
                                    }
                                  >
                                    {selectedService || "Select Service"}
                                  </div>
                                  {showListService && (
                                    <>
                                      <div className="tagsscustomdiv">
                                        <input
                                          type="text"
                                          ref={inputRef2}
                                          placeholder={`Enter a Service`}
                                          value={
                                            selectedService || searchTermService
                                          }
                                          onChange={handleSearchChangeService}
                                          // onClick={() =>
                                          //   setShowListService(!showListService)
                                          // }
                                          className="ticketinput inputcustmeditrepairticketcustm"
                                        />
                                        <div className="addselectbox addbrandnamecustmeditticket">
                                          <ul>
                                            {filteredService.length > 0 ? (
                                              filteredService?.map((brand) => (
                                                <li
                                                  key={brand.id}
                                                  onClick={() =>
                                                    handleServiceSelect(brand)
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  className="liuleditbrandrepaircstm"
                                                >
                                                  {brand?.name}
                                                </li>
                                              ))
                                            ) : (
                                              <div
                                                style={{
                                                  padding: "8px",
                                                  color: "#999",
                                                  textAlign: "center",
                                                }}
                                              >
                                                No results matching "
                                                {searchTermService}"
                                              </div>
                                            )}
                                          </ul>
                                          {typedText &&
                                            !filteredService.includes(
                                              typedText
                                            ) && (
                                              <button
                                                onClick={() => {
                                                  handleAddNewClickService();
                                                  if (inputRef2.current) {
                                                    inputRef2.current.focus();
                                                  }
                                                }}
                                                style={{
                                                  backgroundColor: "#9d3dd3",
                                                }}
                                                type="button"
                                                className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                              >
                                                <span className="addbrandbtnrepairticketcstm">
                                                  +
                                                </span>{" "}
                                                Add New Service "{typedText}" as
                                                a new service
                                              </button>
                                            )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </li>

                                <li
                                  className="editrepairticketlistshowcustm cus_searchdropdown"
                                  ref={dropdownRef3}
                                >
                                  <span>Serivce 2:</span>
                                  <div
                                    class="pos_relative"
                                    bis_skin_checked="1"
                                    onClick={() =>
                                      setShowListService1(!showListService1)
                                    }
                                  >
                                    {selectedService1 || "Select Service2"}
                                    {/* <img
                                      src="assets/img/select-arrow.webp"
                                      alt="Handy Icon"
                                      style={{ cursor: "pointer" }}
                                    /> */}
                                  </div>

                                  {showListService1 && (
                                    <>
                                      <div className="tagsscustomdiv">
                                        <input
                                          type="text"
                                          ref={inputRef3}
                                          placeholder={`Enter a Service2`}
                                          value={
                                            selectedService1 ||
                                            searchTermService1
                                          }
                                          onChange={handleSearchChangeService1}
                                          className="ticketinput inputcustmeditrepairticketcustm"
                                        />
                                        <div className="addselectbox addbrandnamecustmeditticket">
                                          <ul>
                                            {filteredService1.length > 0 ? (
                                              filteredService1?.map((brand) => (
                                                <li
                                                  key={brand.id}
                                                  onClick={() =>
                                                    handleServiceSelect1(brand)
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  className="liuleditbrandrepaircstm"
                                                >
                                                  {brand?.name}
                                                </li>
                                              ))
                                            ) : (
                                              <div
                                                style={{
                                                  padding: "8px",
                                                  color: "#999",
                                                  textAlign: "center",
                                                }}
                                              >
                                                No results matching "
                                                {searchTermService1}"
                                              </div>
                                            )}
                                          </ul>
                                          {typedText &&
                                            !filteredService1.includes(
                                              typedText
                                            ) && (
                                              <button
                                                onClick={() => {
                                                  handleAddNewClickService1();
                                                  if (inputRef3.current) {
                                                    inputRef3.current.focus();
                                                  }
                                                }}
                                                style={{
                                                  backgroundColor: "#9d3dd3",
                                                }}
                                                type="button"
                                                className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                              >
                                                <span className="addbrandbtnrepairticketcstm">
                                                  +
                                                </span>{" "}
                                                Add New Service2 "{typedText}"
                                                as a new service2
                                              </button>
                                            )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </li>
                                <li>
                                  <span>Passcode:</span>
                                  <input
                                    type="password"
                                    value={password}
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                    className="ticketinput"
                                    autoComplete="new-password"
                                  />
                                </li>
                                <li>
                                  <span>IMEI/Serial:</span>
                                  <input
                                    type="text"
                                    value={imeiNumber}
                                    onChange={(e) =>
                                      setIMEINumber(e.target.value)
                                    }
                                    className="ticketinput"
                                  />
                                </li>
                                <li>
                                  <span>Drivers Licenses:</span>
                                  <input
                                    type="text"
                                    value={driverLiecence}
                                    onChange={(e) =>
                                      setDriverLiecence(e.target.value)
                                    }
                                    className="ticketinput"
                                  />
                                </li>
                                <li>
                                  <span>Additional Details:</span>
                                  <input
                                    type="text"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                    className="ticketinput"
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="ticket_block_box customerinfo_box">
                          <div className="tkt_box_title">
                            <h4>Customer Info</h4>
                            {adminState === "0" ? (
                              <a
                                href="#"
                                // data-toggle="modal"
                                // data-target="#addcustomerModal"
                                // onClick={() => setShowModal(true)}
                              >
                                Edit info
                              </a>
                            ) : (
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#addcustomerModal"
                                onClick={() => setShowModal(true)}
                              >
                                Edit info
                              </a>
                            )}
                          </div>
                          <div className="tkt_box_content">
                            <div className="ticket_box_img">
                              {images ? (
                                <img
                                  src={images}
                                  className="customerimagescustmeditticket"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src="assets/img/customer-info-img.webp"
                                  alt=""
                                />
                              )}
                            </div>
                            <ul className="tkt_box_ul">
                              <li>
                                <span>Name:</span>
                                <b>
                                  {firstName} {lastName}
                                </b>
                              </li>
                              <li>
                                <span>Phone no:</span>
                                <b>{formatPhoneNumber(phoneNo)}</b>
                              </li>
                              <li>
                                <span>Email:</span>
                                <b>{email}</b>
                              </li>
                              <li>
                                <span>Address:</span>
                                <b>{address}</b>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="ticket_block_box ticketdate_info">
                          <div className="tkt_box_title">
                            <h4>Date</h4>
                          </div>
                          <div className="tkt_box_content">
                            <ul className="tkt_box_ul">
                              <li>
                                <span>Date Created:</span>
                                <b>{formDate}</b>
                              </li>
                              <li>
                                <span>Last Modified:</span>
                                <b>{formDate1}</b>
                              </li>
                              <li>
                                <span>Due Date:</span>
                                <input
                                  type="date"
                                  value={formattedDueDate}
                                  onChange={handleDueDateChange}
                                  className="ticketinput"
                                />
                                {/* <img
                            src="assets/img/ticket-calendar-icon.webp"
                            alt=""
                          /> */}
                              </li>
                              <li>
                                <span>Tech Assigned:</span>
                                <input
                                  type="text"
                                  placeholder="Tech Name"
                                  value={techAssigned}
                                  // value={techAssigned}
                                  readOnly
                                  onChange={handleTechAssignedChange}
                                  // onClick={() => setShowListTech(!showListTech)}
                                  className="ticketinput"
                                />{" "}
                              </li>
                              <li>
                                <span>Sales Person:</span>
                                <b>{salesPerson}</b>
                              </li>
                              <li className="maintitle">Bin</li>
                              <li>
                                <span>Location</span>
                                <input
                                  type="text"
                                  value={binLocation}
                                  onChange={handleBinLocationChange}
                                  className="ticketinput"
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  <TicketCash
                    status={selectedStatus}
                    status1={selectedStatus1}
                    onload={EditTicket}
                    ticketid={ticketId}
                    customer={customerData}
                    statusId={selectedStatusId}
                    data={location?.state?.data}
                    onloaded={GetAddTicketData}
                    onEdit={EditTicket1}
                  />
                </div>
              </div>
            </>
          )}

          <footer className="main-footer" style={{ display: "none" }}>
            <div className="footer-left">
              <a href="#">Toolbox</a>
            </div>
            <div className="footer-right"></div>
          </footer>
        </div>

        {showModal1 === true ? (
          <div
            className="modal fade common_modal createproduct_modal"
            id="createproductModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="createproductModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="createproductModalLabel">
                    Create New Device
                  </h5>

                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => {
                      setShowModal1(false);
                      // props.onpress(false);
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    <span className="close_icon"></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="custom_form createproduct_form">
                    <form action="" method="" className="cus_form">
                      <div className="form_row product_radio_row">
                        <div className="form_column col_full">
                          <div className="form-group"></div>
                        </div>
                      </div>

                      <div className="product_row">
                        <div className="product_col100 cus_prod_col">
                          <h4>
                            General information<span>*</span>
                          </h4>
                          <p>
                            To start selling, all you need is a name and a
                            price.
                          </p>
                          <div className="form_row">
                            <div className="form_column col_full">
                              <div className="form-group">
                                <label className="cus_label">IMEI/Serial</label>
                                <div className="formfield field_wd100">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="serialno"
                                    placeholder="Enter IMEI/Serial"
                                    value={imeiNumber}
                                    onChange={(e) =>
                                      setIMEINumber(e.target.value.trim())
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form_column col_full">
                              <div className="form-group">
                                <label className="cus_label label_red">
                                  Model Name<span>*</span>
                                </label>

                                <div
                                  className="formfield field_wd100  add_cust_field selectcustomerboxcustmlist"
                                  //ref={dropdownRef1}
                                >
                                  <div className="tagsscustomdiv">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      // onClick={() => setShowList(!showList)}
                                    >
                                      <input
                                        type="text"
                                        className="form-control customsearchinputboxsetting  "
                                        name="firstName"
                                        placeholder="Enter Model Name"
                                        value={model}
                                        onChange={(e) =>
                                          setModel(e.target.value)
                                        }
                                        //onClick={() => setShowList(!showList)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form_column col_full">
                              <div className="form-group">
                                <label className="cus_label">
                                  Description (optional)
                                </label>
                                <div className="formfield field_wd100">
                                  <textarea
                                    className="form-control"
                                    name="description"
                                    placeholder="Enter Description"
                                    value={description1}
                                    onChange={handleDescriptionChange1}
                                  ></textarea>
                                </div>{" "}
                                {errormsg && (
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "red",
                                      marginLeft: "110px",
                                    }}
                                  >
                                    {errormsg}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="product_col100 cus_prod_col uploadimagedevicescustm">
                              <div className="uploadimgesrepairticketdevice">
                                <h4>Thumbnail</h4>
                                <p>Upload image of product</p>
                              </div>
                              <div className="form_row">
                                <div className="form_column col_full">
                                  <div className="form-group">
                                    <div className="thumbnail_img">
                                      {images1 ? (
                                        <i
                                          className="fa-regular fa-circle-xmark deletimgcustm1"
                                          onClick={() => HandleDelete1()}
                                        ></i>
                                      ) : null}
                                      {images1 ? (
                                        <>
                                          <img
                                            src={images1}
                                            className="imgescstmdisply"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <input
                                            type="file"
                                            className="form-control"
                                            name="prod_thumnail"
                                            accept="image/jpeg, image/jpg, image/png"
                                            onChange={handleImages1}
                                          />
                                          <div className="file_upload">
                                            <img
                                              src="assets/img/thumbnail_img.webp"
                                              alt=""
                                            />
                                            <div className="file_txt">
                                              Drop your file here, or{" "}
                                              <span>click to browse</span>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>{" "}
                                    {imageMsg && (
                                      <span
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                        }}
                                      >
                                        {imageMsg}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>

                      <div className="clearfix"></div>
                    </form>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    onClick={() => {
                      setShowModal1(false);
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));

                      //props.onpress(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={() => OnSubmit()}
                  >
                    Save Device
                  </button>
                </div>
              </div>
            </div>
            {/* <TicketDeviceModal
            onpress={onpress}
            onload={GetCustomerDevice}
            id={selectedCustomerId}
            brandid={selectedBrandId1}
          /> */}
          </div>
        ) : null}

        {showModal === true ? (
          <div
            className="modal fade common_modal addcustomer_modal"
            id="addcustomerModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="addcustomerModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addcustomerModalLabel">
                    Edit Customer
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      handleModalClose();
                    }}
                  >
                    <span className="close_icon" />
                  </button>
                </div>
                <div className="modal-body">
                  <div className="custom_form customer_form">
                    <form action="" method="" className="cus_form">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="upload_files">
                              {images ? (
                                <img
                                  className="uploaded_img"
                                  src={images}
                                  alt=""
                                />
                              ) : (
                                <img
                                  style={{ display: "none" }}
                                  className="uploaded_img"
                                  src=""
                                  alt=""
                                />
                              )}
                              <div className="file_upload">
                                <input
                                  type="file"
                                  name="files"
                                  accept="image/jpeg, image/jpg, image/png"
                                  onChange={handleImages}
                                />
                                <div className="fileedit_icon">
                                  <img
                                    src="assets/img/file-edit-icon.webp"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="filetrash_icon deleteimgcust">
                                <a
                                  href="#"
                                  onClick={() => {
                                    if (images) {
                                      HandleDelete();
                                    }
                                  }}
                                >
                                  <img
                                    src="assets/img/file-trash-icon.webp"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                            {imageMsg && (
                              <span className="customvalidationdesignlogin">
                                {imageMsg}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h5>Customer Info</h5>
                        </div>

                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="first_name"
                                placeholder="First Name"
                                value={firstName}
                                onChange={(e) => {
                                  setFirstName(e.target.value.trim());
                                  if (error.firstName) {
                                    setError({ ...error, firstName: "" });
                                  }
                                }}
                              />
                              {error.firstName && (
                                <span className="customvalidationdesignlogin">
                                  {error.firstName}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 lastnamecust">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="last_name"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => {
                                  setLastName(e.target.value.trim());
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value.trim());
                                  if (error.email) {
                                    setError({ ...error, email: "" });
                                  }
                                }}
                              />
                              {error.email && (
                                <span className="customvalidationdesignlogin">
                                  {error.email}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 lastnamecust">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <PhoneInput
                                className="form-control"
                                placeholder="Enter phone number"
                                international
                                countryCallingCodeEditable={false}
                                //limitMaxLength={10}
                                value={phoneNo}
                                onChange={(val) => {
                                  handlePhoneChange(val);
                                  // if (error.phoneNo) {
                                  //   setError({ ...error, phoneNo: "" });
                                  // }
                                  setValidState(true);
                                }}
                                //   onKeyup={setValidState(true)}
                                defaultCountry={countrycode.country_code}
                                //defaultCountry="IN"
                                // defaultCountry={country.countryCode}
                                // error={
                                //   value
                                //     ? isValidPhoneNumber(value)
                                //       ? undefined
                                //       : "Invalid phone number"
                                //     : "Phone number required"
                                // }
                              />

                              {validstate === true ? (
                                <span className="customvalidationdesignlogin">
                                  {phoneNo && isValidPhoneNumber(phoneNo)
                                    ? ""
                                    : "Invalid phone number"}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h5>Address</h5>
                        </div>
                        <div className="col-md-12">
                          <div className="form_column col_full">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                name="address"
                                placeholder="Street Address"
                                value={address}
                                onChange={(e) => {
                                  setAddress(e.target.value);
                                }}
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="country"
                                placeholder="Country"
                                value={country}
                                onChange={(e) => {
                                  setCountry(e.target.value.trim());
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                placeholder="City"
                                value={city}
                                onChange={(e) => {
                                  SetCity(e.target.value.trim());
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="state"
                                placeholder="State / Province"
                                value={state}
                                onChange={(e) => {
                                  setState(e.target.value.trim());
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="zipcode"
                                placeholder="Zip Code"
                                value={zipcode}
                                onChange={(e) => {
                                  setZipcode(e.target.value.trim());
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {message && (
                          <span className="customvalidationdesignlogin">
                            {message}
                          </span>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    data-dismiss="modal"
                    onClick={() => {
                      handleModalClose();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={() => SubmitCus()}
                  >
                    Save Customer
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {showModal9 === true ? (
          <>
            <div
              className={`modal common_modal payment_modal ${
                showModal9 ? "active" : ""
              }`}
              id="paymentModal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="paymentModalLabel"
              aria-hidden="true"
              data-backdrop="static" // Prevent closing when clicking outside
              data-keyboard="false" // Prevent closing when pressing escape
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        // setPaymentMode([]);
                        // setPaymnetState(false);

                        setShowModal9(false);
                        // navigate("/invoicelist");
                        // setDiscounts([]);
                        document
                          .querySelectorAll(".modal-backdrop")
                          .forEach((el) =>
                            el.classList.remove("modal-backdrop")
                          );
                      }}
                    >
                      <span className="close_icon" />
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="payment_receipt_btns">
                      <div className="receipt_btn">
                        <div className="receipt_col colhalf">
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target="#parksaleModal"
                            className="recept_btn recpt_email_btn"
                            onClick={() => {
                              setShowModal11(true);
                              //  EmailSend();
                              setShowModal9(false);
                            }}
                          >
                            Email
                          </a>
                        </div>
                        <div className="receipt_col colhalf">
                          <a
                            href="#"
                            data-dismiss="modal"
                            aria-label="Close"
                            className="recept_btn recpt_noreceipt_btn"
                            onClick={() => {
                              // setSelectedProduct([]);
                              // setSelectedRetrieveProduct([]);
                              // setPaymentMode([]);
                              // setPaymnetState(false);
                              // setSelectedCustomer({});
                              setShowModal9(false);
                              // setSelectedState(false);
                              //navigate("/invoicelist");
                              // setDiscounts([]);
                              document
                                .querySelectorAll(".modal-backdrop")
                                .forEach((el) =>
                                  el.classList.remove("modal-backdrop")
                                );
                            }}
                          >
                            No Receipt
                          </a>
                        </div>

                        <div className="receipt_col colfull">
                          {printType === 2 || states === true ? (
                            <a
                              href="#"
                              className="recept_btn recpt_print_btn"
                              onClick={() => {
                                printSetupService();
                                setShowModal9(false);
                                document
                                  .querySelectorAll(".modal-backdrop")
                                  .forEach((el) =>
                                    el.classList.remove("modal-backdrop")
                                  );
                                //navigate("/invoicelist");
                              }}
                            >
                              Print Receipt
                            </a>
                          ) : (
                            <a
                              href="#"
                              className="recept_btn recpt_print_btn"
                              onClick={printTableService}
                            >
                              Print Receipt
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {showModal11 === true ? (
          <>
            <div
              className="modal common_modal parksale_modal"
              id="parksaleModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="parksaleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="parksaleModalLabel">
                      Email
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setShowModal11(false);
                        setEmail("");

                        document
                          .querySelectorAll(".modal-backdrop")
                          .forEach((el) =>
                            el.classList.remove("modal-backdrop")
                          );
                      }}
                    >
                      <span className="close_icon"></span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="custom_form parksale_form">
                      <form className="cus_form">
                        <div className="form_row">
                          <div className="form_column col_full">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                name="address"
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value.trim());
                                  if (error.email) {
                                    setError({ ...error, email: "" });
                                  }
                                }}
                              />
                              {error.email && (
                                <span className="customvalidationdesignlogin">
                                  {error.email}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary theme_btn"
                      onClick={() => {
                        OnSubmitEmail();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}

        <div style={{ display: "none" }}>
          <div className="recieptlabelprint">
            <div
              style={{
                width: `${width}mm`,
                height: `${height}mm`,

                textAlign: "left",
                justifyContent: "center",
                fontSize:
                  FontSize === "Small"
                    ? "10px"
                    : FontSize === "Regular"
                    ? "14px"
                    : "18px",
                fontFamily: "Arial, sans-serif",
                border: "none",
              }}
            >
              <div style={{ textAlign: "left" }} border={0}>
                <div
                  style={{
                    // padding: "5px 0px",
                    fontSize:
                      FontSize === "Small"
                        ? "10px"
                        : FontSize === "Regular"
                        ? "14px"
                        : "18px",
                    fontWeight: 600,
                    //marginTop: 20,
                    color: "#000",
                  }}
                >
                  {firstName} {lastName}
                </div>
                {selectedOption?.customerPhone === 1 && phoneNo ? (
                  <div
                    style={{
                      // padding: "5px 0px",
                      fontSize:
                        FontSize === "Small"
                          ? "10px"
                          : FontSize === "Regular"
                          ? "14px"
                          : "18px",
                      fontWeight: 400,
                      // marginTop: 1,
                      color: "#000",
                    }}
                  >
                    {phoneNo}
                  </div>
                ) : null}
                {selectedOption?.customerEmail === 1 && email ? (
                  <div
                    style={{
                      // padding: "5px 0px",
                      size: "portrait",
                      fontSize:
                        FontSize === "Small"
                          ? "10px"
                          : FontSize === "Regular"
                          ? "14px"
                          : "18px",
                      fontWeight: 400,
                      // marginTop: 1,
                      color: "#000",
                    }}
                  >
                    {email}
                  </div>
                ) : null}

                <div
                  style={{
                    // padding: "5px 0px",
                    fontSize:
                      FontSize === "Small"
                        ? "10px"
                        : FontSize === "Regular"
                        ? "14px"
                        : "18px",
                    fontWeight: 800,
                    // marginTop: 5,
                    color: "#000",
                  }}
                >
                  {customerDetails.brand === 1 && selectedBrand1
                    ? selectedBrand1
                    : ""}{" "}
                  {customerDetails.model === 1 && selectedDevice
                    ? selectedDevice
                    : ""}
                  <br />
                  {customerDetails.service === 1 &&
                  (selectedService1 || selectedService) // Check if either value is present
                    ? `${selectedService1 || ""}${
                        selectedService ? ` ${selectedService}` : ""
                      }` // Concatenate only if they are defined
                    : ""}{" "}
                  <br />
                  {customerDetails.imei === 1 && imeiNumber ? imeiNumber : ""}
                  <br />
                  {customerDetails.password === 1 && password ? password : ""}
                </div>
              </div>

              <div style={{ textAlign: "left" }} border={0}>
                <div>
                  <span
                    style={{
                      fontSize:
                        FontSize === "Small"
                          ? "10px"
                          : FontSize === "Regular"
                          ? "14px"
                          : "18px",
                      fontWeight: 400,
                      // marginTop: 5,
                      color: "#000",
                      display: "block",
                    }}
                  >
                    {customerDetails.dueDate === 1 && dueDate ? (
                      <>Due:{moment(dueDate).format("MMMM Do YYYY, h:mm a")}</>
                    ) : null}
                  </span>
                  <div
                    style={{
                      display: "inline-block",
                      textAlign: "center",
                      margin: "10px 0", // Adjust margin to align it properly
                    }}
                  >
                    <Barcode
                      value={`T${ticketId}`}
                      // value={ticketId}
                      format="CODE128" // Default format
                      width={1}
                      height={20}
                      displayValue={false} // Shows the value (SKU) under the barcode
                      //fontSize={12}
                    />
                    {/* <img src="assets/img/barcode-img.png" alt="" /> */}
                    <span
                      style={{
                        padding: 0,
                        fontSize:
                          FontSize === "Small"
                            ? "10px"
                            : FontSize === "Regular"
                            ? "14px"
                            : "18px",
                        fontWeight: 400,
                        // marginTop: 8,
                        color: "#000",
                        display: "block",
                      }}
                    >
                      Ticket{"  "}
                      <span style={{ fontWeight: 600 }}>
                        # {"  "}
                        {ticketId}
                      </span>
                    </span>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div className="reciepttableservice">
          <div
            style={{
              width: printType === 0 ? "80mm" : "100%",
              fontFamily: "Arial, sans-serif",
              height: "auto",
              fontSize: 12,
            }}
          >
            {isLogo && logo ? (
              <div
                style={{
                  textAlign: logoPosition === 0 ? "left" : "center",
                }}
              >
                <img
                  src={logo}
                  style={{
                    width: logoSize === 1 ? 300 : 50,
                    height: logoSize === 1 ? 95 : 45,
                    // margin: "0 auto",
                  }}
                  alt="Receipt Logo"
                />
              </div>
            ) : null}
            <div
              style={{
                fontSize: 12,
                fontWeight: 700,
                color: "#000",
                textAlign: "center",
              }}
            >
              {title ? title : "--"}
            </div>
            <div
              style={{
                fontSize: 12,
                fontWeight: 500,
                color: "#000",
                marginTop: 4,
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: header ? header.trim().replace(/\n/g, "<br>") : "--",
                }}
              ></div>

              {/* {header ? header.trim().replace(/\n/g, "<br>") : "--"} */}
            </div>

            <div>
              <table style={{ width: "100%", marginTop: "3px" }} border={0}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        color: "#000",
                      }}
                    >
                      {moment(time, "YYYY/MM/DD").format("DD/MM/YYYY")}
                    </td>
                    <td
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        color: "#000",
                        textAlign: "right",
                      }}
                    >
                      {moment(time).format("LT")}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div
                style={{
                  fontSize: 12,
                  fontWeight: "400",
                  color: "#000",
                  marginTop: "3px",
                }}
              >
                <span style={{ fontWeight: "700" }}>Ticket:</span> #{ticketId}
              </div>
              <div
                style={{
                  fontSize: 12,
                  fontWeight: "400",
                  color: "#000",
                  marginTop: "3px",
                }}
              >
                <span style={{ fontWeight: "700" }}>Customer:</span>
                {(customerServiceDetails?.name === 1 && firstName) || lastName
                  ? `${firstName} ${lastName}`
                  : "unassigned "}

                {customerServiceDetails.email === 1 && email ? (
                  <>
                    {" "}
                    <br />
                    <span
                      style={{
                        fontWeight: 700,
                        marginTop: 10,
                      }}
                    >
                      Email:
                    </span>
                    {email}
                  </>
                ) : null}

                {customerServiceDetails.phone === 1 && phoneNo ? (
                  <>
                    {" "}
                    <br />
                    <span
                      style={{
                        fontWeight: 700,
                        marginTop: 10,
                      }}
                    >
                      Phone No.:
                    </span>
                    {phoneNo}
                  </>
                ) : null}

                {customerServiceDetails?.address === 1 && address ? (
                  <>
                    {" "}
                    <br />
                    <span
                      style={{
                        fontWeight: 700,
                        marginTop: 10,
                      }}
                    >
                      Address:
                    </span>
                    {address}
                  </>
                ) : null}
              </div>
              {serviceDetails.dueDate === 1 && dueDate ? (
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: "400",
                    color: "#000",
                    marginTop: "3px",
                  }}
                >
                  <span style={{ fontWeight: "700" }}>Due:</span>{" "}
                  {moment(dueDate).format("MMMM Do YYYY, h:mm a")}
                </div>
              ) : null}
            </div>

            <div>
              <table
                className="tabledata servtabledata"
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  marginTop: "3px",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        padding: "2px",
                        fontSize: 12,
                        fontWeight: "400",
                        color: "#000",
                        textAlign: "center",
                        borderRight: "2px dashed #000",
                        borderTop: "2px dashed #000",
                      }}
                    >
                      <b>Service</b>
                    </td>
                    <td
                      style={{
                        padding: "2px",
                        fontSize: 12,
                        fontWeight: "400",
                        color: "#000",
                        textAlign: "center",
                        borderTop: "2px dashed #000",
                      }}
                    >
                      <b>Brand & Model</b>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "2px",
                        fontSize: 12,
                        fontWeight: "400",
                        color: "#000",
                        textAlign: "center",
                        borderRight: "2px dashed #000",
                        borderBottom: "2px dashed #000",
                      }}
                    >
                      {
                        serviceDetails.service === 1 &&
                        (selectedService1 || selectedService) // Check if either value is present
                          ? `${selectedService1 || ""}${
                              selectedService ? ` ${selectedService}` : ""
                            }` // Concatenate only if they are defined
                          : "" // Fallback when neither value is available
                      }
                    </td>
                    <td
                      style={{
                        padding: "2px",
                        fontSize: 12,
                        fontWeight: "400",
                        color: "#000",
                        textAlign: "center",
                        borderBottom: "2px dashed #000",
                      }}
                    >
                      {serviceDetails.brand === 1 && selectedBrand1
                        ? selectedBrand1
                        : ""}
                      {"  "}
                      {serviceDetails.model === 1 && selectedDevice
                        ? selectedDevice
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* <div style={{ padding: "8px 0" }}></div> */}

            <div>
              <table
                className="tabledata"
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  marginTop: "3px",
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "3px",
                        fontSize: 12,
                        fontWeight: "700",
                        color: "#000",
                        textAlign: "center",
                        borderBottom: "2px dashed #000",
                      }}
                    >
                      QTY
                    </th>
                    <th
                      style={{
                        padding: "3px",
                        fontSize: 12,
                        fontWeight: "700",
                        color: "#000",
                        textAlign: "left",
                        borderBottom: "2px dashed #000",
                      }}
                    >
                      Item Name
                    </th>
                    <th
                      style={{
                        padding: "3px",
                        fontSize: 12,
                        fontWeight: "700",
                        color: "#000",
                        textAlign: "right",
                        borderBottom: "2px dashed #000",
                      }}
                    >
                      Price
                    </th>
                    <th
                      style={{
                        padding: "3px",
                        fontSize: 12,
                        fontWeight: "700",
                        color: "#000",
                        textAlign: "right",
                        borderBottom: "2px dashed #000",
                      }}
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items && items?.length > 0 ? (
                    items?.map((item, index) => {
                      const itemDetails = itemIds?.find(
                        (id) => id.id === item?.itemId
                      ); // Merge items and itemIds based on itemId
                      return (
                        <tr key={index}>
                          <td
                            style={{
                              padding: "3px",
                              fontSize: 12,
                              fontWeight: "500",
                              color: "#000",
                              textAlign: "center",
                            }}
                          >
                            {item?.quantity}
                          </td>
                          <td
                            style={{
                              padding: "3px",
                              fontSize: 12,
                              fontWeight: "500",
                              color: "#000",
                              textAlign: "left",
                            }}
                          >
                            {selectedServiceOption?.prodname === 1 &&
                            selectedServiceOption?.sku === 0 &&
                            selectedServiceOption?.prodname_sku === 0
                              ? itemDetails?.title
                              : ""}
                            {selectedServiceOption?.sku === 1 &&
                            selectedServiceOption?.prodname === 0 &&
                            selectedServiceOption?.prodname_sku === 0
                              ? itemDetails?.skuCode
                              : ""}
                            {selectedServiceOption?.prodname_sku === 1 &&
                            selectedServiceOption?.prodname === 1 &&
                            selectedServiceOption?.sku === 1
                              ? `${itemDetails?.title}(${itemDetails?.skuCode})`
                              : ""}
                            {/* {itemDetails?.title || "Item Name"} */}
                            <p>{item?.note}</p>
                          </td>
                          <td
                            style={{
                              padding: "3px",
                              fontSize: 12,
                              fontWeight: "500",
                              color: "#000",
                              textAlign: "right",
                            }}
                          >
                            {item?.unitCost}
                          </td>
                          <td
                            style={{
                              padding: "3px",
                              fontSize: 12,
                              fontWeight: "500",
                              color: "#000",
                              textAlign: "right",
                            }}
                          >
                            {item?.total}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="4"
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          fontSize: 12,
                          fontWeight: "500",
                          color: "#000",
                        }}
                      >
                        There is no data found
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td
                      style={{
                        padding: "2px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "center",
                        borderBottom: "2px dashed #000",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "2px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "left",
                        borderBottom: "2px dashed #000",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "2px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "right",
                        borderBottom: "2px dashed #000",
                      }}
                    ></td>
                    <td
                      style={{
                        padding: "2px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "right",
                        borderBottom: "2px dashed #000",
                      }}
                    ></td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      colSpan="2"
                      style={{
                        padding: "1px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      Subtotal
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        padding: "1px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "right",
                      }}
                    >
                      $ {sub || 0}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      style={{
                        padding: "1px",
                        fontSize: 12,
                        fontWeight: "400",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      Discounts
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        padding: "1px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "right",
                      }}
                    >
                      ${discount || 0}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      style={{
                        padding: "1px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "left",
                      }}
                    >
                      Tax({taxPer || 0}%)
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        padding: "1px",
                        fontSize: 12,
                        fontWeight: "500",
                        color: "#000",
                        textAlign: "right",
                      }}
                    >
                      $ {tax || 0}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      style={{
                        padding: "1px",
                        fontSize: 12,
                        fontWeight: "700",
                        color: "#000",
                        textAlign: "left",
                        borderBottom: "2px dashed #000",
                      }}
                    >
                      Grand Total
                    </td>
                    <td
                      colSpan="2"
                      style={{
                        padding: "1px",
                        fontSize: 12,
                        fontWeight: "700",
                        color: "#000",
                        textAlign: "right",
                        borderBottom: "2px dashed #000",
                      }}
                    >
                      ${grand || 0}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            {/* <div style={{ padding: "4px 0" }}></div> */}
            <table style={{ width: "100%", marginTop: "3px" }} border={0}>
              <tbody>
                {paymentDetails?.map((payment, index) => (
                  <tr>
                    <td
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        color: "#000",
                      }}
                    >
                      ("{payment?.paymentType || "--"}")
                      {/* Display Payment Type */}
                    </td>
                    <td
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      Received:{" "}
                      <span style={{ fontWeight: 700 }}>
                        ${payment?.payAmount.toFixed(2) || 0}
                      </span>
                    </td>
                    <td
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        color: "#000",
                        textAlign: "right",
                      }}
                    >
                      {payment?.date || "--"} {payment?.time || "--"}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td
                    style={{
                      // padding: "3px 0px",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#000",
                    }}
                    colSpan={2}
                  >
                    Change Given:{" "}
                    <span style={{ fontWeight: 700 }}>${change || 0}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      // padding: "10px 0px 3px",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#000",
                    }}
                    colSpan={2}
                  >
                    <span style={{ fontWeight: 700 }}>Cashier:</span>
                    {/* {servedPerson === 1 ? cashierName : "--"} */}

                    {servedPerson === 1 ? updateUserName : "--"}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#000",
                    }}
                    colSpan={2}
                  >
                    <span style={{ fontWeight: 700 }}>Technician:</span>{" "}
                    {TechPerson === 1 ? updateUserName : "--"}
                    {/* {TechPerson === 1 ? technicianName : "--"} */}
                  </td>
                </tr>
              </tbody>
            </table>

            <div
              style={{
                textAlign: "justify",
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                maxWidth: "100%",
              }}
            >
              <pre
                style={{
                  textAlign: "justify",
                  fontSize: "12px",
                  fontWeight: 400,
                  color: " #000",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  maxWidth: "100%",
                }}
              >
                {footer ? footer : "--"}
              </pre>

              <div style={{ width: "100%", textAlign: "center", border: "0" }}>
                {gstShow === 1 ? (
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#000",
                    }}
                  >
                    Hst #: {gstNumber ? gstNumber : "--"}
                  </span>
                ) : null}
                {displaySignature === 1 ? (
                  <>
                    <div
                      style={{
                        marginTop: "15px",
                        width: "50%",
                        borderTop: "1px solid #000",
                        marginLeft: "auto",
                        textAlign: "center",
                        marginRight: "auto",
                      }}
                    ></div>
                    <span
                      style={{
                        fontSize: "10px",
                        color: "#000",
                        display: "block",
                        marginLeft: "auto",
                        textAlign: "center",
                      }}
                    >
                      Signature
                    </span>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal common_modal noteinfo_modal"
        id="noteinfoModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="noteinfoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="noteclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="assets/img/notes-close-icon.svg" alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className="notes_tabs">
                <ul
                  className="nav nav-pills notetabs"
                  id="notetabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="video_demo-tab"
                      data-toggle="tab"
                      href="#video_demo"
                      role="tab"
                      aria-controls="video_demo"
                      aria-selected="true"
                    >
                      Video Demo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="page_info-tab"
                      data-toggle="tab"
                      href="#page_info"
                      role="tab"
                      aria-controls="page_info"
                      aria-selected="false"
                    >
                      Page Information
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="settingContent">
                  <div
                    className="tab-pane fade active show"
                    id="video_demo"
                    role="tabpanel"
                    aria-labelledby="video_demo-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Video Demo</h4>
                      <p>
                        In this short tutorial, we’ll walk you through the key
                        features and functions of the Dashboard page.
                      </p>
                      <div className="notes_videos">
                        <img
                          src="assets/img/notes-demo-videos-img.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="page_info"
                    role="tabpanel"
                    aria-labelledby="page_info-tab"
                  >
                    <div className="notetabs_content">
                      <h4>Page Information</h4>
                      <p>
                        This page provides a comprehensive view of all the tools
                        and features available to you. Each section is designed
                        to help you manage your business more efficiently,
                        whether you’re tracking sales, monitoring inventory, or
                        analyzing reports.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TicketDetail;
