import React, { useState, useEffect } from "react";
import { baseurl, ProductBarcodeDetailsGetApi } from "../Utility/Api";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const BarcodeScan = () => {
  const [barcode, setBarcode] = useState("");
  const [productDetails, setProductDetails] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let tempBarcode = "";
    let scanTimeout = null;
    const handleKeyDown = (event) => {
      // Skip processing if the focus is on an input, textarea, or select element
      const targetTag = event.target.tagName.toLowerCase();
      if (["input", "textarea", "select"].includes(targetTag)) {
        return;
      }

      const { key } = event;

      // Accumulate characters for the barcode
      if (key.length === 1) {
        tempBarcode += key;

        // Clear the previous timeout
        if (scanTimeout) clearTimeout(scanTimeout);

        // Set a timeout to process the barcode
        scanTimeout = setTimeout(() => {
          console.log("Barcode scanned:", tempBarcode);
          setBarcode(tempBarcode);

          // Check if the barcode starts with "T"
          if (tempBarcode.startsWith("T")) {
            fetchTicketDetails(tempBarcode); // Call fetchTicketDetails API
          } else {
            const numericBarcode = tempBarcode; // Use the barcode as is
            Productdetail(numericBarcode); // Call Productdetail API
          }

          tempBarcode = ""; // Reset temporary barcode
        }, 300); // Wait 300ms for input to stop
      }
    };

    // Add event listener
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      if (scanTimeout) clearTimeout(scanTimeout);
    };

    // const handleKeyDown = (event) => {
    //   // Skip processing if the focus is on an input, textarea, or select element
    //   const targetTag = event.target.tagName.toLowerCase();
    //   if (["input", "textarea", "select"].includes(targetTag)) {
    //     return;
    //   }

    //   const { key } = event;

    //   // Accumulate characters for the barcode
    //   if (key.length === 1) {
    //     tempBarcode += key;

    //     // Clear the previous timeout
    //     if (scanTimeout) clearTimeout(scanTimeout);

    //     // Set a timeout to process the barcode
    //     scanTimeout = setTimeout(() => {
    //       console.log("Barcode scanned:", tempBarcode);
    //       setBarcode(tempBarcode);
    //       const numericBarcode = tempBarcode.slice(1);
    //       fetchTicketDetails(tempBarcode);
    //       Productdetail(tempBarcode); // Fetch details
    //       tempBarcode = ""; // Reset temporary barcode
    //     }, 300); // Wait 300ms for input to stop
    //   }
    // };

    // // Add event listener
    // window.addEventListener("keydown", handleKeyDown);

    // // Cleanup event listener on unmount
    // return () => {
    //   window.removeEventListener("keydown", handleKeyDown);
    //   if (scanTimeout) clearTimeout(scanTimeout);
    // };
  }, []);

  const fetchTicketDetails = async (barcode) => {
    const numericBarcode = barcode.slice(1);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${baseurl}getTicketById/${numericBarcode}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // if (!response.ok) {
      //   if (response.status === 404) {
      //     // If the API indicates the barcode doesn't exist
      //     Swal.fire({
      //       icon: "error",
      //       title: "Barcode Not Found",
      //       text: "The scanned barcode does not exist.",
      //     });
      //   } else {
      //     throw new Error("Failed to fetch product details");
      //   }
      // } else {
      const data = await response.json();
      if (data.status === false) {
        // Show alert if no product details are returned
        Swal.fire("The scanned barcode does not exist.");
      } else {
        setProductDetails(data);
        navigate("/ticketdetail", { state: data });
        console.log("Product details:", data);
      }
    } catch (err) {
      //}
      console.error(err);
      // Swal.fire({
      //   icon: "error",
      //   title: "Error",
      //   text: "Error fetching product details: " + err.message,
      // });
    }
  };

  const fetchProductDetails = async (barcode) => {
    const numericBarcode = barcode.slice(1);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${baseurl}getTicketById/${numericBarcode}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // if (!response.ok) {
      //   if (response.status === 404) {
      //     // If the API indicates the barcode doesn't exist
      //     Swal.fire({
      //       icon: "error",
      //       title: "Barcode Not Found",
      //       text: "The scanned barcode does not exist.",
      //     });
      //   } else {
      //     throw new Error("Failed to fetch product details");
      //   }
      // } else {
      const data = await response.json();
      if (data.status === false) {
        // Show alert if no product details are returned
        Swal.fire("The scanned barcode does not exist.");
      } else {
        setProductDetails(data);
        navigate("/ticketdetail", { state: data });
        console.log("Product details:", data);
      }
    } catch (err) {
      //}
      console.error(err);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error fetching product details: " + err.message,
      });
    }
  };

  // const Productdetail = (barcode) => {
  //   // Fetch the token from localStorage
  //   const token = localStorage.getItem("token");
  //   if (!token) {
  //     console.error("Authorization token is missing.");
  //     return;
  //   }

  //   // Prepare the headers for the API request
  //   const myHeaders = new Headers({
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   });

  //   // Prepare the body payload, ensuring barcode is sent as a string
  //   const raw = JSON.stringify({
  //     skuCode: String(barcode), // Converts the barcode to a string
  //   });

  //   const requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   console.log("Payload being sent:", raw);

  //   // Make the API call
  //   fetch(ProductBarcodeDetailsGetApi, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log("API Response:", result);

  //       if (result?.status === true) {
  //         if (!result?.data) {
  //           // Show a message if data is null
  //           alert("Scanned barcode doesn't exist.");
  //         } else {
  //           // Check if imeiNumberArr is empty or only contains empty strings
  //           const imeiArray = JSON.parse(
  //             result?.data[0]?.imeiNumberArr || "[]"
  //           );
  //           if (
  //             imeiArray.length === 0 ||
  //             imeiArray.every((item) => item === "")
  //           ) {
  //             // Navigate to edit device page
  //             navigate("/editproduct", { state: { data: result?.data[0] } });
  //           } else {
  //             // Navigate to edit product page
  //             navigate("/editdevice", { state: { data: result?.data[0] } });
  //           }
  //         }
  //       } else {
  //         console.warn("Product not found or error in API response:", result);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error in fetching product details:", error);
  //     });
  // };
  const Productdetail = (barcode) => {
    // Fetch the token from localStorage
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Authorization token is missing.");
      return;
    }

    // Prepare the headers for the API request
    const myHeaders = new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    // Prepare the body payload, ensuring barcode is sent as a string
    const raw = JSON.stringify({
      skuCode: String(barcode), // Converts the barcode to a string
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    console.log("Payload being sent:", raw);

    // Make the API call
    fetch(ProductBarcodeDetailsGetApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("API Response:", result);

        if (result?.status === true) {
          if (!result?.data || result?.data.length === 0) {
            // If data is null or empty, show the barcode does not exist message
            Swal.fire({
              icon: "error",
              title: "Barcode Not Found",
              text: "The scanned barcode does not exist.",
            });
          } else {
            // Check if imeiNumberArr is empty or only contains empty strings
            const imeiArray = JSON.parse(
              result?.data[0]?.imeiNumberArr || "[]"
            );
            if (
              imeiArray.length === 0 ||
              imeiArray.every((item) => item === "")
            ) {
              // Navigate to edit device page
              navigate("/editproduct", { state: { data: result?.data[0] } });
            } else {
              // Navigate to edit product page
              navigate("/editdevice", { state: { data: result?.data[0] } });
            }
          }
        } else {
          console.warn("Product not found or error in API response:", result);
        }
      })
      .catch((error) => {
        console.error("Error in fetching product details:", error);
        // Show a generic error message
        // Swal.fire({
        //   icon: "error",
        //   title: "Error",
        //   text: "Error fetching product details: " + error.message,
        // });
      });
  };

  return <div>{/* Barcode scanning UI or additional elements */}</div>;
};

export default BarcodeScan;
